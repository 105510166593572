/// JsMediaSDK init
import zoomAudioWorkletNode from './ZoomAudioWorkletNode';
import util, {
  Deferred,
  IntegrityHelper,
  deepEqual,
  apiSupportUtility,
  IsSupportWebGLOffscreenCanvas,
  Get_Logical_SSrc,
  createAudioContext,
  VideoStreamCanCapture,
  CheckCanvasSize,
} from '../common/util';
import * as jsEvent from '../common/jsEvent';
import jsMediaEngineVariables from '../inside/JsMediaEngine_Variables';
import globalTracingLogger from '../common/globalTracingLogger';
import * as jsMediaEngine from './JsMediaEngine';
import { CameraOccupiedError } from '../common/Error';
import Log from '../common/log';
import PubSub from '../common/pubSub';
import isFunction from 'lodash/isFunction';
import RemoteControl from '../inside/RemoteControl';
import { ConnectionType } from '../common/enums/CommonEnums';
import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';
import Zoom_Monitor from '../inside/Monitor';
import { RTCPeerConnectionUtil } from '../common/RTCPeerConnectionUtil';
import {
  ZOOM_CONNECTION_TYPE,
  NET_SESSION_TYPE,
  WORKER_TYPE,
  AudioStatus,
} from '../common/enums/CommonEnums';
import { PUBSUB_EVT } from '../common/jsEvent';
import VideoRender, {
  get16V9CroppingParams,
} from '../inside/JsMediaSDK_VideoRender';
import SharingRender from '../inside/JsMediaSDK_SharingRender';
import H264bsdCanvas from '../common/WebGLCanvas';
import WebGL2Canvas from '../common/renderer/WebGL2Canvas';
import {
  ConsumeRB,
  RingBuffer,
  VideoDecodeOBJXBuffer,
} from '../common/ZoomSharedArrayBufferUtils';
import { MEDIA_INIT_SUCCESS_CALLBACK_METADATA } from './JsMediaEngine';
import * as consts from '../worker/common/consts';
import WebrtcAudioBridge from '../inside/WebrtcAudioBridge';
import { HIDControl } from '../inside/hid';
import { AudioInputLevel } from '../common/audio-level';
import { LocalLog } from '../inside/LocalLog';
import deviceManager from '../inside/DeviceManager';
import { ReportStatic } from '../statistic/utils';
import { ComputePressureManager } from '../inside/ComputePressure';
import RenderManager from '../common/renderer/RenderManager';
import * as RenderConst from '../common/renderer/RenderConst';

class MediaStreamController {
  constructor() {
    /** enable reuse stream or not */
    this._isReuseStreamEnabled = false;
    /** set flag for remember stream in re-use status or not */
    this._isStreamInReuseMap = new Map();
    /** for ios safari we can combine the media permission request by preset audio and video constraints */
    this.presetedConstraints = {
      audioConstraints: null,
      videoConstraints: null,
      audioTimer: null,
      videoTimer: null,
    };
    /** in ios mobile, destory a media track will affect track from another stream,
     * need to store and destory later */
    this._deprecatedStream = {
      audio: [],
      video: [],
    };
    this._captureVideoDeferred = null;

    this.audioConstraints = null;
    this.audioStream = null;
    this.isCaptureAudioInProgress = false;

    this.audioCaptureElapsedTime = 0;

    this.videoConstraints = null;
    this.videoStream = null;
    this.videoStreamTrack = null;
    this.captureVideoTimer = null;
    this.checkVideoStreamActiveTimer = null;
    this.isCaptureVideoInProgress = false;

    /** store resolution when update resolution */
    this.currentVideoResolution = {
      width: 0,
      height: 0,
    };

    this.streamProcessAbility = {
      isSupportMediaStreamTrackProcessor:
        util.isSupportMediaStreamTrackProcessor(),
      isSupportVideoTrackReader: util.isSupportVideoTrackReader(),
      isSupportImageCapture: util.isSupportImageCapture(),
    };
    this.mediaStreamTrackProcessor = null;
    this.reableStream = null;
    this.videoTrackReader = null;
    this.videoImageCapture = null;
    /** throttle the imagecapture event loop */
    this.videoImageCaptureLocked = false;
    this.isAppleGraphic = false;
    this.isSupportFullHD = false;
    this.facingMode = consts.FACE_MODE_UNKNOW;
  }

  enableReuseStream(enabled) {
    this._isReuseStreamEnabled = !!enabled;
  }

  _updateStreamInReuse(stream, inReuse) {
    if (inReuse) {
      this._isStreamInReuseMap.set(stream, true);
    } else {
      this._isStreamInReuseMap.delete(stream);
    }
  }

  /** check if the stream is reused from last capture action */
  _isStreamInReuse(stream) {
    return !!this._isStreamInReuseMap.get(stream);
  }

  /**
   * @description store the preseted constraints for capture audio/video with one request
   */
  presetConstraints({ audioConstraints, videoConstraints }) {
    if (audioConstraints) {
      this.presetedConstraints.audioConstraints =
        this._getAudioConstraints(audioConstraints);
    }
    if (videoConstraints) {
      this.presetedConstraints.videoConstraints =
        this._getVideoConstraints(videoConstraints);
    }
  }

  _clearPresetConstraints() {
    this.presetedConstraints.audioConstraints = null;
    this.presetedConstraints.videoConstraints = null;
  }

  _getPresetAudioConstraints() {
    const { audioConstraints } = this.presetedConstraints;
    this._clearPresetConstraints();
    if (
      audioConstraints &&
      !this.audioStream &&
      !this.isCaptureAudioInProgress
    ) {
      return audioConstraints;
    }
    return false;
  }

  _getPresetVideoConstraints() {
    const { videoConstraints } = this.presetedConstraints;
    this._clearPresetConstraints();
    if (
      videoConstraints &&
      !this.videoStream &&
      !this.isCaptureVideoInProgress
    ) {
      return videoConstraints;
    }
    return false;
  }

  /** in ios mobile, destory a media track will affect track from another stream,
   * need to store and destory later */
  _pushDeprecatedStream({ audioStream, videoStream } = {}) {
    if (audioStream) {
      this._deprecatedStream.audio.push(audioStream);
    }
    if (videoStream) {
      this._deprecatedStream.video.push(videoStream);
    }
  }

  /**
   * @description destory a deprecated stream when call destory audio/video stream
   */
  _destoryDeprecatedStream({ audio, video } = {}) {
    if (audio) {
      this._deprecatedStream.audio.forEach((audioStream) => {
        try {
          this._destoryStream(audioStream);
        } catch (e) {
          globalTracingLogger.error(
            'Error when destroying deprecated audio stream',
            e
          );
        }
      });
      this._deprecatedStream.audio = [];
    }
    if (video) {
      this._deprecatedStream.video.forEach((videoStream) => {
        try {
          this._destoryStream(videoStream);
        } catch (e) {
          globalTracingLogger.error(
            'Error when destroying deprecated video stream',
            e
          );
        }
      });
      this._deprecatedStream.video = [];
    }
  }

  /**
   * @description change the default process ability
   */
  setStreamProcessAbility({
    isSupportMediaStreamTrackProcessor,
    isSupportVideoTrackReader,
    isSupportImageCapture,
  }) {
    this.streamProcessAbility = {
      ...this.streamProcessAbility,
      ...(isSupportMediaStreamTrackProcessor !== undefined
        ? { isSupportMediaStreamTrackProcessor }
        : {}),
      ...(isSupportVideoTrackReader !== undefined
        ? { isSupportVideoTrackReader }
        : {}),
      ...(isSupportImageCapture !== undefined ? { isSupportImageCapture } : {}),
    };
  }

  _isFileInputAudioSource(constraints) {
    return (
      constraints &&
      (constraints.audio instanceof HTMLVideoElement ||
        constraints.audio instanceof HTMLAudioElement)
    );
  }

  _getAudioConstraints({
    audioSource,
    isSupportBrowserAec,
    disableAudioAGC,
    disableNoiseSuppression,
    enableOriginalSound,
    enableStereo,
  }) {
    // file input source
    if (
      audioSource instanceof HTMLVideoElement ||
      audioSource instanceof HTMLAudioElement
    ) {
      return {
        audio: audioSource,
      };
    }
    //need set samplerate to 48000, or mono capture will fail(windows chrome)
    let audioPara = false;
    if (isSupportBrowserAec) {
      audioPara = {
        deviceId: audioSource
          ? { exact: audioSource }
          : util.browser.isChrome
          ? { exact: 'default' }
          : undefined,
        autoGainControl: !disableAudioAGC && !enableOriginalSound,
        noiseSuppression: !disableNoiseSuppression && !enableOriginalSound,
        latency: 0,
        echoCancellation: !enableStereo,
        channelCount: 2,
        sampleRate: 48000,
      };
    } else {
      audioPara = {
        deviceId: audioSource
          ? { exact: audioSource }
          : util.browser.isChrome
          ? { exact: 'default' }
          : undefined,
        autoGainControl: !disableAudioAGC && !enableOriginalSound,
        noiseSuppression: !disableNoiseSuppression && !enableOriginalSound,
        latency: 0,
        echoCancellation: isSupportBrowserAec && !enableStereo,
        channelCount: 2,
        sampleRate: 48000,
      };
    }
    return audioPara;
  }

  /**
   * @description compare audio constraints:
   * audio constraints can be stored,
   * we can re-use audio stream when from preview to in-meeting  */
  _isSameAudioConstraintsAsPrev(audioConstraints) {
    if (this._isFileInputAudioSource(audioConstraints)) return false;
    if (
      !this.audioConstraints ||
      !deepEqual(this.audioConstraints, audioConstraints)
    ) {
      return false;
    }
    if (!this.audioStream) return false;
    if (!this._isStreamHasAudio(this.audioStream)) return false;
    return true;
  }

  /**
   * @description destory a media stream
   * @param stream
   */
  _destoryStream(stream, { audioOnly, videoOnly } = {}) {
    /** audio file input stream should not destroy, otherwise audio tag can't play anymore */
    if (stream && !util.audioToMediaStreamMananger.isAudioFileStream(stream)) {
      let tracks = [];
      if (audioOnly) {
        tracks = stream.getAudioTracks();
      } else if (videoOnly) {
        tracks = stream.getVideoTracks();
      } else {
        tracks = stream.getTracks();
      }
      tracks.forEach((track) => {
        track.stop();
      });
    }
  }

  _isStreamHasAudio(stream) {
    const audioTracks = stream.getAudioTracks();
    return audioTracks && audioTracks.length > 0;
  }

  _isStreamHasVideo(stream) {
    const videoTracks = stream.getVideoTracks();
    return videoTracks && videoTracks.length > 0;
  }

  /**
   * @description destory audio stream
   */
  destoryAudioMediaStream() {
    if (this.audioStream) {
      if (this._isFileInputAudioSource(this.audioConstraints)) {
        util.audioToMediaStreamMananger.stopCapture();
      }
      if (this._isReuseStreamEnabled) {
        this._updateStreamInReuse(this.audioStream, true);
      } else {
        this._updateStreamInReuse(this.audioStream, false);
        this._destoryStream(this.audioStream);
        this.audioStream = null;
        this.audioConstraints = null;
        this.audioCaptureElapsedTime = 0;
        this._destoryDeprecatedStream({ audio: true });
      }
    } else {
      this._destoryDeprecatedStream({ audio: true });
    }
    this.enableReuseStream(false);
  }

  /**
   * @description reuesd stream should also call startCaptureAudio
   * Returns true if we should create a new audio stream
   */
  shouldCaptureAudio() {
    return !this.audioStream || this._isStreamInReuse(this.audioStream);
  }

  muteUnmuteAudioMediaStream(muted) {
    if (this.audioStream) {
      const audioTracks = this.audioStream.getAudioTracks() || [];
      audioTracks.forEach((track) => {
        track.enabled = !muted;
      });
    }
  }

  _storeAudioStream(stream, constraints, isPreseted) {
    if (stream) {
      log('audio stream is ok');
      // destory old stream
      this.destoryAudioMediaStream();
      this.audioStream = stream;
      this.audioConstraints = constraints;
      if (isPreseted) {
        this._setPresetAudioStreamAutoStopTimer();
      }
    }
  }

  /**
   * @description preset capture audio stream need to auto stop if not used after 10s
   */
  _setPresetAudioStreamAutoStopTimer() {
    this._clearPresetAudioStreamAutoStopTimer();
    this.presetedConstraints.audioTimer = setTimeout(() => {
      this.destoryAudioMediaStream();
    }, 10 * 1000);
  }

  _clearPresetAudioStreamAutoStopTimer() {
    if (this.presetedConstraints.audioTimer) {
      clearTimeout(this.presetedConstraints.audioTimer);
      this.presetedConstraints.audioTimer = null;
    }
  }

  _handleAudioCaptureSuccess(stream, constraints, successHandler, isReuse) {
    this._clearPresetAudioStreamAutoStopTimer();
    if (!isReuse) {
      this._storeAudioStream(stream, constraints);
    }
    /** when stream is handled, need to clear reuse flag, otherwise will cause cycle call */
    this._updateStreamInReuse(stream, false);

    let audioTrack = stream.getAudioTracks()[0];
    if (audioTrack) {
      Zoom_Monitor.add_monitor(
        'ATRS:' + audioTrack.readyState + '-' + audioTrack.id
      );
      Zoom_Monitor.add_monitor(
        'ATMS:' + audioTrack.muted + '-' + audioTrack.id
      );
      //when playback or streaming has stopped because the end of the media was reached
      //or because no further data is available.
      audioTrack.onended = async () => {
        let devices = await navigator.mediaDevices.enumerateDevices();
        let flag = false;
        devices.forEach((device) => {
          if (
            device.kind == 'audioinput' &&
            device.label == audioTrack.label &&
            device.deviceId == audioTrack.getSettings().deviceId
          ) {
            flag = true;
          }
        });
        if (flag) {
          //leave audio because some expections
          jsMediaEngineVariables.Notify_APPUI_SAFE(jsEvent.AUDIO_STREAM_FAILED);
          Zoom_Monitor.add_monitor(
            'ATRS:' + audioTrack.readyState + '-' + audioTrack.id + '-L'
          );
        } else {
          //ended event caused by unplugging device, ignore this event
          Zoom_Monitor.add_monitor(
            'ATRS:' + audioTrack.readyState + '-' + audioTrack.id + '-I'
          );
        }
      };
      audioTrack.onmute = () => {
        Zoom_Monitor.add_monitor(
          'ATMS:' + audioTrack.muted + '-' + audioTrack.id
        );
      };
      audioTrack.onunmute = () => {
        Zoom_Monitor.add_monitor(
          'ATMS:' + audioTrack.muted + '-' + audioTrack.id
        );
      };
    } else {
      Zoom_Monitor.add_monitor('TN');
    }

    successHandler(stream);
  }

  /**
   * @description generate a new audio stream from a combined av stream
   */
  _genAudioStream(stream) {
    try {
      const audioTracks = stream.getAudioTracks();
      if (audioTracks.length === 0) return null;
      const audioStream = new MediaStream(audioTracks);
      return audioStream;
    } catch (e) {
      globalTracingLogger.warn('_genAudioStream', e);
      this._destoryStream(stream, { videoOnly: true });
      return stream;
    }
  }

  /**
   * @description generate a new video stream from a combined av stream
   */
  _genVideoStream(stream) {
    try {
      const videoTracks = stream.getVideoTracks();
      if (videoTracks.length === 0) return null;
      const videoStream = new MediaStream(videoTracks);
      return videoStream;
    } catch (e) {
      globalTracingLogger.warn('_genVideoStream', e);
      this._destoryStream(stream, { audioOnly: true });
      return stream;
    }
  }

  /**
   * @description split audio and video stream if captured together
   */
  _splitAudioStream(stream, constraints, successHandler, errorHandler) {
    const { audio, video } = constraints;
    if (video && this._isStreamHasVideo(stream)) {
      const audioStream = this._genAudioStream(stream);
      const videoStream = this._genVideoStream(stream);
      if (audioStream) {
        this._handleAudioCaptureSuccess(audioStream, audio, successHandler);
      } else {
        errorHandler(new Error('audio stream do not contains audio track '));
      }
      /** store the videostream for later use */
      if (videoStream) {
        if (this.videoStream) {
          this._pushDeprecatedStream({
            videoStream,
          });
        } else {
          this._updateStreamInReuse(videoStream, true);
          this._storeVideoStream(videoStream, video, true);
        }
      }
      return;
    }
    this._handleAudioCaptureSuccess(stream, audio, successHandler);
  }

  async _captureFileInputAudioStream(audioConstraints) {
    const { audio } = audioConstraints;
    try {
      const success = await new Promise((resolve, reject) => {
        if (audio.currentTime > 0 && !audio.paused && !audio.ended) {
          resolve(true);
        }
        const checkPlayingFn = () => {
          resolve(true);
          audio.removeEventListener('timeupdate', checkPlayingFn);
        };
        audio.addEventListener('timeupdate', checkPlayingFn);
        setTimeout(() => reject(false), 10000);
      });
      if (!success) {
        throw new Error('audio file playing failed');
      }
      const captureStream = util.audioToMediaStreamMananger.startCapture(audio);
      return captureStream;
    } catch (e) {
      console.error(e);
      const fileInputSourceCaptureError = new Error(
        'capture stream from file input souce failed'
      );
      fileInputSourceCaptureError.name = 'FileInputSouceError';
      throw fileInputSourceCaptureError;
    }
  }

  async startCaptureAudio({ audioConstraints, successHandler, errorHandler }) {
    this.enableReuseStream(false);
    const constraints = audioConstraints
      ? this._getAudioConstraints(audioConstraints)
      : false;
    const isFileInputAudioSource = this._isFileInputAudioSource(constraints);
    const isSameConstraints = this._isSameAudioConstraintsAsPrev(constraints);
    const presetVideoConstraints = this._getPresetVideoConstraints();
    this._clearPresetAudioStreamAutoStopTimer();
    if (isSameConstraints) {
      this.audioCaptureElapsedTime = -2;
      this._handleAudioCaptureSuccess(
        this.audioStream,
        constraints,
        successHandler,
        true
      );
      return;
    }
    this.destoryAudioMediaStream();

    const finalConstraints = {
      audio: constraints,
      video: isFileInputAudioSource ? null : presetVideoConstraints,
    };
    this.isCaptureAudioInProgress = true;
    let startTime = Date.now();
    if (util.isIphoneOrIpadSafari()) {
      if (this._captureVideoDeferred) {
        await this._captureVideoDeferred.promise;
      }
      this._captureVideoDeferred = new Deferred();
    }
    Zoom_Monitor.add_monitor('RGUMA');
    (isFileInputAudioSource
      ? this._captureFileInputAudioStream(constraints)
      : navigator.mediaDevices.getUserMedia(finalConstraints)
    )
      .then((stream) => {
        if (
          jsMediaEngineVariables.ComputerAudioStatus ===
          AudioStatus.ComputerAudio_Null
        ) {
          globalTracingLogger.log(
            'getUserMedia successfully but UI has called leave audio'
          );
          stream.getAudioTracks().forEach((track) => {
            track.stop();
          });
          return;
        }
        this.audioCaptureElapsedTime = Date.now() - startTime;
        this._splitAudioStream(
          stream,
          finalConstraints,
          successHandler,
          errorHandler
        );
      })
      .catch((e) => {
        if (
          jsMediaEngineVariables.ComputerAudioStatus ===
          AudioStatus.ComputerAudio_Null
        ) {
          globalTracingLogger.log(
            'getUserMedia failed but UI has called leave audio'
          );
          return;
        }
        if (!isFileInputAudioSource) {
          deviceManager.updateSelectedMicDevices(
            (constraints.deviceId && constraints.deviceId.exact) || 'default',
            'getUsermedia failed',
            Date.now() - startTime,
            false
          );
        }
        errorHandler(e);
      })
      .finally(() => {
        this.isCaptureAudioInProgress = false;
        this._captureVideoDeferred && this._captureVideoDeferred.resolve();
      });
  }

  _isFileInputVideoSource(constraints) {
    return constraints && constraints.video instanceof HTMLVideoElement;
  }

  isUsingFileInputVideoSource() {
    return this._isFileInputVideoSource(this.videoConstraints);
  }

  _getFileInputSourceSize(width, height) {
    const qualityList = [90, 180, 360, 720];
    const radio = 16 / 9;
    if (!height) {
      height = 360;
    }
    if (height > 720) height = 720;
    if (height < 90) height = 90;
    if (qualityList.indexOf(height) === -1) {
      qualityList.some((quality, idx) => {
        if (quality > height) {
          height =
            (quality + qualityList[idx - 1]) / 2 < height
              ? quality
              : qualityList[idx - 1];
          return true;
        }
        return false;
      });
    }
    return {
      width: radio * height,
      height,
    };
  }

  _getVideoConstraints({
    videoSource,
    usingFacingMode,
    width,
    height,
    pan,
    tilt,
    zoom,
    fps,
  }) {
    // file input source
    if (videoSource instanceof HTMLVideoElement) {
      return {
        video: videoSource,
        ...(width && height ? this._getFileInputSourceSize(width, height) : {}),
      };
    }
    let videoPara = false;
    let captureFps = fps || consts.VIDEO_CAPTURE_FPS;

    if (captureFps > consts.MAX_VIDEO_CAPTURE_FPS) {
      captureFps = consts.MAX_VIDEO_CAPTURE_FPS;
    }
    if (captureFps < consts.MIN_VIDEO_CAPTURE_FPS) {
      captureFps = consts.VIDEO_CAPTURE_FPS;
    }
    if (
      navigator.hardwareConcurrency &&
      navigator.hardwareConcurrency < 4 &&
      captureFps > consts.LOWER_VIDEO_CAPTURE_FPS
    ) {
      captureFps = consts.LOWER_VIDEO_CAPTURE_FPS;
    }

    const frameRate = captureFps;
    if (this.videoCaptureValue) {
      videoSource = this.videoCaptureValue.VideoSelectValue;
    }

    if (usingFacingMode) {
      if (videoSource) {
        videoPara = { facingMode: { exact: videoSource } };
      } else {
        videoPara = true;
      }
    } else if (util.browser.isSafari) {
      if (videoSource) {
        videoPara = { deviceId: { exact: videoSource } };
      } else {
        videoPara = true;
      }
    } else {
      const { width: currentWidth, height: currentHeight } =
        this.currentVideoResolution;
      const shouldUsePrevResolution = currentWidth != 0 && currentHeight != 0;
      let minWidth = shouldUsePrevResolution ? currentWidth : width || 640;
      let minHeight = shouldUsePrevResolution ? currentHeight : height || 360;
      if (minWidth >= 1920 && !util.get1080pcapacity()) {
        minWidth = 1280;
        minHeight = 720;
      }
      videoPara = {
        width: { min: minWidth, ideal: minWidth },
        height: { min: minHeight, ideal: minHeight },
        deviceId: videoSource ? { exact: videoSource } : undefined,
        frameRate,
        pan,
        tilt,
        zoom,
      };

      /** used for WCL_CAMERA monitor log */
      jsMediaEngineVariables.monitorVideoReadyCaptureWidth = videoPara.width;
      jsMediaEngineVariables.monitorVideoReadyCaptureHeight = videoPara.height;
    }
    return videoPara;
  }

  _updateVideoConstraints(constraints) {
    if (!this.videoStreamTrack) {
      return Promise.reject(
        new Error('video stream not exist! update video constraints failed')
      );
    }
    return this.videoStreamTrack.applyConstraints(constraints).catch((ex) => {
      globalTracingLogger.error('Applying video constraints failed', ex);
    });
  }

  changeVideoResolution(width, height, reealfps) {
    if (this.videoStreamTrack) {
      if (width >= 1920 && !this.isSupportFullHD) {
        width = 1280;
        height = 720;
      }
      if (this.videoStreamTrack.getSettings) {
        let settings = this.videoStreamTrack.getSettings();
        if (Math.abs(settings.width - width) < 50) {
          return Promise.reject(new Error('no change for the resolution'));
        }
      }
      Zoom_Monitor.add_monitor('CCWidth' + width);

      let constraints = {
        width: { min: width, ideal: width },
        height: { min: height, ideal: height },
        frameRate: reealfps,
        aspectRatio:
          this.platformType == consts.WCL_PLATFORM_TYPE.DESKTOP
            ? { ideal: 16 / 9 }
            : undefined,
      };
      return this._updateVideoConstraints(constraints).then(() => {
        this.currentVideoResolution = {
          width,
          height,
        };
      });
    }
    return Promise.reject(
      new Error('video stream not exist! change video resolution failed')
    );
  }

  /**
   * @description compare video constraints:
   * video constraints can be stored,
   * we can re-use video stream when from preview to in-meeting  */
  _isSameVideoConstraintsAsPrev(videoConstraints) {
    if (this._isFileInputVideoSource(videoConstraints)) return false;
    if (
      !this.videoConstraints ||
      !deepEqual(this.videoConstraints, videoConstraints)
    ) {
      return false;
    }
    if (!this.videoStream) return false;
    if (!this._isStreamHasVideo(this.videoStream)) return false;
    return true;
  }

  _clearCaptureVideoTimer() {
    if (this.captureVideoTimer) {
      clearTimeout(this.captureVideoTimer);
      this.captureVideoTimer = null;
    }
  }

  /**
   * @description destory video stream and video processor
   */
  destoryVideoMediaStream() {
    if (this.videoStream) {
      if (this._isFileInputVideoSource(this.videoConstraints)) {
        util.videoToMediaStreamManager.stopCapture();
      }
      if (this._isReuseStreamEnabled) {
        this._updateStreamInReuse(this.videoStream, true);
      } else {
        this._updateStreamInReuse(this.videoStream, false);
        this._destoryStream(this.videoStream);
        this.videoStream = null;
        this.videoStreamTrack = null;
        this.videoConstraints = null;
        this._destoryDeprecatedStream({ video: true });
      }
    } else {
      this._destoryDeprecatedStream({ video: true });
    }
    if (!this._isReuseStreamEnabled) {
      this._clearCaptureVideoTimer();
    }
    this.enableReuseStream(false);
    this.destoryVideoProcessor();
    this._clearCheckVideoStreamActiveTimer();
  }

  /**
   * @description reuesd stream should also call startCaptureVideo
   */
  shouldCaptureVideo() {
    return !this.videoStream || this._isStreamInReuse(this.videoStream);
  }

  _clearCheckVideoStreamActiveTimer() {
    if (this.checkVideoStreamActiveTimer) {
      clearTimeout(this.checkVideoStreamActiveTimer);
      this.checkVideoStreamActiveTimer = null;
    }
  }

  /**
   * @description onended | onaddtrack | onremovetrack  all of them cannot detect whether MediaStream is active or not
   * following code is a trick
   */
  checkVideoStreamActive() {
    return new Promise((resolve, reject) => {
      this._clearCheckVideoStreamActiveTimer();
      this.checkVideoStreamActiveTimer = setTimeout(() => {
        if (this.videoStream) {
          if (this.videoStream.active === false) {
            reject(
              new CameraOccupiedError('VideoMediaStram.active equals false')
            );
          } else {
            resolve(true);
          }
        } else {
          reject(false);
        }
      }, 1000);
    });
  }

  /**
   * @description destory video processor
   */
  destoryVideoProcessor() {
    if (this.videoTrackReader) {
      try {
        this.videoTrackReader.stop();
      } catch (e) {
        globalTracingLogger.error(
          'Error when destroying video track reader',
          e
        );
      }
      this.videoTrackReader = null;
    }

    if (this.mediaStreamTrackProcessor) {
      this.mediaStreamTrackProcessor = null;
      jsMediaEngine.Notify_Video_Encode_Thread({ command: 'releaseStream' });
      if (this.reableStream && this.reableStream.close) {
        try {
          this.reableStream.close();
        } catch (e) {
          globalTracingLogger.error(
            'Error when destroying mediaStreamTrackProcessor',
            e
          );
        }
      }
      this.reableStream = null;
    }

    this.videoImageCapture = null;
    this.unLockImageCapture();
  }

  /**
   * @description lock the imagecapture process
   */
  lockImageCapture() {
    this.videoImageCaptureLocked = true;
  }

  /**
   * @description unlock the imagecapture process
   */
  unLockImageCapture() {
    this.videoImageCaptureLocked = false;
  }

  /**
   * @description if imagecapture process is locked
   */
  isImageCaptureLocked() {
    return this.videoImageCaptureLocked;
  }

  /**
   * @description create video processor by api ability
   */
  _createVideoProcessor() {
    if (!this.videoStreamTrack) {
      globalTracingLogger.error(
        'Video stream track does not exist - cannot create video processor'
      );
      return;
    }
    if (this.streamProcessAbility.isSupportMediaStreamTrackProcessor) {
      this.mediaStreamTrackProcessor = new MediaStreamTrackProcessor(
        this.videoStreamTrack
      );
      this.reableStream = this.mediaStreamTrackProcessor.readable;
      jsMediaEngine.Notify_Video_Encode_Thread({ command: 'releaseStream' });
      jsMediaEngine.Notify_Video_Encode_Thread_Transferable_Data(
        'frameStream',
        this.reableStream
      );
      Zoom_Monitor.add_monitor('VCTP');
    } else if (this.streamProcessAbility.isSupportVideoTrackReader) {
      this.videoTrackReader = new VideoTrackReader(this.videoStreamTrack);
      Zoom_Monitor.add_monitor('VCTR');
    } else if (this.streamProcessAbility.isSupportImageCapture) {
      this.videoImageCapture = new ImageCapture(this.videoStreamTrack);
      Zoom_Monitor.add_monitor('VCIC');
    }
  }

  _storeVideoStream(stream, constraints, isPreseted) {
    if (stream) {
      log('video stream is ok');
      // destory old stream
      this.destoryVideoMediaStream();
      this.videoStream = stream;
      this.videoStreamTrack = this.videoStream.getVideoTracks()[0];
      this.videoConstraints = constraints;
      if (isPreseted) {
        this._setPresetVideoStreamAutoStopTimer();
      }
    }
  }

  /**
   * @description preset capture audio stream need to auto stop if not used after 10s
   */
  _setPresetVideoStreamAutoStopTimer() {
    this._clearPresetVideoStreamAutoStopTimer();
    this.presetedConstraints.videoTimer = setTimeout(() => {
      this.destoryVideoMediaStream();
    }, 10 * 1000);
  }

  _clearPresetVideoStreamAutoStopTimer() {
    if (this.presetedConstraints.videoTimer) {
      clearTimeout(this.presetedConstraints.videoTimer);
      this.presetedConstraints.videoTimer = null;
    }
  }

  _handleVideoCaptureSuccess(stream, constraints, successHandler, isReuse) {
    this._clearPresetVideoStreamAutoStopTimer();
    if (!isReuse) {
      this._storeVideoStream(stream, constraints);
    }
    this._createVideoProcessor();
    /** when stream is handled, need to clear reuse flag, otherwise will cause cycle call */
    this._updateStreamInReuse(stream, false);
    successHandler(stream);
  }

  getVideoCapabilities() {
    if (this.videoStream) {
      if (!this.videoStreamTrack) {
        this.videoStreamTrack = this.videoStream.getVideoTracks()[0];
      }
      if (this.videoStreamTrack) {
        if (this.videoStreamTrack.getCapabilities) {
          return this.videoStreamTrack.getCapabilities() || {};
        } else if (this.videoStreamTrack.getSettings) {
          return this.videoStreamTrack.getSettings() || {};
        }
      }
    }
    return {};
  }

  getAudioCapabilities() {
    let deviceId = null;
    let deviceLabel = null;
    if (this.audioStream) {
      let audioTrack = this.audioStream.getAudioTracks()[0];
      if (audioTrack) {
        deviceId = audioTrack.getSettings().deviceId;
        deviceLabel = audioTrack.label;
      }
    }
    return {
      deviceId,
      elapsed_time: this.audioCaptureElapsedTime,
      deviceLabel,
    };
  }

  /**
   * @description split audio and video stream if captured together
   */
  _splitVideoStream(stream, constraints, successHandler, errorHandler) {
    const { audio, video } = constraints;
    if (audio && this._isStreamHasAudio(stream)) {
      const videoStream = this._genVideoStream(stream);
      const audioStream = this._genAudioStream(stream);
      if (videoStream) {
        this._handleVideoCaptureSuccess(videoStream, video, successHandler);
      } else {
        errorHandler(new Error('video stream do not contains video track '));
      }
      /** store the audiostream for later use */
      if (audioStream) {
        if (this.audioStream) {
          this._pushDeprecatedStream({
            audioStream,
          });
        } else {
          this._updateStreamInReuse(audioStream, true);
          this._storeAudioStream(audioStream, audio, true);
        }
      }
      return;
    }
    this._handleVideoCaptureSuccess(stream, video, successHandler);
  }

  async _captureFileInputVideoStream(videoConstraints) {
    const { video } = videoConstraints;
    try {
      const success = await new Promise((resolve, reject) => {
        if (video.currentTime > 0 && !video.paused && !video.ended) {
          resolve(true);
        }
        const checkPlayingFn = () => {
          resolve(true);
          video.removeEventListener('timeupdate', checkPlayingFn);
        };
        video.addEventListener('timeupdate', checkPlayingFn);
        setTimeout(() => reject(false), 10000);
      });
      if (!success) {
        throw new Error('video file playing failed');
      }
      if (!videoConstraints.width || !videoConstraints.height) {
        const { width, height } = _getFileInputSourceSize(
          video.videoWidth,
          video.videoHeight
        );
        videoConstraints.width = width;
        videoConstraints.height = height;
      }
      const captureStream = util.videoToMediaStreamManager.startCapture(
        video,
        videoConstraints.width,
        videoConstraints.height
      );
      return captureStream;
    } catch (e) {
      console.error(e);
      const fileInputSourceCaptureError = new Error(
        'capture stream from file input souce failed'
      );
      fileInputSourceCaptureError.name = 'FileInputSouceError';
      throw fileInputSourceCaptureError;
    }
  }

  async startCaptureVideo({
    videoConstraints,
    timeout,
    successHandler,
    errorHandler,
  }) {
    if (this._captureVideoDeferred) {
      await this._captureVideoDeferred.promise;
    }

    this.enableReuseStream(false);
    const constraints = videoConstraints
      ? this._getVideoConstraints(videoConstraints)
      : false;
    const isFileInputVideoSource = this._isFileInputVideoSource(constraints);
    const isSameConstraints = this._isSameVideoConstraintsAsPrev(constraints);
    const presetAudioConstraints = this._getPresetAudioConstraints();
    this._clearPresetVideoStreamAutoStopTimer();
    Zoom_Monitor.add_monitor(
      `STARTVIDEOCAPTURE:${!!this._captureVideoDeferred}:${isSameConstraints}`
    );
    if (isSameConstraints) {
      this._handleVideoCaptureSuccess(
        this.videoStream,
        constraints,
        successHandler,
        true
      );
      return;
    }
    this.destoryVideoMediaStream();

    //We Need to use Webrtc to get media data.
    const finalConstraints = {
      audio: isFileInputVideoSource ? null : presetAudioConstraints,
      video: constraints,
    };
    log('try to getusermedia', finalConstraints);
    try {
      this.isCaptureVideoInProgress = true;
      /** Some devices like MTR-Android may not get any reponse from system when call getUserMedia.
       * Need a timeout to backup.
       * */
      let hasTimeout = false;
      let mediaStream;
      this._clearCaptureVideoTimer();
      if (timeout) {
        this.captureVideoTimer = setTimeout(() => {
          if (!mediaStream) {
            hasTimeout = true;
            const timeoutError = new Error('GetUserMedia timeout');
            errorHandler(timeoutError);
          }
        }, timeout);
      }

      let startTime = Date.now();
      if (isFileInputVideoSource) {
        mediaStream = await this._captureFileInputVideoStream(constraints);
      } else {
        try {
          mediaStream = await navigator.mediaDevices.getUserMedia(
            finalConstraints
          );
        } catch (ex) {
          mediaStream = await navigator.mediaDevices.getUserMedia(
            Object.assign({}, finalConstraints, {
              video: true,
            })
          );
        }
      }

      this._clearCaptureVideoTimer();
      if (mediaStream) {
        let videoTrack = mediaStream.getVideoTracks()[0];

        if (videoTrack && videoTrack.getCapabilities) {
          let capabilities = videoTrack.getCapabilities();
          if (capabilities?.width?.max >= 1920) {
            this.isSupportFullHD = true;
          }
        }

        let videoConstraints = videoTrack.getSettings();
        if (videoConstraints) {
          const facingMode = videoConstraints.facingMode;

          if (facingMode === 'user') {
            this.facingMode = consts.FACE_MODE_USER;
          } else if (facingMode === 'environment') {
            this.facingMode = consts.FACE_MODE_ENVIRONMENT;
          } else {
            this.facingMode = consts.FACE_MODE_UNKNOW;
          }
        }
        let cameraId = videoConstraints.deviceId;
        let cameraLabel = videoTrack.label;
        if (hasTimeout) {
          this._destoryStream(mediaStream);
          deviceManager.updateSelectedCameraDevices(
            cameraId,
            cameraLabel,
            videoConstraints.width,
            videoConstraints.height,
            videoConstraints.frameRate,
            Date.now() - startTime,
            false
          );
          return;
        }
        deviceManager.updateSelectedCameraDevices(
          cameraId,
          cameraLabel,
          videoConstraints.width,
          videoConstraints.height,
          videoConstraints.frameRate,
          Date.now() - startTime,
          true
        );
      } else {
        errorHandler(new Error('GetUserMedia ret null'));
        return;
      }

      this._splitVideoStream(
        mediaStream,
        finalConstraints,
        successHandler,
        errorHandler
      );
    } catch (e) {
      errorHandler(e);
    } finally {
      this.isCaptureVideoInProgress = false;
    }
  }

  playVideoStream(domNode) {
    if (domNode) {
      domNode.setAttribute('muted', '');
      domNode.setAttribute('playsinline', '');
      try {
        domNode.srcObject = this.videoStream;
      } catch (error) {
        domNode.src = URL.createObjectURL(this.videoStream);
      }
      const promise = domNode.play();
      if (promise) {
        return promise
          .then(() => {
            // Automatic playback started!
          })
          .catch((error) => {
            globalTracingLogger.error('Video element playback failed', error);
            throw error;
          });
      } else {
        return Promise.resolve(undefined);
      }
    } else {
      return Promise.reject(new Error('video dom node not provided!'));
    }
  }

  /**
   * @description destory reuse stream
   */
  destoryReuseStream({ audio, video } = {}) {
    this.enableReuseStream(false);
    if (audio) this.destoryAudioMediaStream();
    if (video) this.destoryVideoMediaStream();
  }
}

const mediaStreamController = new MediaStreamController();

const log = Log('sdk');
var updateDeviceListener = false;

let enableLog = false;
try {
  enableLog = __ENABLE_LOG__;
} catch (e) {
  //
}

/**
 * interface IVMapType {
 *   [key: WORKER_TYPE]: string;
 * }
 *
 * @param sdkProps.ivObj {IVMapType} - for wasm aes gcm, iv would be passed to wasm
 * Map<nodeid, userid>
 *
 * @constructor
 */
var orientation = 0;
const orientationListener = (event) => {
  switch (window.orientation) {
    case 0:
      orientation = 1;
      break;
    case 90:
      orientation = 2;
      break;
    case 180:
      orientation = 3;
      break;
    case -90:
      orientation = 0;
      break;
    default:
      orientation = 0;
      break;
  }
};
const screenOrientationListener = (ev) => {
  /**
   * For screens with a natural portrait orientation:
   * portrait-primary: 0°
   * landscape-primary: 90°
   * portrait-secondary: 180°
   * landscape-secondary: 270°
   * For screens with a natural landscape orientation:
   * landscape-primary: 0°
   * portrait-primary: 90°
   * landscape-secondary: 180°
   * portrait-secondary: 270°
   **/

  let angle = ev.target.angle || 0;
  let type = ev.target.type;

  switch (angle) {
    case 0:
      //orientation 0;
      orientation = type == 'landscape-primary' ? 0 : 1;
      break;
    case 90:
      //orientation -90;
      orientation = type == 'portrait-primary' ? 1 : 0;
      break;
    case 180:
      //orientation 180;
      orientation = type == 'landscape-secondary' ? 2 : 3;
      break;
    case 270:
      //orientation 90;
      orientation = type == 'portrait-secondary' ? 3 : 2;
      break;
    default:
      orientation = 0;
      break;
  }
};

(function installWinLister() {
  try {
    if (screen?.orientation && false) {
      screen.orientation.removeEventListener(
        'change',
        screenOrientationListener
      );
      screen.orientation.addEventListener('change', screenOrientationListener);
      screenOrientationListener({ target: screen.orientation });
    } else {
      window?.removeEventListener('orientationchange', orientationListener);
      window?.addEventListener('orientationchange', orientationListener);
      orientationListener();
    }
  } catch (e) {}
})();

(function visibility() {
  document.addEventListener('visibilitychange', () => {
    let info = util.getMachineCapability();
    if (document.hidden) {
      info.unvisibilitycount++;
      info.visibility = false;
    } else {
      info.visibility = true;
    }
  });
})();
function EnableVideoEncodConsumerIntervalEnable() {
  return util.isIphoneOrIpadSafari();
}

function CreateAutoPlayVideoElement() {
  let video = document.createElement('video');
  video.setAttribute('muted', '');
  video.setAttribute('playsinline', '');
  return video;
}

var JsMediaSDK_Instance = function (sdkProps = {}) {
  log('sdkProps', sdkProps);
  /** _id session id absolute increment */
  this._id = new Date().getTime() + Math.floor(performance.now());
  try {
    Zoom_Monitor.add_monitor(
      `JSBN:${JsMediaSDK_Instance.buildNumber},${JsMediaSDK_Instance.fixVersion}`
    );
    Zoom_Monitor.add_monitor(`NewInstance:${this._id}`);
    log(`JSBN:${JsMediaSDK_Instance.buildNumber}`);
  } catch (e) {
    log(e);
  }

  // deprecated, using default value
  this.lateLoadedAssetsHash = {};
  if (sdkProps.globalTracingLogger) {
    globalTracingLogger.setInstance(sdkProps.globalTracingLogger);
  }
  globalTracingLogger.clearHighFrequencyLogs();
  this.isSupportThread =
    typeof WebAssembly === 'object' && typeof Worker === 'function';
  if (!this.isSupportThread) {
    throw new Error('Webassemly or worker is not supported on this browser');
  }

  let sharedBuffer = jsMediaEngineVariables.sharedBuffer;
  jsMediaEngineVariables.reinit(this);
  if (sharedBuffer) {
    jsMediaEngineVariables.sharedBuffer = sharedBuffer;
  }

  this.localLog = null;
  if (enableLog) {
    this.localLog = new LocalLog();
    this.localLog.init();
  }

  deviceManager.init();

  if (!updateDeviceListener) {
    updateDeviceListener = true;
    navigator.mediaDevices.addEventListener(
      'devicechange',
      deviceManager.updateDeviceList.bind(deviceManager)
    );
  }

  this.userId = '';
  this.selfPreviewVideotag = null;
  this.sharingCanvasList = [];
  this.audioCtx = createAudioContext('Main');
  this.sharingAudioCtx = null; //createAudioContext('MainSharing');
  this.webrtcAudioNode = null;
  this.sharingWebrtcAudioNode = null;
  this.videoRenderArray = [];
  this.videoCaptureValue = null;
  this.audioRenderArray = [];
  this.audioCapture = null;
  this.deviceInfo = new Map();
  this.display = [];
  this.sampleRate = this.audioCtx.sampleRate;
  this.videoRenderIntervalHandle = null;
  this.audioDomNode = null;
  this.audioSpeakerValue = null;
  this.audionoderecordbuffersize = 2048; // record buffer
  this.lastresidual = null;
  this.currentactive = 0;
  this.audioPlay = false;
  this.sharingInterval = null;
  this.sharingIntervalTime = 100; // sharing render interval 100ms
  this.sharingDisplay = null;
  this.currentshareactive = 0;

  this.videorenderinterval = 30; // render interval 10ms
  this.waterMarkCanvas = null;
  this.videoCaptureHiddenCanvas = null;
  this.videoCaptureHiddenCanvasCtx = null;
  this.isSetCursor = false;
  this.logon = false;
  this.canvas = null;
  this.firstSetDelay = true;
  this.isSelfViewWithVideo = false;
  this.isSupportOffscreenCanvas = util.isSupportOffscreenCanvas();
  this.isSupportImageCapture = util.isSupportImageCapture();
  this.isSharingSupportImageCapture = this.isSupportImageCapture;
  this.isSupportVideoTrackReader = util.isSupportVideoTrackReader();
  this.isSupportSharingTrackReader = this.isSupportVideoTrackReader;
  this.isSupportVideoShare =
    util.isSupportVideoShareReceive() && util.isSupportVideoShareSend();
  this.isSupportMediaStreamTrackProcessor =
    util.isSupportMediaStreamTrackProcessor();
  this.isSupportVideoFrameOrBitmapCapture =
    util.isSupportVideoFrameOrBitmapCapture();

  this.isMultipleCanvasForMultipleView = 0;
  this.canISendNextSharingFrame = true;
  this.isCaputureNodeConnect = false;
  this.isSharingCaptureNodeConnect = false;
  this.audioWorkletJsPath = null;
  this.isSupportChromeWideAEC = util.isSupportChromeWideAEC();
  //this.isSupportChromeWideAEC = false;

  this.isSupport2DCanvasDrawFrame = util.isSupport2DCanvasDrawFrame();
  this.MaskImage = null;
  this.BgImage = null;
  this.bgCanvas = null;
  this.bgCanvasctx = null;
  this.isCurrentInMaskStatus = false;
  this.maskCoordinate = {
    dx: 0,
    dy: 0,
    dWidth: 0,
    dHeight: 0,
  };
  this.VideoMaskSettingCanvas = null;
  this.VideoMaskSettingCanvasctx = null;
  this.PrevVideoMaskSettingCanvas = null;
  /**
   * @description mask video render fill mode
   * @enum 0: contain mode, show the full video
   * @enum 1: cover mode, fill all the render area
   * @default 0
   */
  this.VideoMaskCanvasFillMode = 0;
  this.isMaskSettingOn = false;
  this.isMaskSettingStarted = false;
  this.MaskData = null;
  // The video element will be shared by mask setting and vb setting
  this.MaskSettingVideo = null;
  this.ReadyCapureWidth = 640;
  this.ReadyCapureHeight = 360;
  this.bgImageCroppingParams = {
    sx: 0,
    sy: 0,
    sw: 0,
    sh: 0,
  };
  this.VideoVBSettingCanvas = null;
  this.isVBSettingOn = false;

  this.sMonitorCaptureFrameCount = 0;
  this.vMonitorCaptureFrameCount = 0;

  this.videoCaptureInterval = 0;
  this.isStartVideoCapture = false;
  this.videoCaptureWidth = 0;
  this.videoCaptureHeight = 0;
  this.videoCaptureContext = null;
  this.isCreateVideoWaterMark = false;
  this.videoWaterMarkName = '';
  this.sharingWaterMarkName = '';
  this.isCreateSharingWaterMark = false;

  this.videoWaterMarkParams = {};
  this.sharingWaterMarkParams = {};

  //for desktop sharing
  this.isStartDesktopSharing = false;
  this.desktopSharingValue = null;
  this.desktopCaptureContext = null;
  this.desktopSharingMediaStram = null;
  this.desktopSharingCaptureWidth = 0;
  this.desktopSharingCaptureHeight = 0;
  this.desktopSharingSend = false;
  this.isdesktopCaptureLoadedmetadata = false;
  this.captureVideoOutputCanvasDomList = [];
  this.captureVideoOutPutVideoDom = null;
  this.shareTrack = null;
  // Determine whether the video loading camera data stream is complete
  this.isVideoCaptureLoadedmetadata = false;
  this.videoloadedmetadatahandle = null;
  // cache value variable from calling method START_CAPTURE_VIDEO
  this.VALUE_CACHE_FOR_START_CAPTURE_VIDEO = {};
  this.vMonitorCount = 0;
  this.sMonitorCount = 0;
  this.mMonitorCount = 0;
  this.sharingTrackReader;
  this.sharingMediaStreamTrackProcessor;
  this.sharingFrameStream;
  this.isSendVideoOfflineCanvas = false;
  jsMediaEngineVariables.mediaSDKHandle = this;
  this._preloadMeetingParam = null;
  this.is32bitbrowser = false;

  this.flipSend = true;
  this.mtu_size = 0;
  this.sharingImageCapture = null;
  this.sharingRenderCanvas = null;
  this.VideoRenderObj = null;
  this.SharingRenderObj = null;

  this.rtcConnectionA = null;
  this.rtcConnectionB = null;
  this.isLocalP2PSupportedPromise = util.checkLocalP2PConnection();
  this.isSupportBrowserAec = true;
  this.sharingWidthAndHeightInfo = {
    ctiveNodeID: 0,
    height: 0,
    logicHeight: 0,
    logicWidth: 0,
    width: 0,
  };
  this.remoteControl = null;

  this.isMirrorMyVideo = false;
  this.isCanvasScaled = false;
  /**
   * indicate if the dataChannel for video decode/encode is inited, video decode/encode use the same dataChannel
   * so only one dataChannel is required.
   */
  this.isInitVideoDataChannel = false;
  this.isInitAudioDataChannel = false;
  this.rtcPeerConnectionList = [];
  // isDestroy is changed by "destroy" method
  this.isDestroy = false;
  this.destoryPromise = null;
  this.rwgAgentMessageListenerWrapper = this.rwgAgentMessageListener.bind(this);
  this.bVideoDecodeWorker2MainSABPostSuccessDeferred = new Deferred();
  // false => do not copy data from sharedArrayBuffer, using a typeArrayView to access the shared memory
  this.bVideoDecodeFrameBackSABRingBufferConsumeCopyData = false;
  //datachannel data => worker
  this.bVideoDecodeUsingSAB = util.isSupportSharedArrayBuffer();
  // video encode data worker => main thread
  this.bVideoEncodeUsingSAB = util.isSupportSharedArrayBuffer();
  // audio encode data worker => main thread
  this.bAudioEncodeUsingSAB = util.isSupportSharedArrayBuffer();
  // datachannel audio data from main thread => worker
  this.bAudioDecodeUsingSAB = util.isSupportSharedArrayBuffer();
  this.audioEncodeRingBufferConsumer = null;
  this.audioDecodeRingBuffer = null;
  this.videoEncodeRingbufferConsumer = null;
  this.videoDecodeFrameBackSABRingBufferConsumer = null;
  this.bVideoEncodeMainThreadConsumerIntervalEnable =
    EnableVideoEncodConsumerIntervalEnable();

  this.isSupportOffscreenCanvasForVideoDecode = util.isSupportOffscreenCanvas();
  this.bAudioDatachannelOpen = null;

  this.isMultiView = false;
  this.webrtcaudiodom = null;

  this.checkWorkletInterval = null;

  if (process.env.NODE_ENV === 'development') {
    try {
      window.zsdk = this;
      console.log('getBrowserVersion()', util.getBrowserVersion());
    } catch (ex) {
      globalTracingLogger.error('Error getting browser version', ex);
    }
  }

  if (sdkProps.ivObj) {
    jsMediaEngineVariables.ivObj = sdkProps.ivObj;
  }

  this.WebSipClient = null;
  this.lastRealRect = { left: 0, top: 0, width: 0, height: 0 };
  this.videodecodehardwareflag = null;
  this.videoencodehardwareflag = null;
  this.isEnableVideoDecodeHardWareThread = false;
  this.hardwareflag = null;
  this.isEnableHardWareThread = false;
  this.isTeslaMode = false;

  // this.videomessagechannel = null;
  this.useAudioBridge = false;
  this.audioBridge = null;
  this.replaceCanvasMap = {};
  this.hidAvalible = false;
  this.enableHID = false;
  this.audioInputLevel = null;
  this.captureAudioMuted = false;
  this.setvideokk = false;
  this.isStartWhiteboardSharing = false;
  this.sharingDecodeRingBuffer = null;
  this.ZoomMonitor = Zoom_Monitor.add_monitor.bind(Zoom_Monitor);
  this.isLandScape = false;
  this.captureSize = null;
  this.platformType = consts.WCL_PLATFORM_TYPE.DESKTOP;
  this.allpromises = [];
  this.RenderInMain = consts.RENDER_UNSET;
  this.supportLoseContext = false;
  //video channel flag
  this.vcFlag = false;
  if (ComputePressureManager.isSupportComputePressure()) {
    this.computePressureManager = new ComputePressureManager();
  }

  this.renderManager = new RenderManager('JsMediaSDK');
  this.rendererType = -1;
  this.webgpucapcity = 0;
  this.enableVBWasmBackend = 0;
  this.isWebGL2Enabled = 0;
};

// __JENKINS_SDK_BUILD_NUMBER__ will be replaced by webpack
JsMediaSDK_Instance.buildNumber = __JENKINS_SDK_BUILD_NUMBER__;
JsMediaSDK_Instance.version = __SDK_BUILD_VERSION__;
JsMediaSDK_Instance.util = util;
JsMediaSDK_Instance.fixVersion = 'Web-Media-Hotfix-0531';

JsMediaSDK_Instance.prototype = {
  JsMediaSDK_Log: function (e) {
    globalTracingLogger.error('Error in JsMediaSDK', e);
  },
  /**
   * Pre-load the JS files, which are entered into the worker to run
   * @param meeting_param.videoEncWorkerPath {String}
   * @param meeting_param.videoDecWorkerPath {String}
   * @param meeting_param.audioEncWorkerPath {String}
   * @param meeting_param.audioDecWorkerPath {String}
   * @param meeting_param.imageDecWorkerPath {String} sharing decode websocket url
   * @param call_back
   * @constructor
   */
  JsMediaSDK_PreLoad: function (meeting_param, call_back, globalEnvParams) {
    const {
      isTeslaMode,
      isGoogleMeetMode,
      enableMultiDecodeVideoWithoutSAB,
      enableVirtualBackgroundWithoutSAB,
      enableDecoderInWorklet,
      enableAudioBridge,
      disableStreamingInstantiate,
    } = globalEnvParams || {};
    //decoder in worklet is hard code true, this OP flag will be used by chromewideAEC
    jsMediaEngineVariables.decoderinworkletOP = true;
    jsMediaEngineVariables.chromeWideAEC = enableDecoderInWorklet;
    jsMediaEngineVariables.enableStreamingInstantiate =
      !disableStreamingInstantiate;
    /** JPM wants to render multi video when not support sharedArrayBuffer */
    jsMediaEngineVariables.enableMultiDecodeVideoWithoutSAB =
      enableMultiDecodeVideoWithoutSAB;
    jsMediaEngineVariables.enableVirtualBackgroundWithoutSAB =
      enableVirtualBackgroundWithoutSAB;
    jsMediaEngineVariables.enableAudioBridge = enableAudioBridge;
    this.isTeslaMode = !!isTeslaMode;
    jsMediaEngineVariables.isGoogleMeetMode = isGoogleMeetMode;
    let audioWorkletPath = {
      audioWorkletPath: meeting_param.audioWorkletPath,
      audioWorkletSIMDPath: meeting_param.audioWorkletSIMDPath,
      audioWorkletProcessPath: meeting_param.audioWorkletProcessPath,
      sharingAudioWorkletPath: meeting_param.sharingAudioWorkletPath,
      audioLevelWorkletPath: meeting_param.audioLevelWorkletPath,
      audioWasm: meeting_param.audioWasm,
      audioSIMDWasm: meeting_param.audioSIMDWasm,
    };
    this.setPropsBeforeInit({
      callback: call_back,
      audioWorkletPath: audioWorkletPath,
    });
    this._preloadMeetingParam = meeting_param;
    jsMediaEngine.Start_Monitor(this);
    this.handleUnloadEvent();
    Zoom_Monitor.add_monitor('JSPLD');
    if (
      globalEnvParams &&
      globalEnvParams.file &&
      globalEnvParams.resourceManager
    ) {
      jsMediaEngine.prepareVBfile(
        globalEnvParams.file,
        globalEnvParams.resourceManager
      );
    }
    if (this.computePressureManager) {
      this.computePressureManager.init();
    }

    util.isSDKSupportSIMD().then((value) => {
      this.isSupportVideoShare = this.isSupportVideoShare && value;
    });
  },

  getWebRTCFlag() {
    return this.webrtcConfig && this.webrtcConfig.webrtcflag;
  },

  isPreviewVideotag(userId) {
    return this.userId == userId && util.isSelfPreviewRenderWithVideo();
  },

  /**
   * during sharing / remote control
   * there is some important information about width/height and so on about the destination media stream
   * these info is from sharing websocket, the method is for recording these info
   */
  recordSharingParamInfo() {
    let self = this;
    PubSub.on(jsEvent.SHARING_PARAM_INFO_FROM_SOCKET, (msg, data) => {
      Object.assign(self.sharingWidthAndHeightInfo, data.body);
    });
  },

  /**
   * set notify callback for video init success, start camera success , start remote control success or not...
   * every message should be exported will be exported through the callback function
   * @param callback function
   */
  setCallback(callback) {
    if (!isFunction(callback)) {
      throw new Error('callback must be function');
    }
    jsMediaEngineVariables._Notify_APPUI = callback;
  },
  // add multi callback
  addCallback(callback) {
    if (!isFunction(callback)) {
      throw new Error('callback must be function');
    }
    jsMediaEngineVariables._callbackList.push(callback);
  },
  removeCallback(callback) {
    const index = jsMediaEngineVariables._callbackList.indexOf(callback);
    if (index !== -1) {
      jsMediaEngineVariables._callbackList.splice(index, 1);
    }
  },
  /**
   * @param props.callback {Function}
   * @param props.audioWorkerPath {String}
   */
  setPropsBeforeInit(props) {
    if (props.featureOptions) {
      let webgpupostion = 21;
      let optionbits = 1;
      this.webgpucapcity = util.getOption(
        props.featureOptions,
        webgpupostion,
        optionbits
      );
      this.enableVBWasmBackend = util.getOption(props.featureOptions, 27, 1);

      this.isWebGL2Enabled = util.getOption(
        props.featureOptions,
        29,
        optionbits
      );
      jsMediaEngineVariables.enableEchoDetection = util.getOption(
        props.featureOptions,
        31,
        1
      );
    }

    // try to read `rendererType` from props
    // it will be used to decide which renderer type should be used
    // but the renderer type might not be supported by MediaSDK
    // so it's not the final renderer type
    if (props && props.rendererType != undefined) {
      this.rendererType = props.rendererType;
      this.handleRendererTypeInProps(this.rendererType).then((rtResult) => {
        // notify the web client a result
        jsMediaEngineVariables.Notify_APPUI(
          jsEvent.SYNC_RENDERER_TYPE_RESPONSE,
          rtResult
        );
        this.renderManager
          .getRendererProvider()
          .setRendererType(rtResult.rendererType);
        this.rendererType = rtResult.rendererType;
        globalTracingLogger.log(
          `handleRendererTypeInProps() final rendererType=${rtResult.rendererType}`
        );
        console.log(
          `handleRendererTypeInProps() final rendererType=${rtResult.rendererType}`
        );
      });
    }

    Zoom_Monitor.add_monitor(
      `option:${props.featureOptions}:${this.webgpucapcity}:${this.enableVBWasmBackend}:${this.isWebGL2Enabled}, wcl_rendererType:${this.rendererType},enableEchoDetection:${jsMediaEngineVariables.enableEchoDetection}`
    );

    props.callback && this.setCallback(props.callback);
    props.audioWorkletPath &&
      (this.audioWorkletJsPath = props.audioWorkletPath);
    if (props.e2eEncrypt) {
      jsMediaEngineVariables.e2eencrypt = true;
    } else {
      jsMediaEngineVariables.e2eencrypt = false;
    }
    this._setWebtransportEnable({
      enable: props.enableWebtransport,
      webtransportPort: props.webtransportPort,
    });

    if (props.bandwidth) {
      if (props.bandwidth.uplimit >= 800) {
        jsMediaEngineVariables.uplimit = (props.bandwidth.uplimit - 120) * 1000;
      } else {
        jsMediaEngineVariables.uplimit = 0;
      }
    } else if (!jsMediaEngineVariables.uplimit) {
      jsMediaEngineVariables.uplimit = 0;
    }
  },

  /**
   * Handle the renderer type if client synchronizes the saved renderer type or
   * evaluate a default supported renderer type.
   */
  async onRendererTypeSelected() {
    const rtResult = await this.handleRendererTypeInProps(this.rendererType);

    // notify the web client a result
    // jsMediaEngineVariables.Notify_APPUI(
    //   jsEvent.SYNC_RENDERER_TYPE_RESPONSE,
    //   rtResult
    // );

    this.renderManager
      .getRendererProvider()
      .setRendererType(rtResult.rendererType);
    this.rendererType = rtResult.rendererType;

    globalTracingLogger.log(
      `onRendererTypeSelected() final rendererType=${rtResult.rendererType}`
    );
    console.log(
      `onRendererTypeSelected() final rendererType=${rtResult.rendererType}`
    );
  },

  /**
   * Handle the renderer type and dispatch a result to client.
   *
   * @param {*} rendererType the renderer type from client that saved from last config
   */
  async handleRendererTypeInProps(rendererType) {
    // 1. first, to check whether the renderer type is supported or not
    const isRendererTypeSupported = await util.isRendererTypeSupported(
      rendererType,
      this.webgpucapcity,
      this.isWebGL2Enabled
    );

    let errNo = 0;
    let rendererTypeRsp = rendererType;

    // if the renderer type is not supported, need to evaluate a new supported renderer type
    // and pass it to the web client and all workers.
    if (!isRendererTypeSupported) {
      errNo = 1;
      rendererTypeRsp = await util.evaluateRendererType(
        this.webgpucapcity,
        this.isWebGL2Enabled
      );
    }

    return {
      errNo: errNo,
      rendererType: rendererTypeRsp,
    };
  },

  /**
   * @param rwgAgent  {@link https://git.zoom.us/web/web-sdk-core/tree/alpha/src/agents/rwg-agent}
   */
  setRWGAgent(rwgAgent) {
    jsMediaEngineVariables.rwgAgent = rwgAgent;
    this.addEventListenerForRWGAgent(rwgAgent);
  },
  updateQosSubscription: function (enable, workerType, pollingInterval) {
    if (
      this.audioBridge &&
      (workerType === WORKER_TYPE.AUDIO_DECODE ||
        workerType === WORKER_TYPE.AUDIO_ENCODE)
    ) {
      this.audioBridge.updateQos({ enable, workerType, pollingInterval });
      return;
    }

    let isVideo = true;
    if (this.isSupportVideoShare) {
      if (workerType == WORKER_TYPE.SHARING_DECODE) {
        workerType = WORKER_TYPE.VIDEO_DECODE;
        isVideo = false;
      }
      if (workerType == WORKER_TYPE.SHARING_ENCODE) {
        workerType = WORKER_TYPE.VIDEO_ENCODE;
        isVideo = false;
      }
    }
    const message = { enable, workerType, pollingInterval, isVideo };
    jsMediaEngine.pushMessageToWorker(
      workerType,
      message,
      consts.QOS_MONITORING,
      false
    );
  },
  addEventListenerForRWGAgent(rwgAgent) {
    rwgAgent.on('message', this.rwgAgentMessageListenerWrapper);
  },
  rwgAgentMessageListener(event) {
    if (typeof event.data !== 'string') return;
    let message = JSON.parse(event.data);
    if (!this.isDestroy) {
      switch (message.evt) {
        case jsEvent.EVT_TYPE_WS_VIDEO_DATACHANNEL_ANSWER: {
          this.setRTCPeerConnectionDatachannelAnswer(message);
          break;
        }
        default:
          break;
      }
    }
  },
  setRTCPeerConnectionDatachannelAnswer(value) {
    if (
      value.evt === jsEvent.ZOOM_CONNECTION_VIDEO_OFFER_RESPONSE_EVT ||
      value.evt === jsEvent.ZOOM_CONNECTION_AUDIO_OFFER_RESPONSE_EVT
    ) {
      log('rwg answer', value);
      if (!value.answer) return;
      switch (value.answer.type) {
        case ZOOM_CONNECTION_TYPE.ZOOM_CONNECTION_VIDEO:
          PubSub.trigger(
            jsEvent.PUBSUB_EVT.ZOOM_CONNECTION_VIDEO_OFFER_RESPONSE_EVT,
            value
          );
          break;
        case ZOOM_CONNECTION_TYPE.ZOOM_CONNECTION_AUDIO:
          PubSub.trigger(
            jsEvent.PUBSUB_EVT.ZOOM_CONNECTION_AUDIO_OFFER_RESPONSE_EVT,
            value
          );
          break;
      }
    }
  },
  /**
   * video datachannel support both video pull and push
   * Although video is transfered though datachannel, the old original websockets in web worker still reserve
   * There are some signals still transfer though websocket.
   * @param connectionID id is from RWG command socket
   */
  async initVideoDataChannel(connectionID, userid) {
    if (this.isInitVideoDataChannel) {
      if (userid) {
        const videoDataChannel = this.rtcPeerConnectionList.find(
          (rtc) => rtc.connectionType === ConnectionType.VIDEO
        );
        if (videoDataChannel) {
          videoDataChannel.checkEmptyUserIdAndResendMonitor(userid);
        }
      }
      return;
    }
    this.isInitVideoDataChannel = true;

    Zoom_Monitor.add_monitor('INITVDC');
    log('initVideoDataChannel', connectionID);
    let rtc = new RTCPeerConnectionUtil();
    if (util.browser.isFirefox) {
      rtc.delaycloseTimeout = 15000; // 15 seconds
    } else {
      /*** chrome/safari  will enter `disconnected` status  and quickly retry
       * after 5 stunRequst error(about 6 seconds) ,
       */
      rtc.delaycloseTimeout = 5000; // 5 seconds
    }

    rtc.setUserid(userid);
    rtc.setConnectionType(ConnectionType.VIDEO);
    log('rtc', rtc);
    let that = this;
    rtc.onConnectionCreated(async (rtcPeerConnection) => {
      let localDesc = rtcPeerConnection.localDescription;
      log('localDesc', localDesc);
      if (that.isDestroy) {
        return;
      }
      jsMediaEngineVariables.sendMessageToRwg(
        jsEvent.SEND_MESSAGE_TO_RWG,
        {
          evt: jsEvent.ZOOM_CONNECTION_VIDEO_OFFER_EVT,
          offer: {
            sdp: localDesc.sdp,
            type: ZOOM_CONNECTION_TYPE.ZOOM_CONNECTION_VIDEO,
          },
        },
        false
      );
      let message = await rtc.waitForAnswerFromRWG(
        jsEvent.PUBSUB_EVT.ZOOM_CONNECTION_VIDEO_OFFER_RESPONSE_EVT
      );
      log(
        'jsEvent.PUBSUB_EVT.ZOOM_CONNECTION_VIDEO_OFFER_RESPONSE_EVT',
        message
      );

      if (rtcPeerConnection != rtc.rtcPeerConnection || rtc.isDestroyed()) {
        return;
      }

      rtc.setRemoteDescription(message.answer);
      rtc.closeIfTimeout();
      let candidate = message.answer.sdp.match(/a=candidate:.+/)[0];
      log('received candidate', candidate);
      candidate = candidate.replace(/^a=/, '');
      rtc.addIceCandidate(candidate);
      rtc.listenOnDataAndSend(jsEvent.VIDEO_DATA_FROM_WORKER);
      rtc.onMessage((message) => {
        var o = new Uint8Array(message);
        if (
          (o[0] == jsEvent.RWG_WCL_PDU_QOS_DATA_VIDEO || o[0] == 132) &&
          (o[1] & 0x01) == jsEvent.DATA_DIRECTION_FROM_RECEIVE
        ) {
          jsMediaEngine.pushMessageToWorker(
            WORKER_TYPE.VIDEO_ENCODE,
            message,
            undefined,
            true
          );
        } else {
          if (!this.isSupportVideoShare && (o[0] == 133 || o[0] == 132)) {
            // debugger;
            if (this.sharingDecodeRingBuffer) {
              this.sharingDecodeRingBuffer.enqueueSafe(o, true, null);
            } else {
              jsMediaEngine.pushMessageToWorker(
                WORKER_TYPE.SHARING_DECODE,
                message,
                undefined,
                true
              );
            }
          } else {
            if (this.videoDecodeRingBuffer) {
              this.videoDecodeRingBuffer.enqueueSafe(o, true, this.ZoomMonitor);
            } else {
              jsMediaEngine.pushMessageToWorker(
                WORKER_TYPE.VIDEO_DECODE,
                message,
                undefined,
                true
              );
            }
          }
        }
      });

      rtc.onDataChannelOpen(() => {
        jsMediaEngine.pushMessageToWorker(
          WORKER_TYPE.VIDEO_DECODE,
          {
            isDataChannelOpen: true,
          },
          'UPDATE_DATACHANNEL_STATUS',
          false,
          true
        );
        jsMediaEngine.pushMessageToWorker(
          WORKER_TYPE.VIDEO_ENCODE,
          {
            isDataChannelOpen: true,
          },
          'UPDATE_DATACHANNEL_STATUS',
          false,
          true
        );
        if (!this.isSupportVideoShare) {
          jsMediaEngine.pushMessageToWorker(
            WORKER_TYPE.SHARING_DECODE,
            {
              isDataChannelOpen: true,
            },
            'UPDATE_DATACHANNEL_STATUS',
            false,
            true
          );
        }
      });
      rtc.onDataChannelClose(() => {
        jsMediaEngine.CloseMediaNetSessionToRwg(
          ZOOM_CONNECTION_TYPE.ZOOM_CONNECTION_VIDEO,
          NET_SESSION_TYPE.NET_DATACHANNEL
        );
        jsMediaEngine.pushMessageToWorker(
          WORKER_TYPE.VIDEO_DECODE,
          {
            isDataChannelOpen: false,
          },
          'UPDATE_DATACHANNEL_STATUS'
        );
        jsMediaEngine.pushMessageToWorker(
          WORKER_TYPE.VIDEO_ENCODE,
          {
            isDataChannelOpen: false,
          },
          'UPDATE_DATACHANNEL_STATUS'
        );
        if (!this.isSupportVideoShare) {
          jsMediaEngine.pushMessageToWorker(
            WORKER_TYPE.SHARING_DECODE,
            {
              isDataChannelOpen: false,
            },
            'UPDATE_DATACHANNEL_STATUS',
            false,
            true
          );
        }
      });
    });
    rtc.initConnection(connectionID).catch((ex) => {
      log.warn('initConnection', ex);
      Zoom_Monitor.add_monitor('INITVDCERR', ex.message);
    });
    this.rtcPeerConnectionList.push(rtc);
    this.videoDataChannel = rtc;
  },
  async initAudioDataChannel(connectionID, userid) {
    if (this.isInitAudioDataChannel) {
      if (userid) {
        const audioDataChannel = this.rtcPeerConnectionList.find(
          (rtc) => rtc.connectionType === ConnectionType.AUDIO
        );
        if (audioDataChannel) {
          audioDataChannel.checkEmptyUserIdAndResendMonitor(userid);
        }
      }
      return;
    }
    this.isInitAudioDataChannel = true;
    Zoom_Monitor.add_monitor('INITADC');
    log('initAudioDataChannel', connectionID);
    let rtc = new RTCPeerConnectionUtil();
    if (util.browser.isFirefox) {
      rtc.delaycloseTimeout = 15000; // 15 seconds
    } else {
      rtc.delaycloseTimeout = 5000; // 15 seconds
    }
    rtc.setUserid(userid);
    rtc.setConnectionType(ConnectionType.AUDIO);
    log('rtc', rtc);
    let that = this;
    rtc.onConnectionCreated(async (rtcPeerConnection) => {
      let localDesc = rtcPeerConnection.localDescription;
      if (that.isDestroy) {
        return;
      }
      log('localDesc', localDesc);
      jsMediaEngineVariables.sendMessageToRwg(
        jsEvent.SEND_MESSAGE_TO_RWG,
        {
          evt: jsEvent.ZOOM_CONNECTION_VIDEO_OFFER_EVT,
          offer: {
            sdp: localDesc.sdp,
            type: ZOOM_CONNECTION_TYPE.ZOOM_CONNECTION_AUDIO,
          },
        },
        false
      );
      let message = await rtc.waitForAnswerFromRWG(
        jsEvent.PUBSUB_EVT.ZOOM_CONNECTION_AUDIO_OFFER_RESPONSE_EVT
      );
      log('answer', message);
      if (rtcPeerConnection != rtc.rtcPeerConnection || rtc.isDestroyed()) {
        return;
      }
      rtc.setRemoteDescription(message.answer);
      rtc.closeIfTimeout();
      let candidate = message.answer.sdp.match(/a=candidate:.+/)[0];
      log('received candidate', candidate);
      candidate = candidate.replace(/^a=/, '');
      rtc.addIceCandidate(candidate);
      rtc.listenOnDataAndSendAudio(jsEvent.AUDIO_DATA_FROM_WORKER);
      rtc.onMessage((message) => {
        // message type is ArrayBuffer, not TypeArray
        /**
         * why need && sharedBuffer?
         * for edge chromium, set webrtc policy,  WebRtcLocalhostIpHandling =  default_public_interface_only
         * then audio worker do not support sharedArrayBuffer solution
         */
        var o = new Uint8Array(message);
        if (
          o[0] == jsEvent.RWG_WCL_PDU_QOS_DATA &&
          o[1] == jsEvent.DATA_DIRECTION_FROM_RECEIVE
        ) {
          jsMediaEngine.pushMessageToWorker(
            WORKER_TYPE.AUDIO_ENCODE,
            message,
            undefined,
            true
          );
        } else {
          let sendData = !(
            this.audioDecodeRingBuffer && jsMediaEngineVariables.sharedBuffer
          );
          if (!sendData) {
            this.audioDecodeRingBuffer.enqueue(o);
          }
          jsMediaEngine.pushMessageToWorker(
            WORKER_TYPE.AUDIO_DECODE,
            sendData ? message : true,
            undefined,
            sendData
          );
        }
      });
      rtc.onDataChannelOpen(() => {
        this.bAudioDatachannelOpen = true;
        jsMediaEngine.pushMessageToWorker(
          WORKER_TYPE.AUDIO_DECODE,
          {
            isDataChannelOpen: true,
          },
          'UPDATE_DATACHANNEL_STATUS',
          false,
          true
        );
        jsMediaEngine.pushMessageToWorker(
          WORKER_TYPE.AUDIO_ENCODE,
          {
            isDataChannelOpen: true,
          },
          'UPDATE_DATACHANNEL_STATUS',
          false,
          true
        );
      });
      rtc.onDataChannelClose(() => {
        jsMediaEngine.CloseMediaNetSessionToRwg(
          ZOOM_CONNECTION_TYPE.ZOOM_CONNECTION_AUDIO,
          NET_SESSION_TYPE.NET_DATACHANNEL
        );
        this.bAudioDatachannelOpen = false;
        jsMediaEngine.pushMessageToWorker(
          WORKER_TYPE.AUDIO_DECODE,
          {
            isDataChannelOpen: false,
          },
          'UPDATE_DATACHANNEL_STATUS'
        );
        jsMediaEngine.pushMessageToWorker(
          WORKER_TYPE.AUDIO_ENCODE,
          {
            isDataChannelOpen: false,
          },
          'UPDATE_DATACHANNEL_STATUS'
        );
      });
    });
    rtc
      .initConnection(connectionID, 'ZoomWebclientAudioDataChannel')
      .catch((ex) => {
        log.warn('initConnection', ex);
        Zoom_Monitor.add_monitor('INITADCERR', ex.message);
      });
    this.rtcPeerConnectionList.push(rtc);
    this.audioDataChannel = rtc;
  },
  init_Notify_APPUI(bInitSuccess, workerType) {
    let metaData = MEDIA_INIT_SUCCESS_CALLBACK_METADATA[workerType];
    let notifyEvent = bInitSuccess ? metaData.success : metaData.fail;
    jsMediaEngineVariables.Notify_APPUI(
      notifyEvent,
      metaData.callbackDataValue
    );
  },
  _setWebtransportEnable({ enable, webtransportPort }) {
    if (enable) {
      apiSupportUtility.setIsSupportWebtransport(true);
      if (webtransportPort) {
        jsMediaEngineVariables.webtransportPort = webtransportPort;
      } else {
        jsMediaEngineVariables.webtransportPort = 8802;
      }
    } else {
      apiSupportUtility.setIsSupportWebtransport(false);
    }
  },
  _previewInitWebtransport({
    conId,
    meetingNumber,
    rwgHost,
    webtransportPort,
    workType,
  }) {
    this._setWebtransportEnable({
      enable: true,
      webtransportPort,
    });
    if (apiSupportUtility.getIsSupportWebtransport()) {
      const isAudioEncode = workType === WORKER_TYPE.AUDIO_ENCODE;
      const isAudioDecode = workType === WORKER_TYPE.AUDIO_DECODE;
      const isVideoEncode = workType === WORKER_TYPE.VIDEO_ENCODE;
      const isVideoDecode = workType === WORKER_TYPE.VIDEO_DECODE;
      const isAudio = isAudioEncode || isAudioDecode;
      const webtransportType = isAudio ? 'a' : 'v';
      const webtransportMode = isAudioEncode || isVideoEncode ? 2 : 1;

      const webtransportURL = `https://${rwgHost}:${jsMediaEngineVariables.webtransportPort}/wc/media/${meetingNumber}?type=${webtransportType}&cid=${conId}`;
      const finalURL = `${webtransportURL}&mode=${webtransportMode}`;

      if (isAudio) {
        jsMediaEngine.Set_Audio_Web_Transport_Ip_Address(webtransportURL);
      } else {
        jsMediaEngine.Set_Video_Web_Transport_Ip_Address(webtransportURL);
      }

      const ssrc = jsMediaEngineVariables.SPECIAL_ID;
      let handleMGRName = '';
      let lazyHandleListenEvent = null;
      if (isAudioEncode) {
        handleMGRName = 'localAudioEncMGR';
        lazyHandleListenEvent =
          jsMediaEngine.preserveMessageController.nameMap
            .CREATE_AUDIO_ENCODE_HANDLE_SUCCESS;
      } else if (isAudioDecode) {
        handleMGRName = 'localAudioDecMGR';
        lazyHandleListenEvent =
          jsMediaEngine.preserveMessageController.nameMap
            .CREATE_AUDIO_DECODE_HANDLE_SUCCESS;
      } else if (isVideoEncode) {
        handleMGRName = 'localVideoEncMGR';
        lazyHandleListenEvent =
          jsMediaEngine.preserveMessageController.nameMap
            .CREATE_VIDEO_ENCODE_HANDLE_SUCCESS;
      } else if (isVideoDecode) {
        handleMGRName = 'localVideoDecMGR';
        lazyHandleListenEvent =
          jsMediaEngine.preserveMessageController.nameMap
            .CREATE_VIDEO_DECODE_HANDLE_SUCCESS;
      }
      let handle =
        jsMediaEngineVariables[handleMGRName] &&
        jsMediaEngineVariables[handleMGRName].map.get(ssrc);

      if (handle) {
        handle.postMessage({
          command: 'OPEN_MEDIA_WEBTRANSPORT',
          webtransportURL: finalURL,
          _id: this._id,
        });
      } else {
        jsMediaEngine.preserveMessageController.enqueueMessage({
          name: lazyHandleListenEvent,
          handler: () => {
            handle = jsMediaEngineVariables[handleMGRName].map.get(ssrc);
            handle.postMessage({
              command: 'OPEN_MEDIA_WEBTRANSPORT',
              webtransportURL: finalURL,
              _id: this._id,
            });
          },
          direction:
            jsMediaEngine.preserveMessageController.direction.SDK_TO_SDK,
        });
      }
    }
  },
  _previewInitMediaWebsocket({ websocketUrl, workType }) {
    const ssrc = jsMediaEngineVariables.SPECIAL_ID;
    const isAudioEncode = workType === WORKER_TYPE.AUDIO_ENCODE;
    const isAudioDecode = workType === WORKER_TYPE.AUDIO_DECODE;
    const isVideoEncode = workType === WORKER_TYPE.VIDEO_ENCODE;
    const isVideoDecode = workType === WORKER_TYPE.VIDEO_DECODE;
    const isAudio = isAudioEncode || isAudioDecode;
    if (isAudio) {
      jsMediaEngine.Set_Audio_WebSocket_Ip_Address(websocketUrl);
    } else {
      jsMediaEngine.Set_Video_WebSocket_Ip_Address(websocketUrl);
    }

    let handleMGRName = '';
    let lazyHandleListenEvent = null;
    let websocketMode = 0;
    if (isAudioEncode) {
      websocketMode = 2;
      handleMGRName = 'localAudioEncMGR';
      lazyHandleListenEvent =
        jsMediaEngine.preserveMessageController.nameMap
          .CREATE_AUDIO_ENCODE_HANDLE_SUCCESS;
    } else if (isAudioDecode) {
      websocketMode = 5;
      handleMGRName = 'localAudioDecMGR';
      lazyHandleListenEvent =
        jsMediaEngine.preserveMessageController.nameMap
          .CREATE_AUDIO_DECODE_HANDLE_SUCCESS;
    } else if (isVideoEncode) {
      websocketMode = 2;
      handleMGRName = 'localVideoEncMGR';
      lazyHandleListenEvent =
        jsMediaEngine.preserveMessageController.nameMap
          .CREATE_VIDEO_ENCODE_HANDLE_SUCCESS;
    } else if (isVideoDecode) {
      websocketMode = 5;
      handleMGRName = 'localVideoDecMGR';
      lazyHandleListenEvent =
        jsMediaEngine.preserveMessageController.nameMap
          .CREATE_VIDEO_DECODE_HANDLE_SUCCESS;
    }
    let handle =
      jsMediaEngineVariables[handleMGRName] &&
      jsMediaEngineVariables[handleMGRName].map.get(ssrc);
    if (handle) {
      handle.postMessage({
        command: 'OPEN_MEDIA_WEBSOCKET',
        websocket_ip_address: `${websocketUrl}&mode=${websocketMode}`,
      });
    } else {
      jsMediaEngine.preserveMessageController.enqueueMessage({
        name: lazyHandleListenEvent,
        handler: () => {
          handle = jsMediaEngineVariables[handleMGRName].map.get(ssrc);
          handle.postMessage({
            command: 'OPEN_MEDIA_WEBSOCKET',
            websocket_ip_address: `${websocketUrl}&mode=${websocketMode}`,
          });
        },
        direction: jsMediaEngine.preserveMessageController.direction.SDK_TO_SDK,
      });
    }
  },
  async previewInit({
    videoDecode,
    videoDataChannel,
    videoEncodeMediaWss,
    videoDecodeMediaWss,
    videoEncodeWebtransport,
    videoDecodeWebtransport,
    audioDecode,
    audioDataChannel,
    audioBridge,
    audioEncodeMediaWss,
    audioDecodeMediaWss,
    audioEncodeWebtransport,
    audioDecodeWebtransport,
  }) {
    if (!jsMediaEngine.AssertMediaSdkNotDestory(this)) {
      return false;
    }
    if (videoDecode) {
      const { urlParameters } = videoDecode || {};
      let isSupportMultiThread =
        (await util.isSDKSupportMultiThread()) &&
        (!util.isSelfPreviewRenderWithVideo() ||
          util.isAndroidBrowser() ||
          util.isMobileSafariSupportVideoFrame());
      let isSupportSIMD = await util.isSDKSupportSIMD();
      let isSupportMultiSIMDThread = isSupportSIMD && isSupportMultiThread;
      await this.onRendererTypeSelected();

      let workerParameters = {};
      workerParameters.rendererType = this.rendererType;
      this.isSupportVideoShare = this.isSupportVideoShare && isSupportSIMD;
      if (this.isSupportVideoShare) {
        workerParameters.workerJsFileUrl = urlParameters.vsmiworkerpath;
        //urlParameters.videoMSIMDWorkerPath;;
        workerParameters.workerWasmFileUrl = urlParameters.videoMSIMDWasm;
      } else if (isSupportMultiSIMDThread) {
        workerParameters.workerJsFileUrl = urlParameters.videoMSIMDWorkerPath;
        workerParameters.workerWasmFileUrl = urlParameters.videoMSIMDWasm;
      } else if (isSupportMultiThread) {
        workerParameters.workerJsFileUrl = urlParameters.videoMtWorkerPath;
        workerParameters.workerWasmFileUrl = urlParameters.videoMtWasm;
      } else if (isSupportSIMD) {
        workerParameters.workerJsFileUrl = urlParameters.videoSIMDWorkerPath;
        workerParameters.workerWasmFileUrl = urlParameters.videoSIMDWasm;
      } else {
        workerParameters.workerJsFileUrl = urlParameters.videoWorkerPath;
        workerParameters.workerWasmFileUrl = urlParameters.videoWasm;
      }
      workerParameters.integrityHelper = new IntegrityHelper(
        workerParameters.workerJsFileUrl,
        this.lateLoadedAssetsHash
      );
      jsMediaEngine.previewController.resetVideoDecode(true);
      jsMediaEngine.initVideoDecode(workerParameters, this);
    }

    if (videoDataChannel) {
      const { conId, userid } = videoDataChannel;
      this.initVideoDataChannel(conId, userid);
      jsMediaEngineVariables.monitorCid = conId;
    }

    if (videoEncodeMediaWss) {
      const { websocketUrl } = videoEncodeMediaWss;
      this._previewInitMediaWebsocket({
        websocketUrl,
        workType: WORKER_TYPE.VIDEO_ENCODE,
      });
    }

    if (videoDecodeMediaWss) {
      const { websocketUrl } = videoDecodeMediaWss;
      this._previewInitMediaWebsocket({
        websocketUrl,
        workType: WORKER_TYPE.VIDEO_DECODE,
      });
    }

    if (videoEncodeWebtransport) {
      const { conId, meetingNumber, rwgHost, webtransportPort } =
        videoEncodeWebtransport;
      this._previewInitWebtransport({
        conId,
        meetingNumber,
        rwgHost,
        webtransportPort,
        workType: WORKER_TYPE.VIDEO_ENCODE,
      });
    }

    if (videoDecodeWebtransport) {
      const { conId, meetingNumber, rwgHost, webtransportPort } =
        videoDecodeWebtransport;
      this._previewInitWebtransport({
        conId,
        meetingNumber,
        rwgHost,
        webtransportPort,
        workType: WORKER_TYPE.VIDEO_DECODE,
      });
    }

    if (audioDecode) {
      const { urlParameters } = audioDecode || {};
      let workerParameters = {};
      let isSupportSIMD = await util.isSDKSupportSIMD();
      if (isSupportSIMD) {
        workerParameters.workerJsFileUrl = urlParameters.audioSIMDWorkletPath;
        workerParameters.workerWasmFileUrl = urlParameters.audioSIMDWasm;
      } else {
        workerParameters.workerJsFileUrl = urlParameters.audioWorkerPath;
        workerParameters.workerWasmFileUrl = urlParameters.audioWasm;
      }
      workerParameters.integrityHelper = new IntegrityHelper(
        workerParameters.workerJsFileUrl,
        this.lateLoadedAssetsHash
      );
      jsMediaEngine.previewController.resetAudioDecode(true);
      jsMediaEngine.initAudioDecode(workerParameters, this);
    }

    if (audioDataChannel) {
      const { conId, userid } = audioDataChannel;
      this.initAudioDataChannel(conId, userid);
      jsMediaEngineVariables.monitorCid = conId;
    }

    if (audioBridge) {
      this.useAudioBridge = true;
      const {
        nginxHost,
        rwgHost,
        cid,
        abToken,
        isCapturingAudio,
        audioMode,
        supportLocalAB,
        useWebRTCOnDesktop,
      } = audioBridge;
      if (!this.audioBridge) {
        let workletPath = {
          jsPath: this.audioWorkletJsPath.audioLevelWorkletPath,
          wasmPath: this.audioWorkletJsPath.audioSIMDWasm,
        };
        this.audioBridge = new WebrtcAudioBridge(
          nginxHost,
          rwgHost,
          cid,
          !isCapturingAudio,
          this.codecDoAVSync,
          supportLocalAB,
          useWebRTCOnDesktop,
          workletPath
        );
      }
      if (isCapturingAudio) {
        this.audioBridge.setRecvOnly(false, mediaStreamController.audioStream);
      }
      this.audioBridge.setCodecDoAVSync(this.codecDoAVSync);
      await this.audioBridge.join(false, abToken, audioMode);
    }

    if (audioEncodeMediaWss) {
      const { websocketUrl } = audioEncodeMediaWss;
      this._previewInitMediaWebsocket({
        websocketUrl,
        workType: WORKER_TYPE.AUDIO_ENCODE,
      });
    }

    if (audioDecodeMediaWss) {
      const { websocketUrl } = audioDecodeMediaWss;
      this._previewInitMediaWebsocket({
        websocketUrl,
        workType: WORKER_TYPE.AUDIO_DECODE,
      });
    }

    if (audioEncodeWebtransport) {
      const { conId, meetingNumber, rwgHost, webtransportPort } =
        audioEncodeWebtransport;
      this._previewInitWebtransport({
        conId,
        meetingNumber,
        rwgHost,
        webtransportPort,
        workType: WORKER_TYPE.AUDIO_ENCODE,
      });
    }

    if (audioDecodeWebtransport) {
      const { conId, meetingNumber, rwgHost, webtransportPort } =
        audioDecodeWebtransport;
      this._previewInitWebtransport({
        conId,
        meetingNumber,
        rwgHost,
        webtransportPort,
        workType: WORKER_TYPE.AUDIO_DECODE,
      });
    }
  },
  previewUnInitRtcConnection() {
    try {
      this.isInitAudioDataChannel = false;
      this.isInitVideoDataChannel = false;
      this.rtcPeerConnectionList.forEach((rtc) => {
        if (rtc) {
          rtc.forceClose();
          rtc = null;
        }
      });
      this.rtcPeerConnectionList = [];
    } catch (e) {
      log.error('clear rtcPeerConnectionList err', e);
    }
    if (this.audioBridge) {
      this.audioBridge.destroy(false);
      this.audioBridge = null;
    }
    const ssrc = jsMediaEngineVariables.SPECIAL_ID;
    const videoEncodeHandle =
      jsMediaEngineVariables.localVideoEncMGR &&
      jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
    const videoDecodeHandle =
      jsMediaEngineVariables.localVideoDecMGR &&
      jsMediaEngineVariables.localVideoDecMGR.map.get(ssrc);
    const audioEncodeHandle =
      jsMediaEngineVariables.localAudioEncMGR &&
      jsMediaEngineVariables.localAudioEncMGR.map.get(ssrc);
    const audioDecodeHandle =
      jsMediaEngineVariables.localAudioDecMGR &&
      jsMediaEngineVariables.localAudioDecMGR.map.get(ssrc);
    [
      videoEncodeHandle,
      videoDecodeHandle,
      audioEncodeHandle,
      audioDecodeHandle,
    ].forEach((handle) => {
      if (handle) {
        handle.postMessage({
          command: 'CLOSE_MEDIA_WEBSOCKET',
        });
        handle.postMessage({
          command: 'CLOSE_MEDIA_WEBTRANSPORT',
          _id: this._id,
        });
      }
    });
  },
  async initVideoEncode(
    urlParameters,
    websocketUrl,
    userid,
    logon = false,
    meetingid,
    meetingnumb,
    videodecodethreadnumb,
    isMSTF,
    qoson = true,
    isenbaleHD,
    enablehw,
    is360penablehw = false,
    isPreviewMode
  ) {
    this.userId = userid;
    if (!jsMediaEngine.AssertMediaSdkNotDestory(this)) {
      return false;
    }
    this.isSupportImageCapture = isMSTF ? false : this.isSupportImageCapture;
    mediaStreamController.setStreamProcessAbility({
      isSupportImageCapture: this.isSupportImageCapture,
    });
    this.setMultiView(videodecodethreadnumb);
    this.isSupportVideoFrameOrBitmapCapture =
      this.isSupportImageCapture ||
      this.isSupportVideoTrackReader ||
      this.isSupportMediaStreamTrackProcessor;
    log('initVideoEncode');
    Zoom_Monitor.add_monitor('INITVE');
    deviceManager.setUserId(userid);

    jsMediaEngine.previewController.resetVideoEncode(isPreviewMode);
    this.is32bitbrowser = await util.is32bitChrome();
    let isSupportMultiThread =
      (await util.isSDKSupportMultiThread()) &&
      (!util.isSelfPreviewRenderWithVideo() ||
        util.isAndroidBrowser() ||
        util.isMobileSafariSupportVideoFrame());
    apiSupportUtility.setIsSupportVirtualBackground(
      !this.isTeslaMode &&
        !util.isAndroidBrowser() &&
        !isMSTF &&
        (isSupportMultiThread ||
          jsMediaEngineVariables.enableVirtualBackgroundWithoutSAB)
    );
    const isSupportVirtualBackground =
      apiSupportUtility.getIsSupportVirtualBackground();
    let isSupportSIMD = await util.isSDKSupportSIMD();
    let isSupportMultiSIMDThread = isSupportSIMD && isSupportMultiThread;
    let workerParameters = {};
    let enable720p = false;
    let graphicname = util.graphicName;
    let isAMDGraphic = graphicname ? graphicname.includes('amd') : false;
    this.isAppleGraphic = graphicname
      ? (graphicname.includes('apple m1') ||
          graphicname.includes('apple m2')) &&
        !util.isChromeVersionHigherThan(116)
      : false;
    if (
      (util.isIphoneOrIpadBrowser() ||
        util.isIpadOS() ||
        (navigator.hardwareConcurrency && navigator.hardwareConcurrency > 7) ||
        ((await util.VP9MachineDetect()) &&
          isAMDGraphic &&
          navigator.hardwareConcurrency &&
          navigator.hardwareConcurrency >= 4)) &&
      isenbaleHD
    ) {
      enable720p = true;
    }

    if (util.isIphoneOrIpadSafari() && !util.isSupportVideoFrame()) {
      enable720p = false;
    }
    this.isSupportVideoShare = this.isSupportVideoShare && isSupportSIMD;
    Zoom_Monitor.add_monitor('isMT: ' + isSupportMultiThread);
    Zoom_Monitor.add_monitor('isenbaleHD: ' + isenbaleHD);
    Zoom_Monitor.add_monitor('ENABLE720:' + enable720p);
    Zoom_Monitor.add_monitor('ISVSV:' + this.isSupportVideoShare);
    Zoom_Monitor.add_monitor('graphicname: ' + graphicname);
    if (this.is32bitbrowser) {
      Zoom_Monitor.add_monitor('is32chrome: ' + this.is32bitbrowser);
    }
    //close webcodec for apple M1 M2
    let originEnable = consts.WEBCODEC_ENCODE_OPEN_FLAG && enablehw;
    /**
     * condition for webcodec encode is high
     */
    if (
      isSupportMultiThread &&
      (isenbaleHD || is360penablehw) &&
      this.isSupportMediaStreamTrackProcessor &&
      util.isChromeVersionHigherThan(95) &&
      util.AdapterWhiteListCheckForEncoder() === 0
    ) {
      this.videoencodehardwareflag =
        consts.WEBCODEC_ENCODE_OPEN_FLAG &&
        (await util.IsSupportVideoEncodeHardwareAcceleration());
      if (this.videoencodehardwareflag) {
        Zoom_Monitor.add_monitor('VIHD:' + this.videoencodehardwareflag);
      }
      enablehw = this.videoencodehardwareflag;
    } else {
      this.videoencodehardwareflag = false;
      enablehw = false;
    }
    if (this.isSupportVideoShare) {
      workerParameters.workerJsFileUrl = urlParameters.vsmiworkerpath;
      workerParameters.workerWasmFileUrl = urlParameters.videoMSIMDWasm;
    } else if (isSupportMultiSIMDThread) {
      workerParameters.workerJsFileUrl = urlParameters.videoMSIMDWorkerPath;
      workerParameters.workerWasmFileUrl = urlParameters.videoMSIMDWasm;
    } else if (isSupportMultiThread) {
      workerParameters.workerJsFileUrl = urlParameters.videoMtWorkerPath;
      workerParameters.workerWasmFileUrl = urlParameters.videoMtWasm;
    } else if (isSupportSIMD) {
      workerParameters.workerJsFileUrl = urlParameters.videoSIMDWorkerPath;
      workerParameters.workerWasmFileUrl = urlParameters.videoSIMDWasm;
    } else {
      workerParameters.workerJsFileUrl = urlParameters.videoWorkerPath;
      workerParameters.workerWasmFileUrl = urlParameters.videoWasm;
    }
    workerParameters.integrityHelper = new IntegrityHelper(
      workerParameters.workerJsFileUrl,
      this.lateLoadedAssetsHash
    );

    if (!isPreviewMode) {
      let cid = new URL(websocketUrl).searchParams.get('cid');
      let rwgHost = new URL(websocketUrl).host;

      if (apiSupportUtility.getIsSupportWebtransport()) {
        jsMediaEngine.Set_Video_Web_Transport_Ip_Address(
          `https://${rwgHost}:${jsMediaEngineVariables.webtransportPort}/wc/media/${meetingnumb}?type=v&cid=${cid}`
        );
      }
      this.previewInit({
        videoDataChannel: {
          conId: cid,
          userid,
        },
      });
    }
    let videoencodethreadnumb = 1;
    let isSupportWebCodecEnocde = false;
    let initWebCodecFlag = false;
    // videoencodethreadnumb just mark different mode
    /** virtual background support no sab vb now */
    const isMultiThreadVB = isSupportVirtualBackground && isSupportMultiThread;
    if (isMultiThreadVB && enablehw) {
      videoencodethreadnumb = 3;
      isSupportWebCodecEnocde = true;
      if (originEnable) {
        //hardware flag true;
        initWebCodecFlag = true;
      } else {
        //hardware flag flase;
        initWebCodecFlag = false;
      }
    } else if (isMultiThreadVB || enablehw) {
      videoencodethreadnumb = 2;
      if (enablehw) {
        isSupportWebCodecEnocde = true;
        if (originEnable) {
          //hardware flag true;
          initWebCodecFlag = true;
        } else {
          //hardware flag flase;
          initWebCodecFlag = false;
        }
      }
    } else if (this.isSupportVideoShare) {
      videoencodethreadnumb = 2;
    } else if (isSupportMultiThread) {
      videoencodethreadnumb = 2;
    }
    let capacityfor720 =
      isSupportWebCodecEnocde && initWebCodecFlag && enable720p;
    util.set720pcapacity(capacityfor720);
    Zoom_Monitor.add_monitor('CAPTURE720:' + capacityfor720);

    Zoom_Monitor.add_monitor('VETNum:' + videoencodethreadnumb);

    if (util.isAndroidBrowser() && !util.isMTRAndroidWithSAB()) {
      this.platformType = consts.WCL_PLATFORM_TYPE.ANDROID;
    } else if (util.isIphoneOrIpadBrowser()) {
      this.platformType = consts.WCL_PLATFORM_TYPE.IPHONE;
    }
    this.supportLoseContext = util.isSupportLoseContext();
    this.renderManager.setSupportLoseContext(this.supportLoseContext);
    initWebCodecFlag = initWebCodecFlag && !this.isAppleGraphic;
    await this.onRendererTypeSelected();

    let videopara = {
      log: logon,
      confId: userid,
      meetingid: meetingid,
      meetingnumb: meetingnumb,
      videodecodethreadnumb: videodecodethreadnumb,
      isSupportMultiThread: isSupportMultiThread,
      videoencodethreadnumb: videoencodethreadnumb,
      qoson: qoson,
      isSupportVirtualBackground: isSupportVirtualBackground,
      isSupportWebCodecEnocde: isSupportWebCodecEnocde,
      initWebCodecFlag: initWebCodecFlag,
      is360penablehw: is360penablehw,
      enable720p: enable720p,
      platformType: this.platformType,
      rendererType: this.rendererType,
      IsRenderInWorker:
        this.checkIsRenderInWorker() &&
        !apiSupportUtility.getIsRenderSelfVideoInEncodeWorker(true),
    };
    console.log(
      'VP9?:',
      await util.VP9MachineDetect(),
      'AMD?:',
      util.isAMDGraphic(),
      'AMDdecodecheck:',
      util.isGraphicShouldUseHardwareAccelerationDecode(),
      'isenbaleHD:' + isenbaleHD + ' enable720p?:',
      enable720p,
      'capacityfor720:',
      capacityfor720
    );
    jsMediaEngine.setVideoEngineInitProperties(websocketUrl, videopara);
    let EncodeInterval = new ReportStatic({
      interval: 10000,
      tag: 'WCL_M,VERB',
      reportcallback: (tag, max, min, count) => {
        Zoom_Monitor.add_monitor(`${tag},${max},${min},${count}`);
      },
    });
    if (!jsMediaEngine.AssertMediaSdkNotDestory(this)) {
      return false;
    }
    await jsMediaEngine.initVideoEncode(workerParameters, this);
    if (isPreviewMode || this.isDestroy) return;
    let self = this;
    this.allpromises.push(
      jsMediaEngineVariables.videoInitInstance.initSuccessPromise
    );
    return await jsMediaEngineVariables.videoInitInstance.initSuccessPromise
      .then((bSuccess) => {
        if (!jsMediaEngine.AssertMediaSdkNotDestory(self)) {
          return false;
        }
        if (bSuccess && this.bVideoEncodeUsingSAB) {
          if (isSupportMultiThread) {
            PubSub.on('VIDEO_ENCODE_PTR', (msg, data) => {
              if (self.isDestroy) {
                return;
              }
              let videoEncodeSABRingBuffer = new RingBuffer(
                data.data.buffer,
                undefined,
                undefined,
                true,
                data.offset,
                data.length,
                data.data
              );
              this.videoEncodeRingbufferConsumer = new ConsumeRB(
                videoEncodeSABRingBuffer,
                this.consumeVideoEncodeRingbuffer.bind(this),
                EncodeInterval.timeoutReport.bind(EncodeInterval)
              );
              if (this.bVideoEncodeMainThreadConsumerIntervalEnable) {
                this.videoEncodeRingbufferConsumer &&
                  this.videoEncodeRingbufferConsumer.consume(20);
              }
            });

            jsMediaEngine.pushMessageToWorker(
              WORKER_TYPE.VIDEO_ENCODE,
              {
                // buffer: videoEncodeSAB,
                bVideoEncodeMainThreadConsumerIntervalEnable:
                  this.bVideoEncodeMainThreadConsumerIntervalEnable,
              },
              'VIDEO_ENCODE_SAB',
              false,
              true
            );
          } else {
            let videoEncodeSAB = RingBuffer.getStorageForCapacity();
            let videoEncodeSABRingBuffer = new RingBuffer(
              videoEncodeSAB,
              undefined,
              undefined,
              true
            );
            this.videoEncodeRingbufferConsumer = new ConsumeRB(
              videoEncodeSABRingBuffer,
              this.consumeVideoEncodeRingbuffer.bind(this),
              EncodeInterval.timeoutReport.bind(EncodeInterval)
            );
            if (this.bVideoEncodeMainThreadConsumerIntervalEnable) {
              this.videoEncodeRingbufferConsumer &&
                this.videoEncodeRingbufferConsumer.consume(20);
            }
            jsMediaEngine.pushMessageToWorker(
              WORKER_TYPE.VIDEO_ENCODE,
              {
                buffer: videoEncodeSAB,
                bVideoEncodeMainThreadConsumerIntervalEnable:
                  this.bVideoEncodeMainThreadConsumerIntervalEnable,
              },
              'VIDEO_ENCODE_SAB',
              false,
              true
            );
          }
        }

        return bSuccess;
      })
      .then((bSuccess) => {
        Zoom_Monitor.add_monitor(
          `INITVERET-${self._id}-${bSuccess && !self.isDestroy}`
        );
        if (!jsMediaEngine.AssertMediaSdkNotDestory(self)) {
          return false;
        }

        this.init_Notify_APPUI(bSuccess, WORKER_TYPE.VIDEO_ENCODE);
        jsMediaEngine.preserveMessageController.subscribeMessage(
          jsMediaEngine.preserveMessageController.nameMap
            .INIT_VIDEO_ENCODE_SUCCESS
        );
        // retry send user node list for encrypt
        jsMediaEngine.setCachedUserNodeListToWorker(WORKER_TYPE.VIDEO_ENCODE);
        return bSuccess;
      });
  },
  async initVideoDecode(
    urlParameters,
    websocketUrl,
    userid,
    logon = false,
    meetingid,
    meetingnumb,
    videodecodethreadnumb,
    enablehw = false,
    qoson = true
  ) {
    this.userId = userid;
    if (!jsMediaEngine.AssertMediaSdkNotDestory(this)) {
      return false;
    }

    jsMediaEngine.previewController.resetVideoDecode(false);
    log('initVideoDecode');
    Zoom_Monitor.add_monitor('INITVD');

    this.setMultiView(videodecodethreadnumb);
    if (
      videodecodethreadnumb > 1 &&
      !jsMediaEngineVariables.enableMultiDecodeVideoWithoutSAB &&
      (IsSupportWebGLOffscreenCanvas() ||
        util.isWebGL2Supported(this.isWebGL2Enabled))
    ) {
      //support SAB, offscreencanvas, RAF, hardwareConcurrency>=4
      this.codecDoAVSync = true;
    } else {
      this.codecDoAVSync = false;
    }

    this.is32bitbrowser = await util.is32bitChrome();

    let isSupportMultiThread =
      (await util.isSDKSupportMultiThread()) &&
      (!util.isSelfPreviewRenderWithVideo() ||
        util.isAndroidBrowser() ||
        util.isMobileSafariSupportVideoFrame());
    let isSupportSIMD = await util.isSDKSupportSIMD();
    let isSupportMultiSIMDThread = isSupportSIMD && isSupportMultiThread;
    let workerParameters = {};
    this.isEnableVideoDecodeHardWareThread = util.isChromeVersionHigherThan(95);
    if (enablehw && this.isEnableVideoDecodeHardWareThread) {
      this.videodecodehardwareflag =
        await util.IsSupportVideoDecodeHardwareAcceleration();
      enablehw = this.videodecodehardwareflag;
    }
    this.isSupportVideoShare = this.isSupportVideoShare && isSupportSIMD;

    Zoom_Monitor.add_monitor(`SupportWebCodecDecode:${enablehw}`);

    if (this.isSupportVideoShare) {
      workerParameters.workerJsFileUrl = urlParameters.vsmiworkerpath;
      //urlParameters.videoMSIMDWorkerPath;;
      workerParameters.workerWasmFileUrl = urlParameters.videoMSIMDWasm;
    } else if (isSupportMultiSIMDThread) {
      workerParameters.workerJsFileUrl = urlParameters.videoMSIMDWorkerPath;
      workerParameters.workerWasmFileUrl = urlParameters.videoMSIMDWasm;
    } else if (isSupportMultiThread) {
      workerParameters.workerJsFileUrl = urlParameters.videoMtWorkerPath;
      workerParameters.workerWasmFileUrl = urlParameters.videoMtWasm;
    } else if (isSupportSIMD) {
      workerParameters.workerJsFileUrl = urlParameters.videoSIMDWorkerPath;
      workerParameters.workerWasmFileUrl = urlParameters.videoSIMDWasm;
    } else {
      workerParameters.workerJsFileUrl = urlParameters.videoWorkerPath;
      workerParameters.workerWasmFileUrl = urlParameters.videoWasm;
    }
    workerParameters.integrityHelper = new IntegrityHelper(
      workerParameters.workerJsFileUrl,
      this.lateLoadedAssetsHash
    );
    let cid = new URL(websocketUrl).searchParams.get('cid');
    let rwgHost = new URL(websocketUrl).host;

    if (apiSupportUtility.getIsSupportWebtransport()) {
      jsMediaEngine.Set_Video_Web_Transport_Ip_Address(
        `https://${rwgHost}:${jsMediaEngineVariables.webtransportPort}/wc/media/${meetingnumb}?type=v&cid=${cid}`
      );
    }
    this.previewInit({
      videoDataChannel: {
        conId: cid,
        userid,
      },
    });

    let capacityforsub1080p =
      enablehw &&
      this.isEnableVideoDecodeHardWareThread &&
      videodecodethreadnumb > 1 &&
      navigator.hardwareConcurrency &&
      navigator.hardwareConcurrency >= 4;
    util.setsub1080pcapacity(capacityforsub1080p);
    let platformType = this.platformType;
    if (util.isAndroidBrowser() && !util.isMTRAndroidWithSAB()) {
      platformType = consts.WCL_PLATFORM_TYPE.ANDROID;
    } else if (util.isIphoneOrIpadBrowser()) {
      platformType = consts.WCL_PLATFORM_TYPE.IPHONE;
    }

    // parameters for webgpu feature
    await this.onRendererTypeSelected();

    let videopara = {
      log: logon,
      confId: userid,
      meetingid: meetingid,
      meetingnumb: meetingnumb,
      videodecodethreadnumb: videodecodethreadnumb,
      isFirefox: util.browser.isFirefox,
      isSupportMultiThread: isSupportMultiThread,
      isSupportVideoTrackReader: this.isSupportVideoTrackReader,
      isSupportOffscreenCanvas: this.isSupportOffscreenCanvas,
      isenablehw: enablehw,
      isEnableVideoDecodeHardWareThread: this.isEnableVideoDecodeHardWareThread,
      qoson: qoson,
      isEnableHardWareThread: this.isEnableHardWareThread,
      isTeslaMode: this.isTeslaMode,
      platformType: platformType,
      rendererType: this.rendererType,
    };
    jsMediaEngine.setVideoEngineInitProperties(websocketUrl, videopara);

    let videoDatachannelSAB;
    let videoDecodeFrameBackSAB;
    let videoDecodeFrameBytesPerEle = 1500000;

    if (this.bVideoDecodeUsingSAB) {
      if (isSupportMultiThread) {
        PubSub.on('VIDEO_DECODE_PTR', (msg, data) => {
          this.videoDecodeRingBuffer = new RingBuffer(
            data.data.buffer,
            undefined,
            undefined,
            undefined,
            data.offset,
            data.length,
            data.data
          );
        });
      } else {
        videoDatachannelSAB = RingBuffer.getStorageForCapacity();
        this.videoDecodeRingBuffer = new RingBuffer(videoDatachannelSAB);
      }
    }
    if (!jsMediaEngine.AssertMediaSdkNotDestory(this)) {
      return false;
    }
    await jsMediaEngine.initVideoDecode(workerParameters, this);

    if (!jsMediaEngine.AssertMediaSdkNotDestory(this)) {
      return false;
    }
    let self = this;
    this.allpromises.push(
      jsMediaEngineVariables.videoDecInitInstance.initSuccessPromise
    );
    return await jsMediaEngineVariables.videoDecInitInstance.initSuccessPromise
      .then(async (bSuccess) => {
        if (!jsMediaEngine.AssertMediaSdkNotDestory(self)) {
          return false;
        }
        if (bSuccess && this.bVideoDecodeUsingSAB) {
          let bSuccess = await jsMediaEngine.pushMessageToWorker(
            WORKER_TYPE.VIDEO_DECODE,
            isSupportMultiThread ? null : videoDatachannelSAB,
            'VIDEO_DECODE_SAB_FROM_DATACHANNEL',
            false,
            true
          );
          // if send SAB failed, set it to null, then datachannel data do not push to shared buffer
          if (!bSuccess) {
            this.videoDecodeRingBuffer = null;
          }
          log('videoDatachannelSAB', bSuccess);
        }
        if (bSuccess && videoDecodeFrameBackSAB) {
          let bSuccess = await jsMediaEngine.pushMessageToWorker(
            WORKER_TYPE.VIDEO_DECODE,
            {
              buffer: videoDecodeFrameBackSAB,
              bytesPerEle: videoDecodeFrameBytesPerEle,
            },
            'VIDEO_DECODE_FRAME_BACK_SAB',
            false,
            true
          );
          this.bVideoDecodeWorker2MainSABPostSuccessDeferred.resolve(bSuccess);
          log('videoDecodeFrameBackSAB', bSuccess);
        }
        return bSuccess;
      })
      .then((bSuccess) => {
        Zoom_Monitor.add_monitor(
          `INITVDRET-${self._id}-${bSuccess && !self.isDestroy}`
        );
        if (!jsMediaEngine.AssertMediaSdkNotDestory(self)) {
          return false;
        }
        this.init_Notify_APPUI(bSuccess, WORKER_TYPE.VIDEO_DECODE);
        jsMediaEngine.preserveMessageController.subscribeMessage(
          jsMediaEngine.preserveMessageController.nameMap
            .INIT_VIDEO_DECODE_SUCCESS
        );
        // retry send user node list for encrypt
        jsMediaEngine.setCachedUserNodeListToWorker(WORKER_TYPE.VIDEO_DECODE);
        return bSuccess;
      });
  },
  async initAudioEncode(
    urlParameters,
    websocketUrl,
    userid,
    logon = false,
    meetingid,
    meetingnumb,
    videodecodethreadnumb,
    qoson = true,
    isPreviewMode
  ) {
    this.userId = userid;
    log('initAudioEncode');
    if (!jsMediaEngine.AssertMediaSdkNotDestory(this)) {
      return false;
    }
    Zoom_Monitor.add_monitor('INITAE');
    deviceManager.setUserId(userid);

    jsMediaEngine.previewController.resetAudioEncode(isPreviewMode);
    this.is32bitbrowser = await util.is32bitChrome();
    let workerParameters = {};
    let isSupportSIMD = await util.isSDKSupportSIMD();
    if (isSupportSIMD) {
      workerParameters.workerJsFileUrl = urlParameters.audioSIMDWorkletPath;
      workerParameters.workerWasmFileUrl = urlParameters.audioSIMDWasm;
    } else {
      workerParameters.workerJsFileUrl = urlParameters.audioWorkerPath;
      workerParameters.workerWasmFileUrl = urlParameters.audioWasm;
    }
    workerParameters.integrityHelper = new IntegrityHelper(
      workerParameters.workerJsFileUrl,
      this.lateLoadedAssetsHash
    );

    if (!isPreviewMode) {
      let cid = new URL(websocketUrl).searchParams.get('cid');
      let rwgHost = new URL(websocketUrl).host;
      if (apiSupportUtility.getIsSupportWebtransport()) {
        jsMediaEngine.Set_Audio_Web_Transport_Ip_Address(
          `https://${rwgHost}:${jsMediaEngineVariables.webtransportPort}/wc/media/${meetingnumb}?type=a&cid=${cid}`
        );
      }
      this.previewInit({
        audioDataChannel: { conId: cid, userid },
      });
    }
    /** When leave computer audio once, audioCtx is setted to null.
     * Webinar attendee will not start audioEncode at first, but when be allowed to talk.AudioEncode will be inited.
     */
    if (!this.audioCtx) {
      this.audioCtx = createAudioContext('Main');
    }

    let audiopara = {
      sampleRate: this.audioCtx.sampleRate,
      userid: userid,
      log: logon,
      meetingid: meetingid,
      meetingnumb: meetingnumb,
      videodecodethreadnumb: videodecodethreadnumb,
      qoson: qoson,
    };
    jsMediaEngine.setAudioEngineInitProperties(websocketUrl, audiopara);

    let audioSAB;
    let BYTES_PER_ELEMENT = 1500;
    if (this.bAudioEncodeUsingSAB && !isPreviewMode) {
      audioSAB = RingBuffer.getStorageForCapacity(100, BYTES_PER_ELEMENT);
      this.audioEncodeRingBufferConsumer = new ConsumeRB(
        new RingBuffer(audioSAB, BYTES_PER_ELEMENT, undefined, true),
        this.audioEncodeRingBufferConsumerCallback.bind(this)
      );
    }

    if (!jsMediaEngine.AssertMediaSdkNotDestory(this)) {
      return false;
    }

    await jsMediaEngine.initAudioEncode(workerParameters, this);
    if (isPreviewMode || this.isDestroy) return;

    let self = this;
    this.allpromises.push(
      jsMediaEngineVariables.audioEncodeInitInstance.initSuccessPromise
    );
    return await jsMediaEngineVariables.audioEncodeInitInstance.initSuccessPromise
      .then((bSuccess) => {
        if (self.isDestroy) {
          return false;
        }
        if (bSuccess && this.bAudioDatachannelOpen !== null) {
          jsMediaEngine.pushMessageToWorker(
            WORKER_TYPE.AUDIO_ENCODE,
            {
              isDataChannelOpen: this.bAudioDatachannelOpen,
            },
            'UPDATE_DATACHANNEL_STATUS'
          );
        }
        if (bSuccess && audioSAB) {
          jsMediaEngine.pushMessageToWorker(
            WORKER_TYPE.AUDIO_ENCODE,
            {
              buffer: audioSAB,
              bytesPerEle: BYTES_PER_ELEMENT,
              bAudioEncodeMainThreadConsumerIntervalEnable: false,
            },
            'audioEncodeSAB',
            false,
            true
          );
        }
        return bSuccess;
      })
      .then((bSuccess) => {
        Zoom_Monitor.add_monitor(
          `INITAERET-${self._id}-${bSuccess && !self.isDestroy}`
        );
        if (!jsMediaEngine.AssertMediaSdkNotDestory(self)) {
          return false;
        }
        this.init_Notify_APPUI(bSuccess, WORKER_TYPE.AUDIO_ENCODE);
        jsMediaEngine.setCachedUserNodeListToWorker(WORKER_TYPE.AUDIO_ENCODE);
        return bSuccess;
      });
  },
  async initAudioDecode(
    urlParameters,
    websocketUrl,
    userid,
    logon = false,
    meetingid,
    meetingnumb,
    videodecodethreadnumb,
    qoson = true
  ) {
    this.userId = userid;
    if (!jsMediaEngine.AssertMediaSdkNotDestory(this)) {
      return false;
    }
    jsMediaEngine.previewController.resetAudioDecode(false);
    log('initAudioDecode');
    deviceManager.setUserId(userid);

    Zoom_Monitor.add_monitor('INITAD');
    this.is32bitbrowser = await util.is32bitChrome();
    let cid = new URL(websocketUrl).searchParams.get('cid');
    let rwgHost = new URL(websocketUrl).host;
    let workerParameters = {};
    workerParameters.workerJsFileUrl = urlParameters.audioWorkerPath;
    workerParameters.workerWasmFileUrl = urlParameters.audioWasm;

    if (apiSupportUtility.getIsSupportWebtransport()) {
      jsMediaEngine.Set_Audio_Web_Transport_Ip_Address(
        `https://${rwgHost}:${jsMediaEngineVariables.webtransportPort}/wc/media/${meetingnumb}?type=a&cid=${cid}`
      );
    }
    this.previewInit({
      audioDataChannel: { conId: cid, userid },
    });

    let audioSAB;
    let bytesPerEle = 1500; /// for zoom client, enable origin sound, packet size > 500
    if (this.bAudioDecodeUsingSAB) {
      audioSAB = RingBuffer.getStorageForCapacity(100, bytesPerEle);
      this.audioDecodeRingBuffer = new RingBuffer(audioSAB, bytesPerEle);
    }
    let isSupportMultiThread = await util.isSDKSupportMultiThread();
    let isSupportSIMD = await util.isSDKSupportSIMD();
    if (isSupportSIMD) {
      workerParameters.workerJsFileUrl = urlParameters.audioSIMDWorkletPath;
      workerParameters.workerWasmFileUrl = urlParameters.audioSIMDWasm;
    } else {
      workerParameters.workerJsFileUrl = urlParameters.audioWorkerPath;
      workerParameters.workerWasmFileUrl = urlParameters.audioWasm;
    }
    workerParameters.integrityHelper = new IntegrityHelper(
      workerParameters.workerJsFileUrl,
      this.lateLoadedAssetsHash
    );

    if (!this.audioCtx) {
      this.audioCtx = createAudioContext('Main');
    }

    let audiopara = {
      sampleRate: this.audioCtx.sampleRate,
      userid: userid,
      log: logon,
      meetingid: meetingid,
      meetingnumb: meetingnumb,
      videodecodethreadnumb: videodecodethreadnumb,
      isSupportMultiThread: isSupportMultiThread,
      qoson: qoson,
    };
    jsMediaEngine.setAudioEngineInitProperties(websocketUrl, audiopara);

    if (!jsMediaEngine.AssertMediaSdkNotDestory(this)) {
      return false;
    }
    await jsMediaEngine.initAudioDecode(workerParameters, this);

    if (!jsMediaEngine.AssertMediaSdkNotDestory(this)) {
      return false;
    }
    let self = this;
    this.allpromises.push(
      jsMediaEngineVariables.audioDecInitInstance.initSuccessPromise
    );
    return await jsMediaEngineVariables.audioDecInitInstance.initSuccessPromise
      .then(async (bSuccess) => {
        if (self.isDestroy) {
          return false;
        }
        Zoom_Monitor.add_monitor('ADIP' + !!bSuccess);
        if (bSuccess && audioSAB) {
          let bool = await jsMediaEngine.pushMessageToWorker(
            WORKER_TYPE.AUDIO_DECODE,
            {
              buffer: audioSAB,
              bytesPerEle,
            },
            'audioDecodeSAB',
            false,
            true
          );
          if (!bool) {
            this.audioDecodeRingBuffer = null;
          }
        }

        if (bSuccess && this.bAudioDatachannelOpen !== null) {
          jsMediaEngine.pushMessageToWorker(
            WORKER_TYPE.AUDIO_DECODE,
            {
              isDataChannelOpen: this.bAudioDatachannelOpen,
            },
            'UPDATE_DATACHANNEL_STATUS'
          );
        }
        return bSuccess;
      })
      .then((bSuccess) => {
        Zoom_Monitor.add_monitor(
          `INITADRET-${self._id}-${bSuccess && !self.isDestroy}`
        );
        if (!jsMediaEngine.AssertMediaSdkNotDestory(self)) {
          return false;
        }
        this.init_Notify_APPUI(bSuccess, WORKER_TYPE.AUDIO_DECODE);
        jsMediaEngine.setCachedUserNodeListToWorker(WORKER_TYPE.AUDIO_DECODE);
        return bSuccess;
      });
  },
  async initSharingDecode(
    urlParameters,
    websocketUrl,
    userid,
    logon = false,
    meetingid,
    meetingnumb,
    videodecodethreadnumb
  ) {
    this.userId = userid;
    if (!jsMediaEngine.AssertMediaSdkNotDestory(this)) {
      return false;
    }
    Zoom_Monitor.add_monitor('INITSD');
    this.recordSharingParamInfo();
    log('initSharingDecode');
    let isSupportSIMD = await util.isSDKSupportSIMD();
    this.isSupportVideoShare = this.isSupportVideoShare && isSupportSIMD;
    if (this.isSupportVideoShare) {
      this.allpromises.push(
        jsMediaEngineVariables.sharingDecInitInstance.socketSuccessPromise
      );
      let self = this;
      return await jsMediaEngineVariables.sharingDecInitInstance.socketSuccessPromise.then(
        (bSuccess) => {
          Zoom_Monitor.add_monitor(
            `INITSDRET-${self._id}-${bSuccess && !self.isDestroy}`
          );
          if (!jsMediaEngine.AssertMediaSdkNotDestory(self)) {
            return false;
          }
          this.init_Notify_APPUI(bSuccess, WORKER_TYPE.SHARING_DECODE);
          jsMediaEngine.setCachedUserNodeListToWorker(
            WORKER_TYPE.SHARING_DECODE
          );
          return bSuccess;
        }
      );
    }

    jsMediaEngineVariables.monitorCid = new URL(websocketUrl).searchParams.get(
      'cid'
    );
    this.is32bitbrowser = await util.is32bitChrome();
    let isSupportMultiThread = await util.isSDKSupportMultiThread();
    await this.onRendererTypeSelected();

    let isSupportMultiSIMDThread = isSupportSIMD && isSupportMultiThread;
    let workerParameters = {};
    if (isSupportMultiSIMDThread) {
      workerParameters.workerJsFileUrl = urlParameters.sharingMSIMDWorkerPath;
      workerParameters.workerWasmFileUrl = urlParameters.videoMSIMDWasm;
    } else if (isSupportMultiThread) {
      workerParameters.workerJsFileUrl = urlParameters.sharingMtWorkerPath;
      workerParameters.workerWasmFileUrl = urlParameters.videoMtWasm;
    } else if (isSupportSIMD) {
      workerParameters.workerJsFileUrl = urlParameters.sharingSIMDWorkerPath;
      workerParameters.workerWasmFileUrl = urlParameters.videoSIMDWasm;
    } else {
      workerParameters.workerJsFileUrl = urlParameters.sharingWorkerPath;
      workerParameters.workerWasmFileUrl = urlParameters.videoWasm;
    }
    workerParameters.integrityHelper = new IntegrityHelper(
      workerParameters.workerJsFileUrl,
      this.lateLoadedAssetsHash
    );

    let sharingpara = {
      log: logon,
      userid: userid,
      meetingid: meetingid,
      meetingnumb: meetingnumb,
      isSupportMultiThread: isSupportMultiThread,
      rendererType: this.rendererType,
    };
    jsMediaEngine.setSharingEngineInitProperties(websocketUrl, sharingpara);
    if (!this.isSupportVideoShare) {
      PubSub.on('SHARING_DECODE_PTR', (msg, data) => {
        this.sharingDecodeRingBuffer = new RingBuffer(
          data.data.buffer,
          undefined,
          undefined,
          undefined,
          data.offset,
          data.length,
          data.data
        );
      });
    }

    if (!jsMediaEngine.AssertMediaSdkNotDestory(this)) {
      return false;
    }

    await jsMediaEngine.initSharingDecode(workerParameters, this);
    if (!jsMediaEngine.AssertMediaSdkNotDestory(this)) {
      return false;
    }

    this.allpromises.push(
      jsMediaEngineVariables.sharingDecInitInstance.initSuccessPromise
    );
    let self = this;
    return await jsMediaEngineVariables.sharingDecInitInstance.initSuccessPromise.then(
      (bSuccess) => {
        Zoom_Monitor.add_monitor(
          `INITSDRET-${self._id}-${bSuccess && !self.isDestroy}`
        );
        if (!jsMediaEngine.AssertMediaSdkNotDestory(self)) {
          return false;
        }
        this.init_Notify_APPUI(bSuccess, WORKER_TYPE.SHARING_DECODE);
        jsMediaEngine.setCachedUserNodeListToWorker(WORKER_TYPE.SHARING_DECODE);
        return bSuccess;
      }
    );
  },
  async initSharingEncode(
    urlParameters,
    websocketUrl,
    userid,
    logon = false,
    meetingid,
    meetingnumb,
    videodecodethreadnumb
  ) {
    this.userId = userid;
    if (!jsMediaEngine.AssertMediaSdkNotDestory(this)) {
      return false;
    }
    log('initSharingEncode');
    let isSupportSIMD = await util.isSDKSupportSIMD();
    this.isSupportVideoShare = this.isSupportVideoShare && isSupportSIMD;
    if (this.isSupportVideoShare) {
      this.allpromises.push(
        jsMediaEngineVariables.sharingEncInitInstance.socketSuccessPromise
      );
      let self = this;
      return await jsMediaEngineVariables.sharingEncInitInstance.socketSuccessPromise.then(
        (bSuccess) => {
          Zoom_Monitor.add_monitor(
            `INITSERET-${self._id}-${bSuccess && !self.isDestroy}`
          );
          if (!jsMediaEngine.AssertMediaSdkNotDestory(self)) {
            return false;
          }
          this.init_Notify_APPUI(bSuccess, WORKER_TYPE.SHARING_ENCODE);
          jsMediaEngine.setCachedUserNodeListToWorker(
            WORKER_TYPE.SHARING_ENCODE
          );
          return bSuccess;
        }
      );
    }

    Zoom_Monitor.add_monitor('INITSE');
    this.is32bitbrowser = await util.is32bitChrome();
    let isSupportMultiThread = await util.isSDKSupportMultiThread();
    await this.onRendererTypeSelected();
    let isSupportMultiSIMDThread = isSupportSIMD && isSupportMultiThread;
    let workerParameters = {};
    if (isSupportMultiSIMDThread) {
      workerParameters.workerJsFileUrl = urlParameters.sharingMSIMDWorkerPath;
      workerParameters.workerWasmFileUrl = urlParameters.videoMSIMDWasm;
    } else if (isSupportMultiThread) {
      workerParameters.workerJsFileUrl = urlParameters.sharingMtWorkerPath;
      workerParameters.workerWasmFileUrl = urlParameters.videoMtWasm;
    } else if (isSupportSIMD) {
      workerParameters.workerJsFileUrl = urlParameters.sharingSIMDWorkerPath;
      workerParameters.workerWasmFileUrl = urlParameters.videoSIMDWasm;
    } else {
      workerParameters.workerJsFileUrl = urlParameters.sharingWorkerPath;
      workerParameters.workerWasmFileUrl = urlParameters.videoWasm;
    }
    workerParameters.integrityHelper = new IntegrityHelper(
      workerParameters.workerJsFileUrl,
      this.lateLoadedAssetsHash
    );

    jsMediaEngineVariables.monitorCid = new URL(websocketUrl).searchParams.get(
      'cid'
    );
    let sharingpara = {
      log: logon,
      userid: userid,
      meetingid: meetingid,
      meetingnumb: meetingnumb,
      isSupportMultiThread: isSupportMultiThread,
      rendererType: this.rendererType,
    };
    jsMediaEngine.setSharingEngineInitProperties(websocketUrl, sharingpara);

    if (!jsMediaEngine.AssertMediaSdkNotDestory(this)) {
      return false;
    }
    await jsMediaEngine.initSharingEncode(workerParameters, this);
    if (!jsMediaEngine.AssertMediaSdkNotDestory(this)) {
      return false;
    }
    let self = this;
    this.allpromises.push(
      jsMediaEngineVariables.sharingEncInitInstance.initSuccessPromise
    );
    return await jsMediaEngineVariables.sharingEncInitInstance.initSuccessPromise.then(
      (bSuccess) => {
        Zoom_Monitor.add_monitor(
          `INITSERET-${self._id}-${bSuccess && !self.isDestroy}`
        );
        if (!jsMediaEngine.AssertMediaSdkNotDestory(self)) {
          return false;
        }
        this.init_Notify_APPUI(bSuccess, WORKER_TYPE.SHARING_ENCODE);
        jsMediaEngine.setCachedUserNodeListToWorker(WORKER_TYPE.SHARING_ENCODE);
        return bSuccess;
      }
    );
  },
  /**
   * JsMediaSDK_UnInit is different from "destroy" method,
   * JsMediaSDK_UnInit does not terminate WebWorkers
   * But "destroy" method does.
   * @constructor
   */
  JsMediaSDK_UnInit: function () {
    jsMediaEngine.Stop_Monitor(this);
    // jsMediaEngine.JsAudioEngine_UnInit();
    // jsMediaEngine.JsVideoEngine_UnInit();
    // jsMediaEngine.JsSharingEngine_UnInit();
    let that = this;
    if (this.videoRenderArray.length) {
      this.videoRenderArray.forEach(function (element) {
        if (element.display) {
          element.display.cleanup(null, that.supportLoseContext);
          element.display = null;
        }
      });
    }
    jsMediaEngineVariables._callbackList = [];
    jsMediaEngineVariables._Notify_APPUI = null;
  },
  StartAudioMediaCapture: function () {
    mediaStreamController.startCaptureAudio({
      audioConstraints: this.audioCapture
        ? {
            audioSource: this.audioCapture
              ? this.audioCapture.AudioSelectValue
              : null,
            enableOriginalSound:
              this.audioCapture?.audioProfile?.currentSelect ===
              'originalSound',
            enableStereo:
              this.audioCapture?.audioProfile?.currentSelect ===
                'originalSound' &&
              this.audioCapture?.audioProfile?.originalSound?.stereo,
            isSupportBrowserAec: this.isSupportBrowserAec,
            disableAudioAGC: this.disableAudioAGC,
            disableNoiseSuppression: this.disableNoiseSuppression,
          }
        : null,
      successHandler: this.handleAudioCapture.bind(this),
      errorHandler: this.handleAudioError.bind(this),
    });
  },
  StartDesktopMediaCapture: async function () {
    let that = this;
    if (this.desktopSharingValue.audioOnly) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            deviceId: this.desktopSharingValue.deviceId
              ? { exact: this.desktopSharingValue.deviceId }
              : util.browser.isChrome
              ? { exact: 'default' }
              : undefined,
            echoCancellation: this.desktopSharingValue.echoCancellation,
            noiseSuppression: this.desktopSharingValue.noiseSuppression,
            autoGainControl: this.desktopSharingValue.autoGainControl,
            sampleRate: this.desktopSharingValue.sampleRate || 48000,
          },
        });
        this.handleDesktopCapture(stream, true);
      } catch (e) {
        this.handleCaptureError(e);
      }
      return;
    }
    if (this.desktopSharingValue.sourceId) {
      const sourceId = this.desktopSharingValue.sourceId;
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            mandatory: {
              chromeMediaSource: 'desktop',
              chromeMediaSourceId: sourceId,
            },
          },
        });
        this.handleDesktopCapture(stream);
      } catch (e) {
        this.handleCaptureError(e);
      }
      return;
    }
    const localHandleCapture = this.handleDesktopCapture.bind(this);
    const localHandleError = this.handleCaptureError.bind(this);
    if (this.desktopSharingValue.share2ndCamera) {
      const shareCameraParams =
        this.desktopSharingValue.share2ndCameraParams || {};
      const deviceId = shareCameraParams.VideoSelectValue
        ? { exact: shareCameraParams.VideoSelectValue }
        : undefined;
      const frameRate = shareCameraParams.frameRate
        ? { ideal: shareCameraParams.frameRate }
        : undefined;
      const videoParams = {
        deviceId,
        width: shareCameraParams.width || 640,
        height: shareCameraParams.height || 360,
        frameRate,
      };

      // By default, don't capture audio at all
      let audioParams = false;
      if (shareCameraParams.AudioSelectValue) {
        audioParams = {
          deviceId: { exact: shareCameraParams.AudioSelectValue },
        };
      }

      const finalParams = { video: videoParams, audio: audioParams };

      navigator.mediaDevices
        .getUserMedia(finalParams)
        .then(localHandleCapture, localHandleError);

      return;
    }
    const sharingVideoConstraints = this.desktopSharingValue.videoParams;
    const supportedConstraints =
      navigator.mediaDevices.getSupportedConstraints &&
      navigator.mediaDevices.getSupportedConstraints();
    /** support set default share souce */
    const isDisplaySurfaceEnabled =
      supportedConstraints &&
      !!supportedConstraints.displaySurface &&
      sharingVideoConstraints &&
      sharingVideoConstraints.displaySurface;

    if (navigator.mediaDevices.getDisplayMedia) {
      //support share audio when SAB is supported
      let audioconstraints =
        util.isSupportSharedArrayBuffer() &&
        this.desktopSharingValue.showShareAudioOption
          ? {
              autoGainControl: false,
              noiseSuppression: false,
              echoCancellation: !util.isSupportSharingStereo(),
            }
          : false;
      let videoConstraints = this.desktopSharingValue.videoParams || true;
      if (isDisplaySurfaceEnabled) {
        if (typeof videoConstraints === 'object') {
          videoConstraints.displaySurface =
            sharingVideoConstraints.displaySurface;
        } else if (typeof videoConstraints === 'boolean') {
          videoConstraints = {
            displaySurface: sharingVideoConstraints.displaySurface,
          };
        }
      }
      let otherConstraints = this.desktopSharingValue.otherParams;

      let finalConstraints = {
        video: videoConstraints,
        audio: audioconstraints,
        ...otherConstraints,
      };
      navigator.mediaDevices
        .getDisplayMedia(finalConstraints)
        .then(localHandleCapture, localHandleError);
    }
  },
  StartVideoMediaCapture: async function () {
    const videoCaptureValue = this.videoCaptureValue;
    const captureParam = {
      encode: this.isStartVideoCapture,
      preview: this.isMaskSettingOn || this.isVBSettingOn,
    };
    Zoom_Monitor.add_monitor(
      `STARTVIDEOMEDIA:${captureParam.encode}:${captureParam.preview}`
    );
    mediaStreamController.startCaptureVideo({
      videoConstraints: this.videoCaptureValue
        ? {
            videoSource: this.videoCaptureValue.VideoSelectValue,
            usingFacingMode: this.videoCaptureValue.usingFacingMode,
            width: this.videoCaptureValue.width,
            height: this.videoCaptureValue.height,
            pan: this.videoCaptureValue.pan,
            tilt: this.videoCaptureValue.tilt,
            zoom: this.videoCaptureValue.zoom,
            fps: this.videoCaptureValue.fps,
          }
        : null,
      timeout: this.videoCaptureValue ? this.videoCaptureValue.timeout : null,
      successHandler: async (stream) => {
        // This is very confusing but `this.videoCaptureValue` will NULL without the following code
        this.videoCaptureValue = videoCaptureValue;
        if (mediaStreamController.videoConstraints) {
          const { width, height } = mediaStreamController.videoConstraints;
          if (width && height) {
            this.videoCaptureWidth = width;
            this.videoCaptureHeight = height;
          }
        }
        try {
          let isSuccess = await this.handleVideoCapture(stream, captureParam);
          Zoom_Monitor.add_monitor(`STARTVIDEORET:${isSuccess}`);
          if (isSuccess) {
            Zoom_Monitor.add_monitor('VCMS');
            jsMediaEngineVariables.monitorVideoCapture = true;
            // jsMediaEngineVariables.Notify_APPUI(jsEvent.START_VIDEO_CAPTURE_SUCCESS, {"currentDeviceID":deviceId});

            let videoTrack = stream.getVideoTracks()[0];
            videoTrack.onended = () => {
              jsMediaEngineVariables.Notify_APPUI_SAFE(
                jsEvent.VIDEO_STREAM_FAILED
              );
              Zoom_Monitor.add_monitor('VTRS:' + videoTrack.readyState);
              globalTracingLogger.error('video media track ended');
            };
            videoTrack.onmute = () => {
              Zoom_Monitor.add_monitor('VTMS:' + videoTrack.muted);
            };
            videoTrack.onunmute = () => {
              Zoom_Monitor.add_monitor('VTMS:' + videoTrack.muted);
            };
          }
        } catch (ex) {
          this.handleVideoError(ex);
        }
      },
      errorHandler: (ex) => {
        this.handleVideoError(ex);
      },
    });
  },
  Start_Audio_Play: function () {
    this.audioPlay = true;
    if (jsMediaEngineVariables.AudioNode) {
      jsMediaEngineVariables.AudioNode.postCMD('startPlayAudio', null);
    }
    jsMediaEngine.UpdateAudioPlayStatus(true);
    return;
  },
  Stop_Audio_Play: function () {
    jsMediaEngine.UpdateAudioPlayStatus(false);
    if (jsMediaEngineVariables.AudioNode) {
      jsMediaEngineVariables.AudioNode.postCMD('stopPlayAudio', null);
    }
  },
  Remove_Audio_Play: function () {
    jsMediaEngine.UpdateAudioPlayStatus(false);
    this.audioPlay = false;
    if (jsMediaEngineVariables.AudioNode) {
      jsMediaEngineVariables.AudioNode.postCMD('stopPlayAudio', null);
    }
  },
  Meeting_Fail_Over: function (
    audio_websocket_addresss,
    video_websocket_address
  ) {
    jsMediaEngine.Meeting_Fail_Over(
      audio_websocket_addresss,
      video_websocket_address
    );
  },
  Start_Desktop_Audio_Capture: function () {
    if (this.audioBridge) {
      this.audioBridge.publishStream(this.desktopSharingMediaStram, true);
    } else {
      let audioTracks = this.desktopSharingMediaStram.getAudioTracks();
      if (this.desktopSharingMediaStram && audioTracks) {
        var audioInput = this.sharingAudioCtx.createMediaStreamSource(
          this.desktopSharingMediaStram
        );
        this.sharingWebrtcAudioNode = audioInput;
        if (!this.isSharingCaptureNodeConnect) {
          this.sharingWebrtcAudioNode.connect(
            jsMediaEngineVariables.SharingAudioNode
          );
          jsMediaEngineVariables.SharingAudioNode.postCMD(
            'StartCaptureAudio',
            null
          );
          this.isSharingCaptureNodeConnect = true;
        }

        if (this.screenShareAudioPreviewElement) {
          this.screenShareAudioPreviewElement.play().catch((error) => {
            globalTracingLogger.error(
              'Screenshare audio preview play failed',
              error
            );
          });
        }

        return;
      }
    }
  },
  Start_Audio_Capture: function () {
    if (!mediaStreamController.shouldCaptureAudio()) {
      let deviceId = mediaStreamController.getAudioCapabilities().deviceId;
      // this means this.audioStream already exists, and the stream is not in reuse.
      if (this.audioInputLevel) {
        this.audioInputLevel.setAudioStream(mediaStreamController.audioStream);
        if (!this.captureAudioMuted) {
          this.audioInputLevel.start();
        }
        if (
          jsMediaEngineVariables.ComputerAudioStatus ===
          AudioStatus.ComputerAudio_Connecting
        ) {
          jsMediaEngineVariables.ComputerAudioStatus =
            AudioStatus.ComputerAudio_Connected;
          jsMediaEngineVariables.Notify_APPUI(
            jsEvent.JOIN_COMPUTER_AUDIO_COMPLETE,
            deviceId
          );
        }
        return;
      }
      if (this.audioBridge) {
        this.audioBridge
          .publishStream(mediaStreamController.audioStream)
          .then((result) => {
            if (
              jsMediaEngineVariables.ComputerAudioStatus ===
              AudioStatus.ComputerAudio_Connecting
            ) {
              jsMediaEngineVariables.ComputerAudioStatus =
                AudioStatus.ComputerAudio_Connected;
              jsMediaEngineVariables.Notify_APPUI(
                jsEvent.JOIN_COMPUTER_AUDIO_COMPLETE,
                deviceId
              );
            }
          });

        return;
      }

      if (jsMediaEngineVariables.AudioNode) {
        if (this.webrtcAudioNode) {
          this.webrtcAudioNode.disconnect(jsMediaEngineVariables.AudioNode);
          this.webrtcAudioNode = null;
        }
        var audioInput = this.audioCtx.createMediaStreamSource(
          mediaStreamController.audioStream
        );

        this.webrtcAudioNode = audioInput;
        this.webrtcAudioNode.connect(jsMediaEngineVariables.AudioNode);
        jsMediaEngineVariables.AudioNode.postCMD('StartCaptureAudio', null);
        this.isCaputureNodeConnect = true;
      }

      if (
        jsMediaEngineVariables.ComputerAudioStatus ===
        AudioStatus.ComputerAudio_Connecting
      ) {
        jsMediaEngineVariables.ComputerAudioStatus =
          AudioStatus.ComputerAudio_Connected;
        jsMediaEngineVariables.Notify_APPUI(
          jsEvent.JOIN_COMPUTER_AUDIO_COMPLETE,
          deviceId
        );
      }
      return;
    } else {
      this.StartAudioMediaCapture();
    }
  },
  Stop_Audio_Capture: function () {
    jsMediaEngine.Notify_Audio_Thread_Status(
      jsMediaEngineVariables.SPECIAL_ID,
      jsEvent.AUDIO_STOP
    ); //0 --- stop audio capture
  },

  /*** for video element capture */
  _videoloadedmetadata: function (ev) {
    let w, h;
    let that = this;
    let source = null;
    if (ev) {
      source = ev.target || that.videoCaptureValue.videoCtrl;
      Zoom_Monitor.add_monitor(
        `VIDEOLOADEDMETADATA:${source == that.videoCaptureValue.videoCtrl}`
      );

      if (that.videoloadedmetadatahandle && source) {
        source.removeEventListener(
          'loadedmetadata',
          that.videoloadedmetadatahandle
        );
      }
      that.videoloadedmetadatahandle = null;
    } else {
      Zoom_Monitor.add_monitor(`VIDEOLOADEDMETADATA:${true}`);
    }
    if (this.isDestroy) {
      globalTracingLogger.error(
        'Video capture loadedmetadata JsMediaSDK instance destroyed'
      );
      return;
    }

    if (that.isVideoCaptureLoadedmetadata) {
      globalTracingLogger.warn(
        'Video capture isVideoCaptureLoadedmetadata is true'
      );
      if (source && source != that.videoCaptureValue.videoCtrl) {
        globalTracingLogger.error('Video capture reLoaded metadata');
        return;
      }
    }

    that.isVideoCaptureLoadedmetadata = true;
    if (that.isSupportVideoFrameOrBitmapCapture) {
      w = that.videoCaptureWidth;
      h = that.videoCaptureHeight;
    } else {
      w = that.videoCaptureValue.videoCtrl.videoWidth;
      h = that.videoCaptureValue.videoCtrl.videoHeight;

      that.videoCaptureWidth = w;
      that.videoCaptureHeight = h;

      // Set width height for each video stream output source
      // otherwise video will be reduced by the browser's default behavior due to size issues
      that.captureVideoOutputCanvasDomList?.forEach(function (item) {
        item.width = w;
        item.height = h;
      });
    }
    jsMediaEngine.Notify_Video_Encode_Thread({
      command: 'startVideoEncode',
      width: that.videoCaptureWidth,
      height: that.videoCaptureHeight,
      fps: that.videoCaptureValue.fps,
      ssid: that.videoCaptureValue.ssid,
      mtu_size: that.mtu_size,
      isSupportImageCapture: that.isSupportImageCapture,
      isSupportVideoTrackReader: that.isSupportVideoTrackReader,
      isSupportMediaStreamTrackProcessor:
        that.isSupportMediaStreamTrackProcessor,
      isSupport2DCanvasDrawFrame: that.isSupport2DCanvasDrawFrame,
      disableOriginalRatio: !!that.videoCaptureValue.disableOriginalRatio,
    });

    jsMediaEngine.Notify_Video_Decode_Thread({
      command: 'startVideoEncode',
      ssid: that.videoCaptureValue.ssid,
      disableOriginalRatio: !!that.videoCaptureValue.disableOriginalRatio,
    });
    ///update_sharing_uplink_bandwidth
    if (!that.isSupportVideoShare) {
      jsMediaEngine.Notify_Sharing_Encode_Thread({
        command: 'startVideoEncode',
      });
    }

    // when start second time, width height won't work by `startVideoEncode`
    // so we need update width/height.
    jsMediaEngine.Notify_Video_Encode_Thread({
      command: 'updateVideoPara',
      // width: w,
      // height: h,
      width: that.videoCaptureWidth,
      height: that.videoCaptureHeight,
      fps: that.GetVideoCaptureFps(),
    });
    //record last real reat width && height
    that.lastRealRect.left = 0;
    that.lastRealRect.top = 0;
    that.lastRealRect.width = that.videoCaptureWidth;
    that.lastRealRect.height = that.videoCaptureHeight;

    jsMediaEngineVariables.monitorVideoUserID = that.videoCaptureValue.ssid;
    // Tell the callback function to tell videoWidth and videoHeight
    jsMediaEngineVariables.Notify_APPUI(
      jsEvent.CURRENT_CAPTURE_VIDEO_WIDTH_HEIGHT,
      {
        width: w,
        height: h,
      }
    );
  },

  Start_Video_Capture: function () {
    if (!mediaStreamController.shouldCaptureVideo()) {
      var that = this;
      return new Promise((resolve, reject) => {
        if (that.isSupportVideoFrameOrBitmapCapture) {
          //imagecapture still need video element
          that._videoloadedmetadata(null);
          try {
            if (that.videoCaptureValue && that.videoCaptureValue.videoCtrl) {
              that.videoCaptureValue.videoCtrl.srcObject =
                mediaStreamController.videoStream;
            }
            if (this.selfPreviewVideotag) {
              this.selfPreviewVideotag.srcObject =
                mediaStreamController.videoStream;
            }
          } catch (ex) {
            globalTracingLogger.error(
              'Error trying to set srcObject of video tag',
              ex
            );
          }
          if (
            util.isSelfPreviewRenderWithVideo() &&
            util.isSupportVideoFrameOrBitmapCapture()
          ) {
            mediaStreamController.playVideoStream(
              that.videoCaptureValue.videoCtrl
            );
          }
          resolve(true);

          const {
            deviceId,
            pan = null,
            tilt = null,
            zoom = null,
          } = mediaStreamController.getVideoCapabilities();
          Zoom_Monitor.add_monitor('STARTVIDEOSUCCESS');
          jsMediaEngineVariables.Notify_APPUI(
            that,
            jsEvent.START_VIDEO_CAPTURE_SUCCESS,
            {
              currentDeviceID: deviceId,
              PTZRange: {
                pan,
                tilt,
                zoom,
              },
            }
          );
          return;
        } else {
          if (that.isVideoCaptureLoadedmetadata) {
            globalTracingLogger.error(
              'Video capture isVideoCaptureLoadedmetadata is true'
            );
            that.isVideoCaptureLoadedmetadata = false;
          }

          that.videoloadedmetadatahandle = that._videoloadedmetadata.bind(that);
          that.videoCaptureValue.videoCtrl?.addEventListener(
            'loadedmetadata',
            that.videoloadedmetadatahandle
          );
        }

        mediaStreamController
          .checkVideoStreamActive()
          .then(resolve)
          .catch(reject);

        if (that.videoCaptureValue.videoCtrl) {
          mediaStreamController.playVideoStream(
            that.videoCaptureValue.videoCtrl
          );
        }
        const {
          deviceId,
          pan = null,
          tilt = null,
          zoom = null,
        } = mediaStreamController.getVideoCapabilities();
        Zoom_Monitor.add_monitor('STARTVIDEOSUCCESS');
        jsMediaEngineVariables.Notify_APPUI(
          that,
          jsEvent.START_VIDEO_CAPTURE_SUCCESS,
          {
            currentDeviceID: deviceId,
            PTZRange: {
              pan,
              tilt,
              zoom,
            },
          }
        );
      });
    } else {
      this.StartVideoMediaCapture();
    }
  },
  Stop_Video_Capture: function () {
    jsMediaEngine.Notify_Video_Encode_Thread({
      command: 'stopVideoEncode',
    });

    jsMediaEngine.Notify_Video_Decode_Thread({
      command: 'stopVideoEncode',
    });

    jsMediaEngine.Notify_Sharing_Encode_Thread({ command: 'stopVideoEncode' });

    this.isStartVideoCapture = false;
    this.isVideoCaptureLoadedmetadata = false;

    if (
      this.videoCaptureValue &&
      this.videoCaptureValue.videoCtrl &&
      !this.isMaskSettingOn &&
      !this.isVBSettingOn
    ) {
      this.videoCaptureValue.videoCtrl.pause();
      if (this.videoCaptureValue.videoCtrl.srcObject) {
        this.videoCaptureValue.videoCtrl.srcObject = null;
      }
      if (this.videoCaptureValue.videoCtrl.src) {
        this.videoCaptureValue.videoCtrl.src = null;
      }
      if (this.videoloadedmetadatahandle) {
        globalTracingLogger.error(
          'stop video capture but loadedmetadata not callback'
        );
        this.videoCaptureValue.videoCtrl.removeEventListener(
          'loadedmetadata',
          this.videoloadedmetadatahandle
        );
        this.videoloadedmetadatahandle = null;
      }
    }

    mediaStreamController._clearCheckVideoStreamActiveTimer();
    if (
      !this.isMaskSettingOn &&
      !this.isVBSettingOn &&
      !this.isStartVideoCapture
    ) {
      mediaStreamController.destoryVideoMediaStream();
      this.videoCaptureValue = null;
    }
    clearInterval(this.videoCaptureInterval);
    this.videoCaptureInterval = 0;
  },
  Remove_Video_Capture: function () {
    var that = this;
    clearInterval(this.videoCaptureInterval);
    this.videoCaptureInterval = 0;
    mediaStreamController.destoryVideoMediaStream();
    this.videoCaptureValue = null;
    if (that.videoCaptureValue.videoCtrl) {
      that.videoCaptureValue.videoCtrl.pause();
    }
  },

  Change_Video_Capture_Resolution: function (
    width,
    height,
    forceupdate = false
  ) {
    // ZOOM-571394 disable 720P for ipad temporally
    if (util.isIpadOS()) {
      return;
    }
    let flag = false;
    if (
      util.isIphoneOrIpadBrowser() ||
      util.isIpadOS() ||
      (util.isAndroidBrowser() && !util.isMTRAndroidWithSAB())
    ) {
      flag = true;
      if (!forceupdate) {
        this.captureSize = { width: width, height: height };
      }

      if (!this.isLandScape && !forceupdate) {
        return;
      }
    }
    // MacOS Firefox doesn't support specific capture resolution
    if (
      this.videoCaptureHiddenCanvas &&
      util.isMac() &&
      util.browser.isFirefox
    ) {
      return;
    }
    //only desktop browser can support 1080p
    if (
      width >= 1920 &&
      this.platformType != consts.WCL_PLATFORM_TYPE.DESKTOP
    ) {
      return;
    }
    let calFps = 0;

    if (width >= 1920) {
      //full hd fps
      calFps = 30;
    } else {
      calFps =
        this.videoCaptureValue && this.videoCaptureValue.fps
          ? this.videoCaptureValue.fps
          : 24;
    }
    mediaStreamController
      .changeVideoResolution(width, height, calFps)
      .then(() => {
        if (flag) {
          return;
        }
        this.ReadyCapureWidth = width;
        this.ReadyCapureHeight = height;
        this.videoCaptureWidth = width;
        this.videoCaptureHeight = height;
        jsMediaEngineVariables.Notify_APPUI(
          jsEvent.CURRENT_CAPTURE_VIDEO_WIDTH_HEIGHT,
          {
            width: width,
            height: height,
          }
        );
        jsMediaEngine.Notify_Video_Encode_Thread({
          command: 'updateVideoPara',
          width: width,
          height: height,
          fps:
            this.videoCaptureValue && this.videoCaptureValue.fps
              ? this.videoCaptureValue.fps
              : 24,
        });
      })
      .catch((error) => {
        globalTracingLogger.warn('change video resoltuion info:', error);
      });
    if (
      mediaStreamController.videoStreamTrack &&
      !this.isSupportVideoTrackReader &&
      !this.isSupportMediaStreamTrackProcessor
    ) {
      this.Process_Video();
    }
  },
  Start_Whiteboard_Sharing: async function () {
    let w, h;
    const whiteboardCanvas = this.desktopSharingValue.canvas;
    this.whiteboardCanvas = whiteboardCanvas;
    const that = this;

    // jsMediaEngineVariables.Notify_APPUI(
    //   jsEvent.SHARING_DESKTOP_STREAM_HAVE_NO_AUDIO,
    //   null
    // );

    // jsMediaEngineVariables.Notify_APPUI(
    //   jsEvent.DESKTOP_SHARING_CAPTURE_SUCCESS,
    //   null
    // );

    {
      this.isdesktopCaptureLoadedmetadata = true;
      w = whiteboardCanvas.width;
      h = whiteboardCanvas.height;
      this.desktopSharingCaptureWidth = w;
      this.desktopSharingCaptureHeight = h;
      this.desktopCaptureContext = whiteboardCanvas.getContext('2d');
    }

    jsMediaEngineVariables.monitorSharingUserID = this.desktopSharingValue.ssid;
    let messagedata = {
      command: 'startSharingEncode',
      width: w,
      height: h,
      fps: 8,
      ssid: this.desktopSharingValue.ssid,
      isSupportVideoTrackReader: false,
      isSupportMediaStreamTrackProcessor: false,
      isWhiteboardSharing: true,
    };
    jsMediaEngine.Notify_Video_Encode_Thread(messagedata);
    if (!this.isSupportVideoShare) {
      jsMediaEngine.Notify_Sharing_Encode_Thread(messagedata);
    }
    // Tell the callback function to tell videoWidth and videoHeight
    // jsMediaEngineVariables.Notify_APPUI(
    //   jsEvent.CURRENT_DESKTOP_SHARING_WIDTH_HEIGHT,
    //   {
    //     width: w,
    //     height: h,
    //   }
    // );
  },
  Change_Sharing_Capture_Resolution: function (mode) {
    var that = this;
    if (that.shareTrack) {
      console.log('change sharing capture resolution to', mode);
      let para;
      if (mode == 1) {
        para = {
          video: true,
          frameRate: 5,
        };
      } else {
        // debugger;
        para = { width: 1280, height: 720, frameRate: 24 };
      }
      that.shareTrack.applyConstraints(para);
      // var track = that.desktopSharingMediaStram.getVideoTracks()[0];
      // if (that.isSupportMediaStreamTrackProcessor) {
      //   that.sharingMediaStreamTrackProcessor = new MediaStreamTrackProcessor(track);
      //   that.sharingFrameStream =
      //     that.sharingMediaStreamTrackProcessor.readable;
      //   if (that.isSupportVideoShare) {
      //     /// notify video encode updare encode;
      //     jsMediaEngine.Notify_Sharing_Video_Encode_Thread_Transferable_Data(
      //       'sharingframeStream',
      //       that.sharingFrameStream
      //     );
      //   } else {
      //     jsMediaEngine.Notify_Sharing_Encode_Thread_Transferable_Data(
      //       'frameStream',
      //       that.sharingFrameStream
      //     );
      //   }

      //   Zoom_Monitor.add_monitor('SCTP');
      // }
    }
  },
  Start_Desktop_Sharing: function () {
    if (this.desktopSharingMediaStram) {
      var that = this;
      return new Promise((resolve, reject) => {
        let loadedmetadata = async function (e) {
          let w, h;
          if (
            that.isSupportSharingTrackReader ||
            that.isSupportMediaStreamTrackProcessor
          ) {
            var track = that.desktopSharingMediaStram.getVideoTracks()[0];
            if (that.isSupportMediaStreamTrackProcessor) {
              that.sharingMediaStreamTrackProcessor =
                new MediaStreamTrackProcessor(track);
              that.sharingFrameStream =
                that.sharingMediaStreamTrackProcessor.readable;
              if (that.isSupportVideoShare) {
                /// notify video encode updare encode;
                jsMediaEngine.Notify_Sharing_Video_Encode_Thread_Transferable_Data(
                  'sharingframeStream',
                  that.sharingFrameStream
                );
              } else {
                jsMediaEngine.Notify_Sharing_Encode_Thread_Transferable_Data(
                  'frameStream',
                  that.sharingFrameStream
                );
              }

              Zoom_Monitor.add_monitor('SCTP');
            } else if (that.isSupportSharingTrackReader) {
              that.sharingTrackReader = new VideoTrackReader(track);
              Zoom_Monitor.add_monitor('SCTPR');
            }
            if (that.desktopSharingValue.video) {
              w = that.desktopSharingValue.video.videoWidth;
              h = that.desktopSharingValue.video.videoHeight;
              that.desktopSharingCaptureWidth = w;
              that.desktopSharingCaptureHeight = h;
              that.desktopSharingValue.video.removeEventListener(
                'loadedmetadata',
                loadedmetadata
              );
            }
          } else if (that.isSharingSupportImageCapture) {
            var track = that.desktopSharingMediaStram.getVideoTracks()[0];
            that.sharingImageCapture = new ImageCapture(track);
            Zoom_Monitor.add_monitor('SCIC');
            let imageBitmap = await that.sharingImageCapture.grabFrame();
            that.desktopSharingCaptureWidth = imageBitmap.width;
            that.desktopSharingCaptureHeight = imageBitmap.height;
            w = imageBitmap.width;
            h = imageBitmap.height;
            try {
              that.replaceCanvasMap.sharingPreviewCanvasId =
                that.desktopSharingValue.rendercanvasID;
              that.sharingOffscreenCanvas =
                that.desktopSharingValue.canvas.transferControlToOffscreen();
              var ssrc = jsMediaEngineVariables.SPECIAL_ID;
              if (jsMediaEngineVariables.localSharingEncMGR) {
                var handle =
                  jsMediaEngineVariables.localSharingEncMGR.map.get(ssrc);
                if (handle) {
                  var data = {
                    command: 'newSharingPara',
                    rendercanvasID:
                      that.replaceCanvasMap.sharingPreviewCanvasId,
                    data: that.sharingOffscreenCanvas,
                    width: w,
                    height: h,
                    flipSend: that.flipSend,
                    is32bitbrowser: that.is32bitbrowser,
                  };
                  handle.postMessage(data, [data.data]);
                }
              }
            } catch (e) {
              var ssrc = jsMediaEngineVariables.SPECIAL_ID;
              if (jsMediaEngineVariables.localSharingEncMGR) {
                var handle =
                  jsMediaEngineVariables.localSharingEncMGR.map.get(ssrc);
                if (handle) {
                  var data = {
                    command: 'newSharingPara',
                    flipSend: that.flipSend,
                    is32bitbrowser: that.is32bitbrowser,
                  };
                  handle.postMessage(data);
                }
              }
            }
          } else {
            that.isdesktopCaptureLoadedmetadata = true;
            w = that.desktopSharingValue.video.videoWidth;
            h = that.desktopSharingValue.video.videoHeight;
            that.desktopSharingValue.canvas.width = w;
            that.desktopSharingValue.canvas.height = h;
            that.desktopSharingCaptureWidth = w;
            that.desktopSharingCaptureHeight = h;
            that.desktopCaptureContext =
              that.desktopSharingValue.canvas.getContext('2d');
            that.desktopSharingValue.video.removeEventListener(
              'loadedmetadata',
              loadedmetadata
            );
          }
          jsMediaEngineVariables.monitorSharingUserID =
            that.desktopSharingValue.ssid;

          if (that.isSupportVideoShare) {
            jsMediaEngine.Notify_Sharing_Video_Encode_Thread({
              command: 'startSharingEncode',
              width: w,
              height: h,
              fps: 8,
              ssid: that.desktopSharingValue.ssid,
              isSupportVideoTrackReader: that.isSupportSharingTrackReader,
              isSupportMediaStreamTrackProcessor:
                that.isSupportMediaStreamTrackProcessor,
            });
          } else {
            jsMediaEngine.Notify_Sharing_Encode_Thread({
              command: 'startSharingEncode',
              width: w,
              height: h,
              fps: 8,
              ssid: that.desktopSharingValue.ssid,
              isSupportVideoTrackReader: that.isSupportSharingTrackReader,
              isSupportMediaStreamTrackProcessor:
                that.isSupportMediaStreamTrackProcessor,
            });
          }
          // Tell the callback function to tell videoWidth and videoHeight
          jsMediaEngineVariables.Notify_APPUI(
            jsEvent.CURRENT_DESKTOP_SHARING_WIDTH_HEIGHT,
            {
              width: w,
              height: h,
            }
          );
          jsMediaEngine.Notify_Video_Encode_Thread({
            command: 'startsharingencode',
          });
        };

        // Play video preview of the screenshare onto the video element
        if (
          that.isSupportSharingTrackReader ||
          that.isSupportMediaStreamTrackProcessor
        ) {
          that.desktopSharingValue.video.addEventListener(
            'loadedmetadata',
            loadedmetadata
          );
        } else if (that.isSharingSupportImageCapture) {
          loadedmetadata();
          that.desktopSharingValue.video.srcObject =
            that.desktopSharingMediaStram;
          that.desktopSharingValue.video.muted = true;
          resolve(true);
          return;
        } else {
          that.desktopSharingValue.video.addEventListener(
            'loadedmetadata',
            loadedmetadata
          );
          that.desktopSharingValue.video.setAttribute('playsinline', '');
          that.desktopSharingValue.video.muted = true;
        }

        try {
          that.desktopSharingValue.video.srcObject =
            that.desktopSharingMediaStram;
          that.desktopSharingValue.video.muted = true;
        } catch (error) {
          that.desktopSharingValue.video.src = URL.createObjectURL(
            that.desktopSharingMediaStram
          );
        }

        that.desktopSharingValue.video.play().catch((error) => {
          globalTracingLogger.error(
            'Screenshare video preview play failed',
            error
          );
        });
        if (
          jsMediaEngineVariables.isGoogleMeetMode &&
          that.desktopSharingMediaStram.getAudioTracks().length > 0
        ) {
          that.screenShareAudioPreviewElement = new Audio();
          that.screenShareAudioPreviewElement.srcObject =
            that.desktopSharingMediaStram;
          that.screenShareAudioPreviewElement.setSinkId(that.audioCtx.sinkId);
        }
        resolve(true);
      });
    } else {
      this.StartDesktopMediaCapture();
    }
  },
  SharingFrameOutputCallback: function (sharingframe) {
    let that = this;
    let w, h;
    if (sharingframe.format != null && sharingframe.planes != null) {
      w = sharingframe.planes[0].stride; //y_data_width
      h = sharingframe.planes[0].rows; //y_data_height
      if (
        w != that.desktopSharingCaptureWidth ||
        h != that.desktopSharingCaptureHeight
      ) {
        jsMediaEngineVariables.Notify_APPUI(
          jsEvent.CURRENT_DESKTOP_SHARING_WIDTH_HEIGHT,
          {
            width: w,
            height: h,
          }
        );
        that.desktopSharingCaptureWidth = w;
        that.desktopSharingCaptureHeight = h;
      }
      if (that.sMonitorCaptureFrameCount == 1000) {
        Zoom_Monitor.add_monitor2('SCFOK');
        that.sMonitorCaptureFrameCount = 0;
      }
      that.sMonitorCaptureFrameCount++;
      if (that.canISendNextSharingFrame) {
        that.canISendNextSharingFrame = false;
        var ssrc = jsMediaEngineVariables.SPECIAL_ID;
        if (jsMediaEngineVariables.localSharingEncMGR) {
          var handle = jsMediaEngineVariables.localSharingEncMGR.map.get(ssrc);
          if (handle) {
            var data = {
              command: 'encodeSharingFrame',
              isSupportVideoTrackReader: that.isSupportSharingTrackReader,
              data: sharingframe,
            };
            handle.postMessage(data);
          }
          if (util.browserType.version >= 90) {
            if ('close' in sharingframe) {
              sharingframe.close();
            } else {
              sharingframe.destroy();
            }
          }
        }
      } else {
        if ('close' in sharingframe) {
          sharingframe.close();
        } else {
          sharingframe.destroy();
        }
      }
    } else {
      if (that.canISendNextSharingFrame) {
        that.canISendNextSharingFrame = false;
        let imagebitmap;
        sharingframe
          .createImageBitmap()
          .then((ibm) => {
            imagebitmap = ibm;
            w = imagebitmap.width;
            h = imagebitmap.height;
            if ('close' in sharingframe) {
              sharingframe.close();
            } else {
              sharingframe.destroy();
            }
            if (
              w != that.desktopSharingCaptureWidth ||
              h != that.desktopSharingCaptureHeight
            ) {
              jsMediaEngineVariables.Notify_APPUI(
                jsEvent.CURRENT_DESKTOP_SHARING_WIDTH_HEIGHT,
                {
                  width: w,
                  height: h,
                }
              );
              that.desktopSharingCaptureWidth = w;
              that.desktopSharingCaptureHeight = h;
            }
            var ssrc = jsMediaEngineVariables.SPECIAL_ID;
            if (jsMediaEngineVariables.localSharingEncMGR) {
              var handle =
                jsMediaEngineVariables.localSharingEncMGR.map.get(ssrc);
              if (handle) {
                handle.postMessage(
                  {
                    command: 'encodeSharingFrame',
                    isImageFromSharingFrame: true,
                    data: imagebitmap,
                  },
                  [imagebitmap]
                );
              }
            }
          })
          .catch((ex) => {
            globalTracingLogger.error(
              'Converting sharing frame to image bitmap failed',
              ex
            );
          });
      } else {
        if ('close' in sharingframe) {
          sharingframe.close();
        } else {
          sharingframe.destroy();
        }
      }
    }
  },
  Process_Sharing: async function () {
    if (!this.desktopSharingValue || !this.desktopSharingSend) {
      return;
    }
    let w, h, video;
    let that = this;

    if (!this.isStartWhiteboardSharing && this.isSupportSharingTrackReader) {
      try {
        await this.sharingTrackReader.start(
          this.SharingFrameOutputCallback.bind(this)
        );
      } catch (e) {}
      return;
    } else if (
      !this.isStartWhiteboardSharing &&
      this.isSharingSupportImageCapture
    ) {
      try {
        let imageBitmap = await this.sharingImageCapture.grabFrame();
        w = imageBitmap.width;
        h = imageBitmap.height;

        if (this.sMonitorCaptureFrameCount == 1000) {
          Zoom_Monitor.add_monitor2('SCFOK');
          this.sMonitorCaptureFrameCount = 0;
        }
        this.sMonitorCaptureFrameCount++;

        var ssrc = jsMediaEngineVariables.SPECIAL_ID;
        if (jsMediaEngineVariables.localSharingEncMGR) {
          var handle = jsMediaEngineVariables.localSharingEncMGR.map.get(ssrc);
          if (handle) {
            var data = {
              command: 'encodeSharingFrame',
              data: imageBitmap,
              isImage: true,
            };
            handle.postMessage(data, [data.data]);
          }
        }
      } catch (e) {
        globalTracingLogger.error(
          'An error occurred when trying to encode a sharing frame using the ImageCapture API',
          e
        );
        Zoom_Monitor.add_monitor3('SICF');
        var ssrc = jsMediaEngineVariables.SPECIAL_ID;
        if (jsMediaEngineVariables.localSharingEncMGR) {
          var handle = jsMediaEngineVariables.localSharingEncMGR.map.get(ssrc);
          if (handle) {
            var data = { command: 'encodeSharingFrame' };
            handle.postMessage(data);
          }
        }
      }
    } else {
      if (this.isStartWhiteboardSharing) {
        video = this.whiteboardCanvas;
        w = video.width;
        h = video.height;
      } else {
        video = this.desktopSharingValue.video;
        w = video.videoWidth;
        h = video.videoHeight;
      }
    }
    if (
      w != this.desktopSharingCaptureWidth ||
      h != this.desktopSharingCaptureHeight
    ) {
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.CURRENT_DESKTOP_SHARING_WIDTH_HEIGHT,
        {
          width: w,
          height: h,
        }
      );
      this.desktopSharingCaptureWidth = w;
      this.desktopSharingCaptureHeight = h;
    }
    try {
      if (this.isdesktopCaptureLoadedmetadata && video) {
        if (!this.isStartDesktopSharing) {
          this.desktopSharingValue.canvas.width = w;
          this.desktopSharingValue.canvas.height = h;
        }

        var context = this.desktopCaptureContext;
        // 1.Video stream output to canvas
        if (!this.is32bitbrowser || this.flipSend) {
          if (!this.isStartWhiteboardSharing) {
            context.drawImage(video, 0, 0, w, h, 0, 0, w, h);
          }
          var img = context.getImageData(0, 0, w, h);

          if (this.sMonitorCaptureFrameCount == 1000) {
            Zoom_Monitor.add_monitor2('SCFOK');
            this.sMonitorCaptureFrameCount = 0;
          }
          this.sMonitorCaptureFrameCount++;

          jsMediaEngine.Sharing_Encode_Frame(
            jsMediaEngineVariables.SPECIAL_ID,
            img.data,
            img.data.length,
            0,
            w,
            h
          );
          this.flipSend = false;
        } else {
          this.flipSend = true;
          jsMediaEngine.Sharing_Encode_Frame(
            jsMediaEngineVariables.SPECIAL_ID,
            null,
            0,
            0,
            0,
            0
          );
        }
      }
    } catch (e) {
      globalTracingLogger.error(
        'An error occurred when trying to encode a sharing frame',
        e
      );
      Zoom_Monitor.add_monitor3('GIDF');
      setTimeout(function () {
        jsMediaEngine.Sharing_Encode_Frame(
          jsMediaEngineVariables.SPECIAL_ID,
          null,
          0,
          0,
          0,
          0
        );
      }, 1000);
    }
  },

  VideoFrameOutputCallback: function (videoframe) {
    let that = this;
    let vw, vh, w, h;

    if (videoframe.format != null && videoframe.planes != null) {
      w = that.videoCaptureWidth;
      h = that.videoCaptureHeight;
      vw = videoframe.cropWidth;
      vh = videoframe.cropHeight; //y_data_height

      if (vw !== w || vh !== h) {
        log('video width/height changed old => begin', w, h, vw, vh);
        jsMediaEngineVariables.Notify_APPUI(
          jsEvent.CURRENT_CAPTURE_VIDEO_WIDTH_HEIGHT,
          {
            width: vw,
            height: vh,
          }
        );
        jsMediaEngine.Notify_Video_Encode_Thread({
          command: 'updateVideoPara',
          width: vw,
          height: vh,
          fps: that.GetVideoCaptureFps(),
        });
        that.videoCaptureWidth = vw;
        that.videoCaptureHeight = vh;
      }

      if (that.vMonitorCaptureFrameCount == 3 * 1000) {
        Zoom_Monitor.add_monitor2('VCFOK');
        that.vMonitorCaptureFrameCount = 0;
      }
      that.vMonitorCaptureFrameCount++;

      var ssrc = jsMediaEngineVariables.SPECIAL_ID;
      if (jsMediaEngineVariables.localVideoEncMGR) {
        var handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
        if (handle && videoframe) {
          handle.postMessage({
            command: 'yuvVideoFrame',
            data: videoframe,
          });
        }
        if (util.browserType.version >= 90) {
          if ('close' in videoframe) {
            videoframe.close();
          } else {
            videoframe.destroy();
          }
        }
      }
    } else {
      let imagebitmap;
      videoframe
        .createImageBitmap()
        .then((ibm) => {
          imagebitmap = ibm;
          if ('close' in videoframe) {
            videoframe.close();
          } else {
            videoframe.destroy();
          }
          w = that.videoCaptureWidth;
          h = that.videoCaptureHeight;
          vw = imagebitmap.width;
          vh = imagebitmap.height;

          if (vw !== w || vh !== h) {
            log('video width/height changed old => begin', w, h, vw, vh);
            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.CURRENT_CAPTURE_VIDEO_WIDTH_HEIGHT,
              {
                width: vw,
                height: vh,
              }
            );
            jsMediaEngine.Notify_Video_Encode_Thread({
              command: 'updateVideoPara',
              width: vw,
              height: vh,
              fps: that.GetVideoCaptureFps(),
            });

            that.videoCaptureWidth = vw;
            that.videoCaptureHeight = vh;
          }
          var ssrc = jsMediaEngineVariables.SPECIAL_ID;
          if (jsMediaEngineVariables.localVideoEncMGR) {
            var handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
            if (handle) {
              handle.postMessage(
                {
                  command: 'ImageBitmapFromVideoFrame',
                  data: imagebitmap,
                },
                [imagebitmap]
              );
            }
          }
        })
        .catch((ex) => {
          globalTracingLogger.error(
            'Converting video frame to image bitmap failed',
            ex
          );
        });
    }
  },

  Update_Mask_Texture: function (maskCoordinate, width, height) {
    width = Math.floor(width);
    height = Math.floor(height);
    if (!this.bgCanvas || !this.bgCanvasctx) {
      this.bgCanvas = document.createElement('canvas');
      this.bgCanvasctx = this.bgCanvas.getContext('2d');
    }
    this.bgCanvas.width = width;
    this.bgCanvas.height = height;
    this.bgCanvasctx.clearRect(0, 0, width, height);
    if (this.MaskImage && this.BgImage && this.BgImage !== 'blur') {
      if (
        maskCoordinate.dWidth > 0 &&
        maskCoordinate.dHeight > 0 &&
        this.VideoMaskSettingCanvas.width > 0 &&
        this.VideoMaskSettingCanvas.height > 0 &&
        width > 0 &&
        height > 0
      ) {
        let x = maskCoordinate.dx / (this.VideoMaskSettingCanvas.width / width);
        let y =
          maskCoordinate.dy / (this.VideoMaskSettingCanvas.height / height);
        let w =
          maskCoordinate.dWidth / (this.VideoMaskSettingCanvas.width / width);
        let h =
          maskCoordinate.dHeight /
          (this.VideoMaskSettingCanvas.height / height);
        this.bgCanvasctx.drawImage(this.MaskImage, x, y, w, h);
      }
      this.bgCanvasctx.globalCompositeOperation = 'source-out';
      this.Crop_Mask_Bg_16V9();
      let sx, sy, sw, sh;
      sx = this.bgImageCroppingParams.sx;
      sy = this.bgImageCroppingParams.sy;
      sw = this.bgImageCroppingParams.sw;
      sh = this.bgImageCroppingParams.sh;
      this.bgCanvasctx.drawImage(
        this.BgImage,
        sx,
        sy,
        sw,
        sh,
        0,
        0,
        width,
        height
      );
    }
  },

  Crop_Mask_Bg_16V9: function () {
    let sx,
      sy,
      sw,
      sh = 0;
    let destRate = 16 / 9;
    let srcWidth = this.BgImage.naturalWidth;
    let srcHeight = this.BgImage.naturalHeight;
    let dw = destRate * srcHeight;
    if (dw >= srcWidth) {
      sw = srcWidth;
      sh = srcWidth / destRate;
      sx = 0;
      sy = (srcHeight - sh) / 2;
    } else {
      sw = srcHeight * destRate;
      sh = srcHeight;
      sx = (srcWidth - sw) / 2;
      sy = 0;
    }
    this.bgImageCroppingParams.sx = sx;
    this.bgImageCroppingParams.sy = sy;
    this.bgImageCroppingParams.sw = sw;
    this.bgImageCroppingParams.sh = sh;
  },
  Draw_Mask_Frame: function () {
    if (!this.VideoMaskSettingCanvas) return;
    if (!this.VideoMaskSettingCanvasctx) {
      this.VideoMaskSettingCanvasctx =
        this.VideoMaskSettingCanvas.getContext('2d');
    }

    const croppingParams = this.VideoMaskCanvasFillMode
      ? get16V9CroppingParams(
          this.videoCaptureHiddenCanvas.width,
          this.videoCaptureHiddenCanvas.height,
          undefined,
          undefined,
          this.VideoMaskSettingCanvas.width / this.VideoMaskSettingCanvas.height
        )
      : this.videoCaptureValue && this.videoCaptureValue.disableOriginalRatio
      ? get16V9CroppingParams(
          this.videoCaptureHiddenCanvas.width,
          this.videoCaptureHiddenCanvas.height
        )
      : null;
    if (this.isMirrorMyVideo) {
      if (!this.isCanvasScaled) {
        this.VideoMaskSettingCanvasctx.scale(-1, 1);
        this.isCanvasScaled = true;
      }
      this.VideoMaskSettingCanvasctx.clearRect(
        0 - this.VideoMaskSettingCanvas.width,
        0,
        this.VideoMaskSettingCanvas.width,
        this.VideoMaskSettingCanvas.height
      );
      this.VideoMaskSettingCanvasctx.drawImage(
        this.videoCaptureHiddenCanvas,
        ...(croppingParams
          ? [
              croppingParams.left,
              croppingParams.top,
              croppingParams.width,
              croppingParams.height,
            ]
          : []),
        0 - this.VideoMaskSettingCanvas.width,
        0,
        this.VideoMaskSettingCanvas.width,
        this.VideoMaskSettingCanvas.height
      );
    } else {
      if (this.isCanvasScaled) {
        this.VideoMaskSettingCanvasctx.setTransform(1, 0, 0, 1, 0, 0);
        this.isCanvasScaled = false;
      }
      this.VideoMaskSettingCanvasctx.clearRect(
        0,
        0,
        this.VideoMaskSettingCanvas.width,
        this.VideoMaskSettingCanvas.height
      );
      this.VideoMaskSettingCanvasctx.drawImage(
        this.videoCaptureHiddenCanvas,
        ...(croppingParams
          ? [
              croppingParams.left,
              croppingParams.top,
              croppingParams.width,
              croppingParams.height,
            ]
          : []),
        0,
        0,
        this.VideoMaskSettingCanvas.width,
        this.VideoMaskSettingCanvas.height
      );
    }
  },
  GetVideoCaptureFps: function () {
    return this.videoCaptureValue && this.videoCaptureValue.fps
      ? this.videoCaptureValue.fps
      : consts.VIDEO_CAPTURE_FPS;
  },
  Process_Video: async function () {
    if (this.isSupportMediaStreamTrackProcessor) {
      return;
    }
    if (this.isSupportVideoTrackReader) {
      try {
        await mediaStreamController.videoTrackReader.start(
          this.VideoFrameOutputCallback.bind(this)
        );
      } catch (e) {}
      return;
    }
    if (this.isSupportImageCapture) {
      if (mediaStreamController.isImageCaptureLocked()) {
        return;
      } else {
        mediaStreamController.lockImageCapture();
      }
    }
    if (
      (!this.videoCaptureValue || !this.isStartVideoCapture) &&
      !this.isMaskSettingOn &&
      !this.isVBSettingOn
    ) {
      mediaStreamController.unLockImageCapture();
      // console.error("equal return 1117")
      return;
    }
    let that = this;
    let vw, vh, w, h;
    let video;
    w = this.videoCaptureWidth;
    h = this.videoCaptureHeight;
    if (this.isSupportImageCapture) {
      try {
        let imageBitmap =
          await mediaStreamController.videoImageCapture.grabFrame();
        vw = imageBitmap.width;
        vh = imageBitmap.height;
        if (vw && vh && (vw !== w || vh !== h)) {
          log('video width/height changed old => begin', w, h, vw, vh);
          jsMediaEngineVariables.Notify_APPUI(
            jsEvent.CURRENT_CAPTURE_VIDEO_WIDTH_HEIGHT,
            {
              width: vw,
              height: vh,
            }
          );
          jsMediaEngine.Notify_Video_Encode_Thread({
            command: 'updateVideoPara',
            width: vw,
            height: vh,
            fps: this.GetVideoCaptureFps(),
          });

          if (this.vMonitorCaptureFrameCount == 3 * 1000) {
            Zoom_Monitor.add_monitor2('VCFOK');
            this.vMonitorCaptureFrameCount = 0;
          }
          this.vMonitorCaptureFrameCount++;

          this.videoCaptureWidth = vw;
          this.videoCaptureHeight = vh;
        }
        jsMediaEngine.transportImageBitMap(imageBitmap);
      } catch (e) {
        globalTracingLogger.error(
          'An error occurred when trying to encode a video frame using the ImageCapture API',
          e
        );
        Zoom_Monitor.add_monitor3('VICF');
        jsMediaEngine.transportImageBitMap(null);
      }
      // console.error("equal return 1160")
      mediaStreamController.unLockImageCapture();
      // that.Process_Video();
      // setTimeout(function () {
      //     that.Process_Video();
      // }, 0)
      return;
    }
    const isFileInputVideoSource =
      mediaStreamController.isUsingFileInputVideoSource();
    if (this.isStartVideoCapture) {
      video = this.videoCaptureValue.videoCtrl;
    } else {
      if (this.isMaskSettingOn || this.isVBSettingOn) {
        video = this.MaskSettingVideo;
      }
    }
    if (
      (this.isVideoCaptureLoadedmetadata ||
        this.isMaskSettingOn ||
        this.isVBSettingOn) &&
      video &&
      video.readyState >= 2
    ) {
      if (video.paused || video.ended) {
        video.play().catch((error) => {
          globalTracingLogger.error(
            'Video capture video tag play failed',
            error
          );
        });
      }

      if (
        this.isSelfViewWithVideo &&
        this.platformType == consts.WCL_PLATFORM_TYPE.IPHONE
      ) {
        if (!VideoStreamCanCapture(video.srcObject)) {
          return;
        }
        /// ios safari not support mask and vb
        let lorientation = orientation;
        if (mediaStreamController.facingMode == consts.FACE_MODE_ENVIRONMENT) {
          if (orientation == 2) {
            lorientation = 0;
          } else if (orientation == 0) {
            lorientation = 2;
          }
        }
        let frame = new VideoFrame(video, {
          timestamp: 0,
        });
        var ssrc = jsMediaEngineVariables.SPECIAL_ID;
        var handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
        if (handle) {
          handle.postMessage(
            {
              command: 'yuvVideoFrame',
              data: frame,
              rotation: lorientation,
            },
            [frame]
          );
        } else {
          frame.close();
        }
        return;
      }

      vw = video.videoWidth ? video.videoWidth : 640;
      vh = video.videoHeight ? video.videoHeight : 360;

      if (vw && vh && (vw !== w || vh !== h)) {
        log('video width/height changed old => begin', w, h, vw, vh);
        jsMediaEngineVariables.Notify_APPUI(
          jsEvent.CURRENT_CAPTURE_VIDEO_WIDTH_HEIGHT,
          {
            width: vw,
            height: vh,
          }
        );

        this.videoCaptureWidth = vw;
        this.videoCaptureHeight = vh;

        jsMediaEngine.Notify_Video_Encode_Thread({
          command: 'updateVideoPara',
          width: vw,
          height: vh,
          fps: that.GetVideoCaptureFps(),
        });
      }

      /* to keep Mac/IOS firefox and safari video original ratio */
      let dx, dy, dWidth, dHeight;
      /*
       * make sure we are not in Mask Mode.
       * */
      if (
        ((!that.isMaskSettingOn ||
          (that.videoCaptureValue &&
            that.videoCaptureValue.disableOriginalRatio)) &&
          (!that.MaskImage || !that.BgImage)) ||
        !that.isCurrentInMaskStatus
      ) {
        dWidth = vw;
        dHeight = vh;
        dx = 0;
        dy = 0;
        that.videoCaptureHiddenCanvas.width = vw;
        that.videoCaptureHiddenCanvas.height = vh;
      } else {
        let dw = (vw / vh) * that.ReadyCapureHeight;
        let dh = (vh / vw) * that.ReadyCapureWidth;
        if (dw > that.ReadyCapureWidth) {
          dx = 0;
          dy = (that.ReadyCapureHeight - dh) / 2;
        } else {
          dx = (that.ReadyCapureWidth - dw) / 2;
          dy = 0;
        }
        dWidth = that.ReadyCapureWidth - 2 * dx;
        dHeight = that.ReadyCapureHeight - 2 * dy;
        that.videoCaptureHiddenCanvas.width = that.ReadyCapureWidth;
        that.videoCaptureHiddenCanvas.height = that.ReadyCapureHeight;
      }
      if (isFileInputVideoSource) {
        util.videoToMediaStreamManager.drawCanvas(
          that.videoCaptureHiddenCanvas,
          that.videoCaptureHiddenCanvasCtx,
          that.videoCaptureHiddenCanvas.width,
          that.videoCaptureHiddenCanvas.height
        );
      } else {
        that.videoCaptureHiddenCanvasCtx.drawImage(
          video,
          0,
          0,
          vw,
          vh,
          dx,
          dy,
          dWidth,
          dHeight
        );
      }

      if (that.isCurrentInMaskStatus) {
        if (that.bgCanvas) {
          //The image object can be an img element, a canvas element, or a video element in Safari. Use of the video element is not supported in Safari on iOS, however.
          that.videoCaptureHiddenCanvasCtx.drawImage(
            that.bgCanvas,
            0,
            0,
            that.videoCaptureHiddenCanvas.width,
            that.videoCaptureHiddenCanvas.height
          );
        } else {
          that.Update_Mask_Texture(
            this.maskCoordinate,
            that.videoCaptureHiddenCanvas.width,
            that.videoCaptureHiddenCanvas.height
          );
        }
        if (that.VideoMaskSettingCanvas) {
          that.Draw_Mask_Frame();
        }
      }
      if (that.isStartVideoCapture || that.isVBSettingOn) {
        var img = that.videoCaptureHiddenCanvasCtx.getImageData(
          0,
          0,
          that.videoCaptureHiddenCanvas.width,
          that.videoCaptureHiddenCanvas.height
        );
        let data = img.data;

        if (
          that.lastRealRect.width != that.videoCaptureHiddenCanvas.width ||
          that.lastRealRect.height != that.videoCaptureHiddenCanvas.height
        ) {
          that.lastRealRect.left = 0;
          that.lastRealRect.top = 0;
          that.lastRealRect.width = that.videoCaptureHiddenCanvas.width;
          that.lastRealRect.height = that.videoCaptureHiddenCanvas.height;
          jsMediaEngine.Notify_Video_Encode_Thread({
            command: 'updateVideoPara',
            width: that.lastRealRect.width,
            height: that.lastRealRect.height,
            fps: that.GetVideoCaptureFps(),
          });
        }
        if (
          that.videoCaptureValue &&
          that.VideoRenderObj &&
          !that.isMultiView
        ) {
          if (img.data instanceof Uint8ClampedArray) {
            data = new Uint8Array(img.data.buffer);
          }
          if (
            that.videoCaptureValue &&
            that.videoCaptureValue.disableOriginalRatio !== undefined
          ) {
            that.VideoRenderObj.Set_Cropping_Mode(
              !!that.videoCaptureValue.disableOriginalRatio
            );
          }
          that.VideoRenderObj.Draw_Send_Video_Img(
            data,
            that.videoCaptureHiddenCanvas.width,
            that.videoCaptureHiddenCanvas.height,
            that.videoCaptureValue.ssid,
            consts.VIDEO_RGBA,
            that.lastRealRect
          );
        }

        if (that.vMonitorCaptureFrameCount == 3 * 1000) {
          Zoom_Monitor.add_monitor2('VCFOK');
          that.vMonitorCaptureFrameCount = 0;
        }
        that.vMonitorCaptureFrameCount++;
        jsMediaEngine.Video_Encode_Frame(
          jsMediaEngineVariables.SPECIAL_ID,
          data,
          that.videoCaptureHiddenCanvas.width,
          that.lastRealRect.left,
          that.lastRealRect.top
        );
      }

      that.videoCaptureHiddenCanvasCtx.clearRect(
        0,
        0,
        that.videoCaptureHiddenCanvas.width,
        that.videoCaptureHiddenCanvas.height
      );
    }
  },
  Remove_Sharing_Audio_Capture: function () {
    if (this.audioBridge) {
      this.audioBridge.publishStream(null, true);
      return;
    }
    jsMediaEngine.Notify_Audio_Encode_Thread({
      command: 'changeAudioShare',
      isStart: false,
    });
    if (
      jsMediaEngineVariables.ComputerAudioStatus ==
      AudioStatus.ComputerAudio_Null
    ) {
      jsMediaEngine.addAudioMonitorLog.clear();
    }
    if (
      this.sharingWebrtcAudioNode &&
      jsMediaEngineVariables.SharingAudioNode
    ) {
      try {
        this.sharingWebrtcAudioNode.disconnect(
          jsMediaEngineVariables.SharingAudioNode
        );
      } catch (e) {
        this.JsMediaSDK_Log(e);
      }
      this.sharingWebrtcAudioNode = null;
    }
  },
  Remove_Audio_Capture: function (fakeLeave) {
    jsMediaEngine.Notify_Audio_Thread_Status(
      jsMediaEngineVariables.SPECIAL_ID,
      jsEvent.AUDIO_REMOVE,
      fakeLeave
    ); //2 --- remove audio capture
    if (
      jsMediaEngineVariables.DesktopAudioStatus == AudioStatus.DesktopAudio_Null
    ) {
      jsMediaEngine.addAudioMonitorLog.clear();
    }
    this.firstSetDelay = true;
    if (this.webrtcAudioNode && jsMediaEngineVariables.AudioNode) {
      try {
        this.webrtcAudioNode.disconnect(jsMediaEngineVariables.AudioNode);
      } catch (e) {
        this.JsMediaSDK_Log(e);
      }
      this.webrtcAudioNode = null;
    }
  },
  Start_Video_Play: function () {
    if (this.videoRenderIntervalHandle) {
      return;
    }
    this.videoRenderIntervalHandle = this.JsMediaSDK_VideoRenderInterval(
      this.videorenderinterval
    );
  },
  Stop_Video_Play: function () {
    this.UpdateVideoPlayStatus(false);
    if (this.VideoRenderObj) {
      this.VideoRenderObj.ClearQueue();
      this.VideoRenderObj.Set_WaterMark_Info({
        waterMarkCanvas: this.waterMarkCanvas,
        isCreateVideoWaterMark: this.isCreateVideoWaterMark,
        videoWaterMarkName: this.videoWaterMarkName,
      });
    }
    if (this.videoRenderIntervalHandle) {
      let stopDraw = this.VideoRenderObj.Stop_Draw.bind(this.VideoRenderObj);
      stopDraw();
      this.videoRenderIntervalHandle = null;
    }
  },
  Remove_Video_Play: function () {
    this.UpdateVideoPlayStatus(false);
    if (this.videoRenderIntervalHandle) {
      clearInterval(this.videoRenderIntervalHandle);
      this.videoRenderIntervalHandle = null;
    }
    jsMediaEngine.Notify_Video_Decode_Thread({
      command: 'removeVideoPlay',
    });
  },

  UpdateVideoPlayStatus(bPlaystatus) {
    jsMediaEngine.UpdateVideoPlayStatus(bPlaystatus);
  },
  EndMedia: function () {
    try {
      if (jsMediaEngineVariables.rwgAgent) {
        jsMediaEngineVariables.rwgAgent.off(
          'message',
          this.rwgAgentMessageListenerWrapper
        );
      }
    } catch (e) {
      log.error('rwgAgent.off error', e);
    }
    try {
      this.rtcPeerConnectionList.forEach((rtc) => {
        if (rtc) {
          rtc.forceClose();
          rtc = null;
        }
      });
      this.rtcPeerConnectionList = [];
    } catch (e) {
      log.error('clear rtcPeerConnectionList err', e);
    }

    try {
      mediaStreamController.destoryAudioMediaStream();
      // maybe leakmemory!!!!!should test carefully.
      //Audio
      this.Remove_Audio_Play();
      this.Remove_Audio_Capture();
      this.Remove_Sharing_Audio_Capture();
      if (this.checkWorkletInterval) {
        clearInterval(this.checkWorkletInterval);
        this.checkWorkletInterval = null;
      }

      //Video
      this.Remove_Video_Play();
      if (this.audioCtx) {
        this.audioCtx.onstatechange = null;
        this.audioCtx.close();
        this.audioCtx = null;
      }
      if (this.sharingAudioCtx) {
        this.sharingAudioCtx.close();
        this.sharingAudioCtx = null;
      }
      if (this.audioDomNode) {
        this.audioDomNode = null;
      }

      mediaStreamController.destoryVideoMediaStream();
      this.EndSharingMediaStream();
      this.CloseBoringPeerConnection();
    } catch (ex) {
      log('endMedia', ex);
    }

    try {
      jsMediaEngine.disableSocketReconnect();
    } catch (ex) {
      log('endMedia', ex);
    }

    try {
      if (this.remoteControl) {
        this.remoteControl.destroy();
      }
    } catch (ex) {
      log('endMedia', ex);
    }
  },
  EndDesktopAudioMediaStream: function () {
    if (!this.isStartDesktopSharing) {
      this.desktopSharingValue = null;
    }
    if (this.desktopSharingMediaStram) {
      let desktopSharingAudioTracks =
        this.desktopSharingMediaStram.getAudioTracks();
      if (desktopSharingAudioTracks.length) {
        desktopSharingAudioTracks.forEach(function (track) {
          track.stop();
        });
      }
    }
  },
  EndSharingMediaStream: function () {
    if (this.desktopSharingMediaStram) {
      this.desktopSharingMediaStram.getTracks().forEach(function (track) {
        track.stop();
      });
      this.desktopSharingMediaStram = null;
      if (window.WebQrscanner) {
        WebQrscanner.qrScanner.stop();
      }
    }
  },
  StopSharingCapture: function () {
    this.EndSharingMediaStream();
    this.desktopSharingSend = false;
    if (this.isStartDesktopSharing) {
      jsMediaEngine.Notify_Video_Encode_Thread({
        command: 'stopsharingencode',
      });
    }
    this.isStartDesktopSharing = false;
    this.desktopSharingMediaStram = null;
    if (window.WebQrscanner) {
      WebQrscanner.qrScanner.stop();
    }
    this.desktopSharingValue = null;
  },
  CloseBoringPeerConnection: function () {
    try {
      if (this.rtcConnectionB) {
        this.rtcConnectionB.close();
        this.rtcConnectionB = null;
      }
      if (this.rtcConnectionA) {
        this.rtcConnectionA.close();
        this.rtcConnectionA = null;
      }
    } catch (e) {
      log(e);
    }
  },

  UnMuteAudio: function () {
    jsMediaEngine.Notify_Audio_Thread_Status(
      jsMediaEngineVariables.SPECIAL_ID,
      jsEvent.AUDIO_START
    );
    if (this.enableHID && this.hidAvalible) {
      HIDControl.sendReport('mute', false);
    }
  },
  MuteAudio: function () {
    this.Stop_Audio_Capture();
    if (this.enableHID && this.hidAvalible) {
      HIDControl.sendReport('mute', true);
    }
  },

  selectComputerAudioSpeaker(deviceId, startSelectSpreakerTime) {
    let isSupportChromeWideAEC = util.isSupportChromeWideAEC();
    let playDevice = deviceId == 'default' ? '' : deviceId;
    let audioPlayer = null;
    if (isSupportChromeWideAEC && !this.audioDomNode) {
      if (this.audioCtx) {
        audioPlayer = this.audioCtx;
      }
    } else if (this.audioDomNode) {
      audioPlayer = this.audioDomNode;
    }
    if (!audioPlayer || !audioPlayer.setSinkId) {
      Zoom_Monitor.add_monitor('NoAudioPlayer');
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.AUDIO_SPEAKER_SET_ERROR,
        'no audio player or audio player dont support setSinkId. audioPlay: ' +
          !!audioPlayer
      );
      return;
    }
    audioPlayer
      .setSinkId(playDevice)
      .then(() => {
        deviceManager.updateSelectedSpeakerDevices(
          audioPlayer.sinkId || 'default',
          Date.now() - startSelectSpreakerTime,
          true
        );
        jsMediaEngineVariables.Notify_APPUI(
          jsEvent.AUDIO_SPEAKER_SET_SUCCESS,
          audioPlayer.sinkId || 'default'
        );
      })
      .catch((e) => {
        globalTracingLogger.error(
          'An error occurred when trying to set the audio output device',
          e
        );
        Zoom_Monitor.add_monitor('AODF');
        deviceManager.updateSelectedSpeakerDevices(
          playDevice,
          Date.now() - startSelectSpreakerTime,
          false
        );
        jsMediaEngineVariables.Notify_APPUI(
          jsEvent.AUDIO_SPEAKER_SET_ERROR,
          null
        );
      });
  },

  handleDesktopCapture: function (stream, audioOnly = false) {
    this.desktopSharingMediaStram = stream;
    var that = this;

    let audioTrack = this.desktopSharingMediaStram.getAudioTracks()[0];
    if (audioTrack) {
      // share Tab audio support open mic when chrome-wide AEC is supported
      // support 2nd audio device can support open mic
      // Google Meet HDMI ingest audio will have a 'HDMI' label
      if (
        !audioOnly &&
        audioTrack.label !== 'Tab audio' &&
        !audioTrack.label.includes('HDMI')
      ) {
        jsMediaEngineVariables.shareSystemAudio = true;
      }
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.SHARING_DESKTOP_STREAM_HAVE_AUDIO,
        audioOnly
      );
      audioTrack.onended = function () {
        console.log('share audio track ended');
      };
    } else {
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.SHARING_DESKTOP_STREAM_HAVE_NO_AUDIO,
        null
      );
    }
    this.shareTrack = this.desktopSharingMediaStram.getVideoTracks()[0];
    if (this.shareTrack) {
      this.shareTrack.onended = function () {
        Zoom_Monitor.add_monitor('SSBB');
        jsMediaEngineVariables.Notify_APPUI(
          jsEvent.USER_STOP_DESKTOP_SHARING,
          null
        );
        that.StopSharingCapture();
      };
      this.Start_Desktop_Sharing();
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.DESKTOP_SHARING_CAPTURE_SUCCESS,
        null
      );
    } else {
      globalTracingLogger.log('shareTrack is null when share screen');
    }
  },
  handleCaptureError: function (error) {
    this.handleGetDisplayMediaError(
      'An error occurred when trying to capture sharing',
      error
    );

    this.StopSharingCapture();
    Zoom_Monitor.add_monitor('SCCF');

    if (error.message === 'Permission denied by system') {
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.DESKTOP_SHARING_SYSTEM_ERROR,
        null
      );
    } else {
      jsMediaEngineVariables.Notify_APPUI(jsEvent.DESKTOP_SHARING_ERROR, null);
    }
  },
  handleAudioCapture: function () {
    let { deviceId, elapsed_time, deviceLabel } =
      mediaStreamController.getAudioCapabilities();
    if (deviceId && elapsed_time && deviceLabel) {
      deviceManager.updateSelectedMicDevices(
        deviceId,
        deviceLabel,
        elapsed_time,
        true
      );
    }
    if (this.audioBridge && !this.audioInputLevel) {
      this.audioBridge
        .publishStream(mediaStreamController.audioStream)
        .then((result) => {
          if (
            jsMediaEngineVariables.ComputerAudioStatus ===
            AudioStatus.ComputerAudio_Connecting
          ) {
            jsMediaEngineVariables.ComputerAudioStatus =
              AudioStatus.ComputerAudio_Connected;
            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.JOIN_COMPUTER_AUDIO_COMPLETE,
              deviceId
            );
          }
        });
      globalTracingLogger.log('user grante audio capture');
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.USER_GRANT_CAPTURE_AUDIO,
        deviceId
      );
      return;
    }
    if (!this.audioCtx && !this.audioInputLevel) {
      globalTracingLogger.log('no audio context when join computer audio');
      jsMediaEngineVariables.ComputerAudioStatus =
        AudioStatus.ComputerAudio_Null;
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.JOIN_COMPUTER_AUDIO_COMPLETE,
        null
      );
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.JOIN_COMPUTER_AUDIO_FAILURE,
        null
      );
      mediaStreamController.destoryAudioMediaStream();
      return;
    }
    globalTracingLogger.log('user grante audio capture');

    jsMediaEngineVariables.Notify_APPUI(
      jsEvent.USER_GRANT_CAPTURE_AUDIO,
      deviceId
    );
    this.Start_Audio_Capture();
    // this.Start_Video_Capture();
    return;
  },
  handleVideoCapture: function (stream, captureParam) {
    let that = this;
    return new Promise(async (resolve, reject) => {
      if (!stream) {
        return reject(new Error('no stream'));
      }
      that.videoCaptureWidth
        ? (that.videoCaptureWidth = that.videoCaptureWidth)
        : (that.videoCaptureWidth = 640);
      that.videoCaptureHeight
        ? (that.videoCaptureHeight = that.videoCaptureHeight)
        : (that.videoCaptureHeight = 360);
      if (captureParam.encode && that.isStartVideoCapture) {
        try {
          let isSuccess = await that.Start_Video_Capture();
          resolve(isSuccess);
        } catch (ex) {
          globalTracingLogger.error(
            'media stream is ok, but start video capture fail',
            ex
          );
          reject(new Error('media stream is ok, but start video capture fail'));
        }
        if (that.isMaskSettingOn && that.MaskSettingVideo) {
          mediaStreamController.playVideoStream(that.MaskSettingVideo);
        }
      } else if (
        captureParam.preview &&
        (that.isMaskSettingOn || that.isVBSettingOn)
      ) {
        try {
          let settingisSuccess = await that.startMaskOrVBSettingVideoCapture(
            stream
          );
          resolve(settingisSuccess);
        } catch (ex) {
          log('startMaskOrVBSettingVideoCapture', ex);
          reject(
            new Error(
              'media stream is ok, but start setting video capture fail'
            )
          );
        }
      } else {
        mediaStreamController.destoryVideoMediaStream();
      }
    });
  },

  startMaskOrVBSettingVideoCapture: function (stream) {
    return new Promise((resolve, reject) => {
      try {
        if (this.isVBSettingOn) {
          if (this.MaskSettingVideo) {
            const promise = mediaStreamController.playVideoStream(
              this.MaskSettingVideo
            );

            if (promise) {
              promise
                .then(function () {
                  //wait util video play success to notify UI, or else firefox and and safari setting mask will show black bottom for a short time
                  jsMediaEngineVariables.Notify_APPUI(
                    jsEvent.START_VIDEO_STREAM_IN_VB_SETTING_SUCCESS,
                    null
                  );
                })
                .catch(function (error) {
                  reject(
                    new Error('media stream is ok, but video.play() fail')
                  );
                  globalTracingLogger.error(
                    'An error occurred when trying to play video in Background Setting tab',
                    error
                  );
                  Zoom_Monitor.add_monitor('VBVPF');
                });
            }
          } else {
            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.START_VIDEO_STREAM_IN_VB_SETTING_SUCCESS,
              null
            );
          }
        } else if (this.isMaskSettingOn) {
          //for firefox and safari to render setting video
          if (this.MaskSettingVideo) {
            const promise = mediaStreamController.playVideoStream(
              this.MaskSettingVideo
            );
            if (promise) {
              promise
                .then(function () {
                  //wait util video play success to notify UI, or else firefox and and safari setting mask will show black bottom for a short time
                  jsMediaEngineVariables.Notify_APPUI(
                    jsEvent.START_VIDEO_STREAM_IN_MASK_SETTING_SUCCESS,
                    null
                  );
                })
                .catch(function (error) {
                  reject(
                    new Error('media stream is ok, but video.play() fail')
                  );
                  globalTracingLogger.error(
                    'An error occurred when trying to play video in Background Setting tab',
                    error
                  );
                  Zoom_Monitor.add_monitor('MVPF');
                });
            }
          } else {
            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.START_VIDEO_STREAM_IN_MASK_SETTING_SUCCESS,
              null
            );
          }
        }
        ///decode render send videoFrame by MessageChannel
        this.addChannelForVideo();

        jsMediaEngine.Notify_Video_Encode_Thread({
          command: 'startvideointerval',
          width: this.videoCaptureValue.width,
          height: this.videoCaptureValue.height,
          ssid: this.videoCaptureValue.ssid,
          mtu_size: this.mtu_size,
          fps: this.videoCaptureValue.fps,
          isSupportImageCapture: this.isSupportImageCapture,
          isSupportVideoTrackReader: this.isSupportVideoTrackReader,
          isSupportMediaStreamTrackProcessor:
            this.isSupportMediaStreamTrackProcessor,
          isSupport2DCanvasDrawFrame: this.isSupport2DCanvasDrawFrame,
          isVBSettingOn: this.isVBSettingOn,
          isMaskSettingOn: this.isMaskSettingOn,
        });
        resolve(true);
      } catch (ex) {
        globalTracingLogger.error(
          'Starting mask or virtual background failed',
          ex
        );
        reject(ex);
      }
    });
  },
  handleGetDisplayMediaError: function (message, error) {
    const errorLogLevelMap = {
      AbortError: 'error',
      InvalidStateError: 'error',
      NotAllowedError: 'warn',
      NotFoundError: 'warn',
      NotReadableError: 'warn',
      OverconstrainedError: 'warn',
      TypeError: 'error',
    };
    const { name } = error;
    const logLevel = errorLogLevelMap[name] || 'error';
    globalTracingLogger[logLevel](message, error);
  },
  handleGetUserMediaError: function (message, error) {
    const errorLogLevelMap = {
      NotAllowedError: 'warn',
      NotFoundError: 'warn',
      NotReadableError: 'warn',
      OverconstrainedError: 'warn',
      SecurityError: 'warn',
      SourceUnavailableError: 'warn',
      PermissionDeniedError: 'warn',
      AbortError: 'error',
      TypeError: 'error',
    };
    const { name } = error;
    const logLevel = errorLogLevelMap[name] || 'error';
    globalTracingLogger[logLevel](message, error);
  },
  handleAudioError: function (error) {
    //No Device
    //open
    this.handleGetUserMediaError(
      'Error occurred when trying to capture audio',
      error
    );
    Zoom_Monitor.add_monitor('HADF: ' + error?.name);

    jsMediaEngineVariables.Notify_APPUI(
      jsEvent.USER_FORBIDDED_CAPTURE_AUDIO,
      error
    );

    if (
      jsMediaEngineVariables.ComputerAudioStatus ===
      AudioStatus.ComputerAudio_Connecting
    ) {
      jsMediaEngineVariables.ComputerAudioStatus =
        AudioStatus.ComputerAudio_Null;
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.JOIN_COMPUTER_AUDIO_COMPLETE,
        null
      );
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.JOIN_COMPUTER_AUDIO_FAILURE,
        null
      );
    }
  },
  /**
   * @param error Error | MediaStream
   */
  handleVideoError: function (error) {
    // camera occupied scene
    // error.name == NotReadableError  Chrome
    // error.name == NotReadableError  firefox
    // error.name == SourceUnavailableError  edge

    // not allowed
    // error.name == NotAllowedError   Chrome
    // error.name == NotAllowedError   firefox
    // error.name == PermissionDeniedError   edge
    jsMediaEngineVariables.monitorVideoCapture = false;
    this.isMaskSettingStarted = false;
    this.isCurrentInMaskStatus = false;
    log('handleVideoError', error);
    let name = error?.name || 'other error';
    let info = `Error ${error?.message || ''}`;
    Zoom_Monitor.add_monitor(`STARTVIDEOERR:${name}-${info}`);
    let occupy = ['NotReadableError', 'SourceUnavailableError'];

    if (
      error instanceof CameraOccupiedError ||
      (error.name && occupy.indexOf(error.name) !== -1)
    ) {
      Zoom_Monitor.add_monitor('COCPEF');
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.USER_CAMERA_IS_TAKEN_BY_OTHER_PROGRAMS,
        error
      );
    } else {
      Zoom_Monitor.add_monitor('HVDF');
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.USER_FORBIDDED_CAPTURE_VIDEO,
        error
      );
    }

    this.handleGetUserMediaError(
      'Error occurred when trying to capture video',
      error
    );

    this.Stop_Video_Capture();
  },
  chromeAecWorkAround: async function (sourcestream) {
    Zoom_Monitor.add_monitor('ASCAEC');
    if (this.rtcConnectionA || this.rtcConnectionB) {
      return false;
    }
    let rtcConnection = null;
    let rtcLoopbackConnection = null;
    let loopbackStream = new MediaStream(); // this is the stream you will read from for actual audio output

    const offerOptions = {
      offerVideo: true,
      offerAudio: true,
      offerToReceiveAudio: false,
      offerToReceiveVideo: false,
    };

    let offer, answer;

    rtcConnection = new RTCPeerConnection();
    rtcLoopbackConnection = new RTCPeerConnection();

    rtcConnection.onicecandidate = (e) =>
      e.candidate &&
      rtcLoopbackConnection.addIceCandidate(new RTCIceCandidate(e.candidate));
    rtcLoopbackConnection.onicecandidate = (e) =>
      e.candidate &&
      rtcConnection.addIceCandidate(new RTCIceCandidate(e.candidate));

    rtcLoopbackConnection.ontrack = (e) => {
      e.streams[0].getTracks().forEach((track) => {
        loopbackStream.addTrack(track);
      });
    };

    // setup the loopback
    rtcConnection.addStream(sourcestream); // this stream would be the processed stream coming out of Web Audio API destination node
    offer = await rtcConnection.createOffer(offerOptions);
    // ZOOM-271998 [WebClient] Audio bad quality with hearing music.
    // here shoud't use L16/16000, will lead to bad quality for host sharing music.
    // reproduce site : https://dreamy-bell-b81701.netlify.app/
    let error = false;
    let offersdp = null;
    let oldsdp = null;
    let chromeversion = util.getBrowserVersion();
    try {
      oldsdp = offer.sdp;
      if (chromeversion >= 104) {
        offersdp = offer.sdp.replace('SAVPF 111', 'SAVPF 100 111');
        offersdp = offersdp.replace(
          'a=rtpmap:111 opus/48000/2',
          'a=rtpmap:100 L16/48000\na=rtpmap:111 opus/48000/2'
        );
      } else {
        offersdp = offer.sdp.replace('SAVPF 111', 'SAVPF 10 111');
        offersdp = offersdp.replace(
          'a=rtpmap:111 opus/48000/2',
          'a=rtpmap:10 L16/48000\na=rtpmap:111 opus/48000/2'
        );
      }
      //   offersdp = offersdp.replace('fmtp:111 minptime=10;useinbandfec=1', 'fmtp:100 minptime=10;useinbandfec=1;stereo=1;sprop-stereo=1');

      offer.sdp = offersdp;
      await rtcConnection.setLocalDescription(offer);
      await rtcLoopbackConnection.setRemoteDescription(offer);
    } catch (e) {
      error = true;
      offer.sdp = oldsdp;
      await rtcConnection.setLocalDescription(offer);
      await rtcLoopbackConnection.setRemoteDescription(offer);
    }

    answer = await rtcLoopbackConnection.createAnswer();
    if (!error) {
      if (chromeversion >= 104) {
        answer.sdp = answer.sdp.replace('SAVPF 111', 'SAVPF 100 111');
        answer.sdp = answer.sdp.replace(
          'a=rtpmap:111 opus/48000/2',
          'a=rtpmap:100 L16/48000\na=rtpmap:111 opus/48000/2'
        );
      } else {
        answer.sdp = answer.sdp.replace('SAVPF 111', 'SAVPF 10 111');
        answer.sdp = answer.sdp.replace(
          'a=rtpmap:111 opus/48000/2',
          'a=rtpmap:10 L16/48000\na=rtpmap:111 opus/48000/2'
        );
      }
      //   answer.sdp = answer.sdp.replace('fmtp:111 minptime=10;useinbandfec=1', 'fmtp:100 minptime=10;useinbandfec=1;stereo=1;sprop-stereo=1');
    }
    await rtcLoopbackConnection.setLocalDescription(answer);
    await rtcConnection.setRemoteDescription(answer);
    //end rtcloopbackhack.js
    this.rtcConnectionA = rtcConnection;
    this.rtcConnectionB = rtcLoopbackConnection;
    return loopbackStream;
  },
  /**
   * switch canvas for video streaming output
   * @param dom  canvas Dom | Array<canvas Dom>
   */
  switchCanvasForVideoCapture: function (dom) {
    var doms = [].concat(dom);
    var domFirst = doms[0];
    this.captureVideoOutputCanvasDomList = doms;
    this.videoCaptureValue.canvasCtrl = domFirst;
    if (!this.isSupportVideoFrameOrBitmapCapture) {
      var w = this.videoCaptureValue.videoCtrl.videoWidth;
      var h = this.videoCaptureValue.videoCtrl.videoHeight;
      doms.forEach(function (item) {
        item.width = w;
        item.height = h;
      });
      this.videoCaptureValue.canvasCtrl.width = w;
      this.videoCaptureValue.canvasCtrl.height = h;
      this.videoCaptureContext = domFirst.getContext('2d');
    }
  },
  videoDecodeFrameBackSABRingBufferConsumeCallback(data) {
    let o2b = new VideoDecodeOBJXBuffer();
    o2b.setBuffer(data);
    let message = o2b.buffer2Obj();
    jsMediaEngine.Put_Video_Frame_Buffer(
      message.video_ssrc,
      message.data,
      message.video_timestamp,
      message.video_width,
      message.video_height,
      message.rendering_x,
      message.rendering_y,
      message.rendering_w,
      message.rendering_h,
      message.rotation,
      message.yuv_limited
    );

    var JsMediaSDK_VideoRenderHandle =
      this.VideoRenderObj.JsMediaSDK_VideoRender.bind(this.VideoRenderObj);
    JsMediaSDK_VideoRenderHandle();
  },
  consumeVideoEncodeRingbuffer(data) {
    if (this.videoDataChannel) {
      this.videoDataChannel.sendVideoData(data);
    }
  },
  audioEncodeRingBufferConsumerCallback(data) {
    if (this.audioDataChannel) {
      this.audioDataChannel.sendAudioData(data);
    }
  },
  CheckCurrentActiveSSRC(ssrc) {
    if (this.currentactive != ssrc) {
      this.currentactive = ssrc;
      jsMediaEngineVariables.CurrentSSRC = this.currentactive;
    } else {
      return;
    }
    if (jsMediaEngineVariables.decoderinworklet) {
      if (jsMediaEngineVariables.AudioNode) {
        jsMediaEngineVariables.AudioNode.postCMD(
          'currentSSRC',
          jsMediaEngineVariables.CurrentSSRC
        );
      }
    } else {
      jsMediaEngine.Notify_Audio_Thread_CurrentSSRC(
        jsMediaEngineVariables.CurrentSSRC
      );
    }
  },

  createVideoElement() {
    if (!this.MaskSettingVideo) {
      if (this.videoCaptureValue && this.videoCaptureValue.videoCtrl) {
        this.MaskSettingVideo = this.videoCaptureValue.videoCtrl;
      } else {
        this.MaskSettingVideo = CreateAutoPlayVideoElement();
        if (util.browser.isSafari) {
          this.MaskSettingVideo.setAttribute(
            'style',
            'position:fixed;top:-10000px;left:-10000px;'
          );
          document.body.appendChild(this.MaskSettingVideo);
        }
      }
    }
  },
  changeVideoRenderActiveSsrc(activessrc) {
    if (this.RenderInMain == consts.RENDER_IN_WORKER) {
      var ssrc = jsMediaEngineVariables.SPECIAL_ID;
      if (jsMediaEngineVariables.localVideoDecMGR) {
        var handle = jsMediaEngineVariables.localVideoDecMGR.map.get(ssrc);
        if (handle) {
          var data = {
            command: 'CHANGE_CURRENT_ACTIVE_SSRC',
            ssrc: activessrc,
          };
          handle.postMessage(data);
        }
      }
    } else if (this.RenderInMain == consts.RENDER_IN_MAIN) {
      this.VideoRenderObj.Change_Current_SSRC(activessrc);
    }
  },
  addChannelForVideo() {
    if (!this.vcFlag) {
      this.vcFlag = true;
      var video_channnel = new MessageChannel();
      jsMediaEngine.Notify_Video_Thread_Msg_Channel(
        video_channnel,
        video_channnel
      );
    }
  },
  checkIsRenderInWorker() {
    return (
      this.isMultiView ||
      this.isSupportVideoFrameOrBitmapCapture ||
      jsMediaEngineVariables.enableVirtualBackgroundWithoutSAB
    );
  },
  setMultiView(decodethreadnumb) {
    if (
      decodethreadnumb > 1 &&
      (IsSupportWebGLOffscreenCanvas() ||
        util.isWebGL2Supported(this.isWebGL2Enabled))
    ) {
      this.isMultiView = true;
    }
  },
  //////////////////////////////////
  //
  //                  Notify_Event
  //          APP>>>>>>>>>>>>>>>>>>>>JsMediaSDK
  //
  //          type:
  //          0   :   Start_Media
  //          1   :   ADD_RENDER_VIDEO
  //          2   :   STOP_RENDER_VIDEO
  //          3   :   ADD_CAPTURE_VIDEO
  //          4   :   DELETE_CAPTURE_VIDEO
  //          5   :   ADD_Render_AUDIO
  //          6   :   STOP_RENDER_AUDIO
  //          7   :   Add_Capture_Audio
  //          8   :   STOP_CAPTURE_AUDIO
  //          9   :   Change_FrameRate
  //          10   :   Change_Resolution
  //          11   :   Change_Speaker
  //          12   :   End_Media
  //          Type value struct{
  //                ssrc
  //                width
  //                height
  //                framerate:
  //                VideoSelectValue: // video device
  //                AudioSelectValue:  // audio device
  //                canvas  //render canvas
  //                display
  //            }
  ////////////////////////////////////
  Notify_MeidaSDK: async function (type, value) {
    log(type, value);
    var i = 0;
    Zoom_Monitor.add_monitor2('B' + type);
    switch (type) {
      // VB settinhg
      case jsEvent.START_VIDEO_VB_SETTING:
        {
          //91

          let that = this;
          var width = 0;
          var height = 0;
          that.isVBSettingOn = true;
          that.isCurrentInMaskStatus = false;
          width = value.width ? value.width : 640;
          height = value.height ? value.height : 360;
          that.videoCaptureWidth
            ? (that.videoCaptureWidth = that.videoCaptureWidth)
            : (that.videoCaptureWidth = width);
          that.videoCaptureHeight
            ? (that.videoCaptureHeight = that.videoCaptureHeight)
            : (that.videoCaptureHeight = height);
          jsMediaEngine.Notify_Video_Encode_Thread({
            command: 'StartVBSetting',
          });
          if (value.bgdom) {
            Zoom_Monitor.add_monitor('VBBG');
            if (value.bgdom == 'blur') {
              jsMediaEngine.Notify_Video_Encode_Thread({
                command: 'vbbgimagebitmap',
                data: value.bgdom,
              });
            } else {
              let BgImage = document.getElementById(value.bgdom);
              if (!BgImage || !BgImage.naturalWidth || !BgImage.naturalHeight) {
                Zoom_Monitor.add_monitor('VBGF');
                jsMediaEngineVariables.Notify_APPUI(
                  jsEvent.VIDEO_VB_SETTING_PARA_ERROR,
                  2
                );
                return;
              }
              that.BgImage = BgImage;
              createImageBitmap(BgImage)
                .then(function (ibm) {
                  jsMediaEngine.transportVBBgImageBitMap(ibm, 'BgImage');
                })
                .catch((e) => {
                  Zoom_Monitor.add_monitor('VBCF');
                  jsMediaEngineVariables.Notify_APPUI(
                    jsEvent.VIDEO_VB_SETTING_PARA_ERROR,
                    2
                  );
                  return;
                });
            }
          }
          if (value.canvas) {
            that.VideoVBSettingCanvas = document.getElementById(value.canvas);
            if (
              !that.VideoVBSettingCanvas ||
              that.VideoVBSettingCanvas.width <= 0 ||
              that.VideoVBSettingCanvas.height <= 0
            ) {
              Zoom_Monitor.add_monitor('VBCAF');
              jsMediaEngineVariables.Notify_APPUI(
                jsEvent.VIDEO_VB_SETTING_PARA_ERROR,
                3
              );
              return;
            }
            try {
              var settingcanvas =
                that.VideoVBSettingCanvas.transferControlToOffscreen();
              jsMediaEngine.transportSettingCanvas(
                settingcanvas,
                'VBOfflineCanvas',
                value.canvas
              );
            } catch (e) {
              jsMediaEngine.transportSettingCanvas(
                null,
                'VBOfflineCanvas',
                value.canvas
              );
            }
          }

          if (!that.isSupportVideoFrameOrBitmapCapture) {
            that.createVideoElement();

            if (!that.videoCaptureHiddenCanvas) {
              that.videoCaptureHiddenCanvas = util.isSupport2dOffscreenCanvas()
                ? new OffscreenCanvas(640, 360)
                : document.createElement('canvas');
              that.videoCaptureHiddenCanvasCtx =
                that.videoCaptureHiddenCanvas.getContext('2d');
            }
          }
          /** when reuse video frame, must call StartVideoMediaCapture after get vb image, otherwise ui will destroy the dom */
          if (mediaStreamController.shouldCaptureVideo()) {
            if (!jsMediaEngine.isVideoEncodeHandleReady()) {
              log.warn(`not isVideoEncodeHandleReady so return`);
              Zoom_Monitor.add_monitor(
                'not isVideoEncodeHandleReady so return'
              );
              return;
            }
            if (that.videoCaptureValue) {
              that.videoCaptureValue = Object.assign(
                that.videoCaptureValue,
                value
              );
            } else {
              that.videoCaptureValue = value;
            }
            if (that.videoCaptureValue.ssid) {
              jsMediaEngineVariables.localSsrc = that.videoCaptureValue.ssid;
            }
            that.StartVideoMediaCapture();
          } else {
            if (that.MaskSettingVideo) {
              mediaStreamController.playVideoStream(that.MaskSettingVideo);
            }
          }
        }
        break;

      case jsEvent.UPDATE_VIDEO_VB_BG_IMAGE:
        {
          //92
          //bg image dom
          if (value.bgdom) {
            Zoom_Monitor.add_monitor('VBBG');
            if (value.bgdom == 'blur') {
              jsMediaEngine.Notify_Video_Encode_Thread({
                command: 'vbbgimagebitmap',
                data: value.bgdom,
              });
            } else {
              let BgImage = document.getElementById(value.bgdom);
              if (!BgImage || !BgImage.width || !BgImage.height) {
                Zoom_Monitor.add_monitor('VBGF');
                jsMediaEngineVariables.Notify_APPUI(
                  jsEvent.VIDEO_VB_SETTING_PARA_ERROR,
                  2
                );
                return;
              }
              this.BgImage = BgImage;
              createImageBitmap(BgImage)
                .then(function (ibm) {
                  jsMediaEngine.transportVBBgImageBitMap(ibm, 'BgImage');
                })
                .catch((e) => {
                  Zoom_Monitor.add_monitor('VBCF');
                  jsMediaEngineVariables.Notify_APPUI(
                    jsEvent.VIDEO_VB_SETTING_PARA_ERROR,
                    2
                  );
                  return;
                });
            }
          } else {
            this.BgImage = null;
            jsMediaEngine.transportVBBgImageBitMap(null, 'BgImage');
          }
        }
        break;
      case jsEvent.STOP_VIDEO_VB_SETTING:
        {
          //93
          this.isVBSettingOn = false;
          if (value.isSwitch) {
            this.isMaskSettingOn = true;
            jsMediaEngine.Notify_Video_Encode_Thread({
              command: 'StartMaskSetting',
              isSwitch: value.isSwitch,
              enabled: this.isSupportVideoFrameOrBitmapCapture,
            });
          }
          jsMediaEngine.Notify_Video_Encode_Thread({
            command: 'finishVBSetting',
          });
          if (!this.isStartVideoCapture && !this.isMaskSettingOn) {
            this.Stop_Video_Capture();
          }
          if (this.MaskSettingVideo) this.MaskSettingVideo.pause();
        }
        break;

      //mask setting
      case jsEvent.VIDEO_MASK_SETTING:
        {
          //62
          if (this.isMaskSettingStarted) {
            return;
          }

          this.isMaskSettingStarted = true;
          let that = this;
          let width = 0;
          let height = 0;
          that.isCurrentInMaskStatus = true;
          that.isMaskSettingOn = true;
          width = value.width ? value.width : 640;
          height = value.height ? value.height : 360;
          that.ReadyCapureWidth = width;
          that.ReadyCapureHeight = height;
          that.videoCaptureWidth
            ? (that.videoCaptureWidth = that.videoCaptureWidth)
            : (that.videoCaptureWidth = width);
          that.videoCaptureHeight
            ? (that.videoCaptureHeight = that.videoCaptureHeight)
            : (that.videoCaptureHeight = height);
          let originWidth = 0;
          let originHeight = 0;

          if (value.canvas) {
            that.VideoMaskSettingCanvas = document.getElementById(value.canvas);
            that.PrevVideoMaskSettingCanvas = that.VideoMaskSettingCanvas;
            that.replaceCanvasMap.videoMaskSettingCanvasId = value.canvas;
            if (
              !that.VideoMaskSettingCanvas ||
              that.VideoMaskSettingCanvas.width <= 0 ||
              that.VideoMaskSettingCanvas.height <= 0
            ) {
              jsMediaEngineVariables.Notify_APPUI(
                jsEvent.MASK_SETTING_PARA_ERROR,
                3
              );
              return;
            }

            originWidth =
              value.originWidth || that.VideoMaskSettingCanvas.width;
            originHeight =
              value.originHeight || that.VideoMaskSettingCanvas.height;
          }

          if (value.maskdom) {
            let MaskImage = document.getElementById(value.maskdom);
            if (!MaskImage || !MaskImage.width || !MaskImage.height) {
              jsMediaEngineVariables.Notify_APPUI(
                jsEvent.MASK_SETTING_PARA_ERROR,
                1
              );
              return;
            }
            let scale = 1.0;
            if (
              originWidth != 0 &&
              originHeight != 0 &&
              (originWidth != that.VideoMaskSettingCanvas.width ||
                originHeight != that.VideoMaskSettingCanvas.height)
            ) {
              scale = Math.min(
                (that.VideoMaskSettingCanvas.width * 1.0) / originWidth,
                (that.VideoMaskSettingCanvas.height * 1.0) / originHeight
              );
            }
            that.MaskImage = MaskImage;
            that.maskCoordinate.dx = value.dx
              ? Math.round(value.dx * scale)
              : that.maskCoordinate.dx;
            that.maskCoordinate.dy = value.dy
              ? Math.round(value.dy * scale)
              : that.maskCoordinate.dy;
            that.maskCoordinate.dWidth = value.dWidth
              ? Math.round(value.dWidth * scale)
              : that.maskCoordinate.dWidth;
            that.maskCoordinate.dHeight = value.dHeight
              ? Math.round(value.dHeight * scale)
              : that.maskCoordinate.dHeight;
            if (that.isSupportVideoFrameOrBitmapCapture) {
              createImageBitmap(MaskImage)
                .then(function (ibm) {
                  jsMediaEngine.transportMaskImageBitMap(
                    ibm,
                    'MaskImage',
                    that.maskCoordinate,
                    that.videoCaptureWidth,
                    that.videoCaptureHeight
                  );
                })
                .catch((e) => {
                  jsMediaEngineVariables.Notify_APPUI(
                    jsEvent.MASK_SETTING_PARA_ERROR,
                    1
                  );
                  return;
                });
            }
          }
          if (value.bgdom) {
            let BgImage = document.getElementById(value.bgdom);
            if (!BgImage || !BgImage.naturalWidth || !BgImage.naturalHeight) {
              Zoom_Monitor.add_monitor('VMGF');
              jsMediaEngineVariables.Notify_APPUI(
                jsEvent.MASK_SETTING_PARA_ERROR,
                2
              );
              return;
            }
            that.BgImage = BgImage;
            if (that.isSupportVideoFrameOrBitmapCapture) {
              createImageBitmap(BgImage)
                .then(function (ibm) {
                  jsMediaEngine.transportBgImageBitMap(ibm, 'BgImage');
                })
                .catch((e) => {
                  Zoom_Monitor.add_monitor('VMCF');
                  jsMediaEngineVariables.Notify_APPUI(
                    jsEvent.MASK_SETTING_PARA_ERROR,
                    2
                  );
                  return;
                });
            }
          }

          if (!that.isSupportVideoFrameOrBitmapCapture) {
            that.createVideoElement();
          }
          this.VideoMaskCanvasFillMode = value.fillMode ? 1 : 0;
          jsMediaEngine.Notify_Video_Encode_Thread({
            command: 'StartMaskSetting',
            fillMode: this.VideoMaskCanvasFillMode,
            enabled: this.isSupportVideoFrameOrBitmapCapture,
          });

          if (!mediaStreamController.shouldCaptureVideo()) {
            //firefox and safari setting video render
            if (that.MaskSettingVideo) {
              mediaStreamController.playVideoStream(that.MaskSettingVideo);
            }
            if (that.isMaskSettingOn) {
              jsMediaEngineVariables.Notify_APPUI(
                jsEvent.START_VIDEO_STREAM_IN_MASK_SETTING_SUCCESS,
                null
              );
            }
          } else {
            if (
              !that.videoCaptureHiddenCanvas &&
              !that.isSupportVideoFrameOrBitmapCapture
            ) {
              that.videoCaptureHiddenCanvas = util.isSupport2dOffscreenCanvas()
                ? new OffscreenCanvas(640, 360)
                : document.createElement('canvas');
              that.videoCaptureHiddenCanvasCtx =
                that.videoCaptureHiddenCanvas.getContext('2d');
            }
            if (!jsMediaEngine.isVideoEncodeHandleReady()) {
              log.warn(`not isVideoEncodeHandleReady so return`);
              Zoom_Monitor.add_monitor(
                'not isVideoEncodeHandleReady so return'
              );
              return;
            }
            if (that.videoCaptureValue) {
              that.videoCaptureValue = Object.assign(
                that.videoCaptureValue,
                value
              );
            } else {
              that.videoCaptureValue = value;
            }
            if (that.videoCaptureValue.ssid) {
              jsMediaEngineVariables.localSsrc = that.videoCaptureValue.ssid;
            }
            that.StartVideoMediaCapture();
          }
          // mtr-android still need to transfer canvas due to setting pannel don't support video render
          if (
            that.isSupportVideoFrameOrBitmapCapture ||
            (util.isSupportVideoFrameOrBitmapCapture() &&
              util.isSelfPreviewRenderWithVideo())
          ) {
            try {
              let settingcanvas =
                that.VideoMaskSettingCanvas.transferControlToOffscreen();
              jsMediaEngine.transportSettingCanvas(
                settingcanvas,
                'MaskOfflineCanvas',
                that.replaceCanvasMap.videoMaskSettingCanvasId
              );
            } catch (e) {
              jsMediaEngine.transportSettingCanvas(
                null,
                'MaskOfflineCanvas',
                that.replaceCanvasMap.videoMaskSettingCanvasId
              );
            }
          } else {
            that.Update_Mask_Texture(
              that.maskCoordinate,
              that.videoCaptureWidth,
              that.videoCaptureHeight
            );
          }
        }
        break;

      case jsEvent.UPDATE_MASK_CANVAS_SIZE: {
        let rendercanvasID = value.canvas;
        if (
          this.isSupportImageCapture ||
          (util.isSupportImageCapture() && util.isSelfPreviewRenderWithVideo())
        ) {
          var ssrc = jsMediaEngineVariables.SPECIAL_ID;
          if (jsMediaEngineVariables.localVideoEncMGR) {
            var handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
            if (handle) {
              var data = {
                command: 'update_mask_canvas_size',
                rendercanvasID: rendercanvasID,
                width: value.width,
                height: value.height,
              };
              handle.postMessage(data);
            }
          }
        } else {
          value.canvas = document.getElementById(rendercanvasID);
          if (value.canvas) {
            value.canvas.width = value.width;
            value.canvas.height = value.height;
          }
        }
        break;
      }
      case jsEvent.UPDATE_BG_IMAGE:
        {
          //63
          if (this.isSupportVideoFrameOrBitmapCapture) {
            if (value.bgdom) {
              let BgImage = document.getElementById(value.bgdom);
              if (!BgImage || !BgImage.width || !BgImage.height) {
                jsMediaEngineVariables.Notify_APPUI(
                  jsEvent.MASK_SETTING_PARA_ERROR,
                  2
                );
                return;
              }
              this.BgImage = BgImage;
              createImageBitmap(BgImage)
                .then(function (ibm) {
                  jsMediaEngine.transportBgImageBitMap(ibm, 'BgImage');
                })
                .catch((e) => {
                  jsMediaEngineVariables.Notify_APPUI(
                    jsEvent.MASK_SETTING_PARA_ERROR,
                    2
                  );
                  return;
                });
            } else {
              this.BgImage = null;
              jsMediaEngine.transportBgImageBitMap(null, 'BgImage');
            }
          } else {
            if (value.bgdom) {
              let BgImage = document.getElementById(value.bgdom);
              if (!BgImage || !BgImage.width || !BgImage.height) {
                jsMediaEngineVariables.Notify_APPUI(
                  jsEvent.MASK_SETTING_PARA_ERROR,
                  2
                );
                return;
              }
              this.BgImage = BgImage;
            } else {
              this.BgImage = null;
            }
            this.Update_Mask_Texture(
              this.maskCoordinate,
              this.videoCaptureWidth,
              this.videoCaptureHeight
            );
          }
        }
        break;
      case jsEvent.UPDATE_MASK_INFO:
        {
          //64
          this.maskCoordinate.dx = value.dx;
          this.maskCoordinate.dy = value.dy;
          this.maskCoordinate.dWidth = value.dWidth;
          this.maskCoordinate.dHeight = value.dHeight;
          if (this.isSupportVideoFrameOrBitmapCapture) {
            if (value.maskdom) {
              let MaskImage = document.getElementById(value.maskdom);
              if (!MaskImage || !MaskImage.width || !MaskImage.height) {
                jsMediaEngineVariables.Notify_APPUI(
                  jsEvent.MASK_SETTING_PARA_ERROR,
                  1
                );
                return;
              }
              this.MaskImage = MaskImage;
              let maskCoordinate = this.maskCoordinate;
              let width = this.videoCaptureWidth;
              let height = this.videoCaptureHeight;
              createImageBitmap(MaskImage)
                .then(function (ibm) {
                  jsMediaEngine.transportMaskImageBitMap(
                    ibm,
                    'MaskImage',
                    maskCoordinate,
                    width,
                    height
                  );
                })
                .catch((e) => {
                  jsMediaEngineVariables.Notify_APPUI(
                    jsEvent.MASK_SETTING_PARA_ERROR,
                    1
                  );
                  return;
                });
            } else {
              this.MaskImage = null;
              let maskCoordinate = this.maskCoordinate;
              let width = this.videoCaptureWidth;
              let height = this.videoCaptureHeight;
              jsMediaEngine.transportMaskImageBitMap(
                null,
                'MaskImage',
                maskCoordinate,
                width,
                height
              );
            }
          } else {
            if (value.maskdom) {
              let MaskImage = document.getElementById(value.maskdom);
              if (!MaskImage || !MaskImage.width || !MaskImage.height) {
                jsMediaEngineVariables.Notify_APPUI(
                  jsEvent.MASK_SETTING_PARA_ERROR,
                  1
                );
                return;
              }
              this.MaskImage = MaskImage;
            } else {
              this.MaskImage = null;
            }
            this.Update_Mask_Texture(
              this.maskCoordinate,
              this.videoCaptureWidth,
              this.videoCaptureHeight
            );
          }
        }
        break;

      case jsEvent.FINISH_MASK_SETTING:
        {
          this.isMaskSettingStarted = false;
          this.isMaskSettingOn = false;
          if (value.isSwitch) {
            this.isVBSettingOn = true;
            jsMediaEngine.Notify_Video_Encode_Thread({
              command: 'StartVBSetting',
              isSwitch: value.isSwitch,
            });
          }
          if (this.MaskSettingVideo) {
            this.MaskSettingVideo.pause();
          }
          if (this.VideoMaskSettingCanvas) {
            if (this.VideoMaskSettingCanvasctx) {
              this.VideoMaskSettingCanvasctx.setTransform(1, 0, 0, 1, 0, 0);
              this.VideoMaskSettingCanvasctx.clearRect(
                0,
                0,
                this.VideoMaskSettingCanvas.width,
                this.VideoMaskSettingCanvas.height
              );
              this.VideoMaskSettingCanvasctx = null;
            }
            this.VideoMaskSettingCanvas = null;
          }
          this.isCanvasScaled = false;
          jsMediaEngine.Notify_Video_Encode_Thread({
            command: 'finishMaskSetting',
          });
          if (!this.isStartVideoCapture && !this.isVBSettingOn) {
            this.Stop_Video_Capture();
          }
        }
        break;

      case jsEvent.MIRROR_MY_VIDEO:
        {
          this.isMirrorMyVideo = value.isMirrorMyVideo;
          jsMediaEngine.Notify_Video_Encode_Thread({
            command: 'mirrorMyVideo',
            isMirrorMyVideo: this.isMirrorMyVideo,
          });
          if (!this.isSupportOffscreenCanvas) {
            if (!this.VideoRenderObj) {
              this.VideoRenderObj = new VideoRender(
                Object.assign(
                  {},
                  {
                    Notify_APPUI: jsMediaEngineVariables.Notify_APPUI.bind(
                      jsMediaEngineVariables
                    ),
                    isSupportOffscreenCanvas: false,
                    jsMediaEngine: jsMediaEngine,
                    globalTracingLogger,
                    monitorLoggerFn: Zoom_Monitor.add_monitor,
                    ...(jsMediaEngineVariables.enableMultiDecodeVideoWithoutSAB
                      ? { videodecodethreadnumb: consts.MAX_RENDER_WITHOUT_SAB }
                      : {}),
                    renderManager: this.renderManager,
                  }
                )
              );
            }
            this.VideoRenderObj.setMirrorMyVideoOption(this.isMirrorMyVideo);
          } else {
            if (this.VideoRenderObj) {
              this.VideoRenderObj.setMirrorMyVideoOption(this.isMirrorMyVideo);
            }
            jsMediaEngine.Notify_Video_Decode_Thread({
              command: 'mirrorMyVideo',
              isMirrorMyVideo: this.isMirrorMyVideo,
              isSupportOffscreenCanvas: this.isSupportOffscreenCanvas,
            });
          }
        }
        break;
      case jsEvent.NEW_ACTIVE_SPEAKER_SSRC:
        {
          Zoom_Monitor.add_monitor(`ACTIVESSRC:${value.ssrc}`);
          /// await video decode init successed
          jsMediaEngine.pushMessageToWorker(
            WORKER_TYPE.VIDEO_DECODE,
            { ssrc: value.ssrc },
            'newActiveSpeakerSsrc',
            false,
            true
          );
          if (this.RenderInMain == consts.RENDER_IN_MAIN) {
            this.VideoRenderObj?.setActiveSpeakerSsrc(value.ssrc);
          }
        }
        break;
      case jsEvent.CANCEL_NEW_ACTIVE_SPEAKER_BEFORE_CALL_BACK:
        {
          Zoom_Monitor.add_monitor(`CANCELACTIVE:${value.ssrc}`);
          jsMediaEngine.Notify_Video_Decode_Thread({
            command: 'cancelNewActiveSpeakerSsrcBefreCallback',
            ssrc: value.ssrc,
            haddata: value.haddata,
          });
        }
        break;

      case jsEvent.SEND_RENDER_LOG:
        {
          Zoom_Monitor.add_monitor(value.message);
        }
        break;

      case jsEvent.UPDATE_VIDEO_QUALITY:
        {
          if (!jsMediaEngineVariables.rwgAgent) {
            break;
          }
          if (this.userId != value.userId) {
            jsMediaEngineVariables.sendMessageToRwg(jsEvent.SUBSCRIBE_VIDEO, {
              evt: 12303,
              seq: 1,
              body: {
                subInfoList: [
                  {
                    id: Number(value.userId),
                    size: Number(value.videoQuality),
                    bOn: false,
                  },
                ],
              },
            });
          }
        }
        break;

      case jsEvent.ADD_RENDER_VIDEO:
        {
          Zoom_Monitor.add_monitor(`ADDRENDER:${value.userId}`);
          // Starts rendering a specific user's video based on the parameters passed in.
          // Depending on the browser, the rendering may occur on the main thread or a worker thread.

          // userId: The id of the user to render video
          // width: The width in pixels
          // height: The height in pixels
          // x: The x coordinate to render the ? corner within the specified zone
          // y: The y coordinate to render the ? corner within the specified zone
          // canvas: DOM id of canvas element
          // quality: 1
          // isMyself: Whether or not the user is the current user
          // enableWaterMark: Whether or not to render watermark text on top of the video
          // waterMarkText: The string to render as the watermark
          // zone: The id of the zone to render to
          // watermarkOpacity: 1
          // watermarkRepeated: Whether or not the watermark should be repeated
          // watermarkPosition: 1
          // fillMode: 1

          Object.assign(value, this.videoWaterMarkParams);

          if (value.isMyself === undefined) {
            value.isMyself = this.userId == value.userId;
          }

          if (
            util.isSelfPreviewRenderWithVideo() &&
            this.videoCaptureValue &&
            Get_Logical_SSrc(parseInt(value.userId)) ==
              Get_Logical_SSrc(this.videoCaptureValue.ssid) &&
            value.videodom
          ) {
            value.videodom.srcObject = mediaStreamController.videoStream;
            value.videodom.play();
            this.selfPreviewVideotag = value.videodom;
            return;
          }

          value.ssrc = value.userId;
          if (typeof value.canvas === 'string') {
            value.canvas = document.getElementById(value.canvas);
          }
          if (!value.canvas) {
            globalTracingLogger.warn(
              `ADDRENDER:${value.userId}, canvas:${value.canvas}`
            );
            return;
          }
          value.x = Math.floor(value.x);
          value.y = Math.floor(value.y);
          value.width = Math.floor(value.width);
          value.height = Math.floor(value.height);

          this.isCreateVideoWaterMark = value.enableWaterMark;
          this.videoWaterMarkName = this.isCreateVideoWaterMark
            ? value.waterMarkText
            : '';

          if (!this.isMultiView && !value.isMyself) {
            this.CheckCurrentActiveSSRC(value.ssrc);
          }

          if (this.checkIsRenderInWorker()) {
            const renderSelfVideoInEncodeWorker =
              apiSupportUtility.getIsRenderSelfVideoInEncodeWorker(
                jsMediaEngineVariables.localSsrc &&
                  Get_Logical_SSrc(value.ssrc) ===
                    Get_Logical_SSrc(jsMediaEngineVariables.localSsrc)
              );
            /**
             * !important webclient has suspension video mode,
             * which encode and decode video may use same canvas, this is conflict with no-sab vb
             * because the same canvas can't transfer to encode and decode worker at the same time
             */
            const videoMGR = renderSelfVideoInEncodeWorker
              ? jsMediaEngineVariables.localVideoEncMGR
              : jsMediaEngineVariables.localVideoDecMGR;
            const specialSsrc = jsMediaEngineVariables.SPECIAL_ID;
            const rendercanvasID = value.canvas?.id;
            if (!this.replaceCanvasMap.videoDecodeCanvasIds) {
              this.replaceCanvasMap.videoDecodeCanvasIds = [];
            }
            if (!this.replaceCanvasMap.videoEncodeCanvasIds) {
              this.replaceCanvasMap.videoEncodeCanvasIds = [];
            }

            if (renderSelfVideoInEncodeWorker) {
              if (
                this.replaceCanvasMap.videoEncodeCanvasIds.indexOf(
                  rendercanvasID
                ) === -1
              ) {
                this.replaceCanvasMap.videoEncodeCanvasIds.push(rendercanvasID);
              }
            } else {
              if (
                this.replaceCanvasMap.videoDecodeCanvasIds.indexOf(
                  rendercanvasID
                ) === -1
              ) {
                this.replaceCanvasMap.videoDecodeCanvasIds.push(rendercanvasID);
              }
            }
            try {
              if (videoMGR) {
                const renderCanvas = value.canvas.transferControlToOffscreen();
                CheckCanvasSize(value.canvas.width, value.canvas.height);
                const handle = videoMGR.map.get(specialSsrc);
                if (handle) {
                  const data = {
                    command: 'renderOfflineCanvas',
                    ssrc: value.ssrc,
                    rendercanvasID,
                    canvas: renderCanvas,
                    isCreateVideoWaterMark: this.isCreateVideoWaterMark,
                    videoWaterMarkName: this.videoWaterMarkName,
                    waterMarkText: value.waterMarkText,
                    watermarkOpacity: value.watermarkOpacity,
                    watermarkRepeated: value.watermarkRepeated,
                    watermarkPosition: value.watermarkPosition,
                    x: value.x,
                    y: value.y,
                    width: value.width,
                    height: value.height,
                    zone: value.zone,
                    fillMode: value.fillMode,
                    fillModeForResolution: value.fillModeForResolution,
                  };

                  handle.postMessage(data, [renderCanvas]);
                }
              }
            } catch (e) {
              if (videoMGR) {
                const handle = videoMGR.map.get(specialSsrc);
                if (handle) {
                  const data = {
                    command: 'renderOfflineCanvas',
                    rendercanvasID,
                    ssrc: value.ssrc,
                    videoWaterMarkName: this.videoWaterMarkName,
                    isCreateVideoWaterMark: this.isCreateVideoWaterMark,
                    waterMarkText: value.waterMarkText,
                    watermarkOpacity: value.watermarkOpacity,
                    watermarkRepeated: value.watermarkRepeated,
                    watermarkPosition: value.watermarkPosition,
                    x: value.x,
                    y: value.y,
                    width: value.width,
                    height: value.height,
                    zone: value.zone,
                    fillMode: value.fillMode,
                    fillModeForResolution: value.fillModeForResolution,
                  };
                  handle.postMessage(data);
                }
              }
            }
            if (this.RenderInMain === consts.RENDER_UNSET) {
              this.RenderInMain = consts.RENDER_IN_WORKER;
            }
            if (this.currentactive) {
              this.changeVideoRenderActiveSsrc(this.currentactive);
            }
          } else {
            if (!value.isMyself) {
              jsMediaEngine.Notify_Video_Decode_Thread({
                command: 'RenderInMain',
              });
            }
            const rendercanvasID = value.canvas?.id;
            if (this.videoRenderArray.length) {
              for (i = 0; i < this.videoRenderArray.length; i++) {
                if (
                  this.videoRenderArray[i].ssrc == value.ssrc &&
                  this.videoRenderArray[i].zone == value.zone
                ) {
                  return;
                }
              }
            }
            value.rendercanvasID = rendercanvasID;
            this.videoRenderArray.push(value);
            if (!this.VideoRenderObj) {
              this.VideoRenderObj = new VideoRender(
                Object.assign(
                  {},
                  {
                    Notify_APPUI: jsMediaEngineVariables.Notify_APPUI.bind(
                      jsMediaEngineVariables
                    ),
                    isSupportOffscreenCanvas: false,
                    jsMediaEngine: jsMediaEngine,
                    ...(jsMediaEngineVariables.enableMultiDecodeVideoWithoutSAB
                      ? { videodecodethreadnumb: consts.MAX_RENDER_WITHOUT_SAB }
                      : {}),
                    globalTracingLogger,
                    monitorLoggerFn: Zoom_Monitor.add_monitor,
                    renderManager: this.renderManager,
                  }
                )
              );
            }
            if (value.canvasList) {
              value.canvasList.forEach((canvas) => {
                this.videoRenderArray.push(
                  Object.assign({}, value, {
                    canvas,
                  })
                );
              });
            }

            log('Start_Video_Play');
            this.Start_Video_Play();

            this.VideoRenderObj.Set_Render_Array(
              this.videoRenderArray,
              jsMediaEngineVariables.enableMultiDecodeVideoWithoutSAB
            );
            if (this.isCreateVideoWaterMark && !this.waterMarkCanvas) {
              this.waterMarkCanvas = document.createElement('canvas');
            }
            this.VideoRenderObj.Set_WaterMark_Info({
              waterMarkCanvas: this.waterMarkCanvas,
              isCreateVideoWaterMark: this.isCreateVideoWaterMark,
              videoWaterMarkName: this.videoWaterMarkName,
              watermarkOpacity: value.watermarkOpacity,
              watermarkRepeated: value.watermarkRepeated,
              watermarkPosition: value.watermarkPosition,
            });
            if (this.RenderInMain === consts.RENDER_UNSET) {
              this.RenderInMain = consts.RENDER_IN_MAIN;
            }
            if (this.currentactive) {
              this.changeVideoRenderActiveSsrc(this.currentactive);
            }
          }

          await jsMediaEngine.UpdateVideoPlayStatus(true);
        }
        break;
      case jsEvent.UPDATE_CANVAS_SIZE:
        {
          let rendercanvasID =
            typeof value.canvas === 'string' ? value.canvas : value.canvas?.id;
          CheckCanvasSize(value.width, value.height);
          if (
            this.isMultiView ||
            this.isSupportVideoFrameOrBitmapCapture ||
            jsMediaEngineVariables.enableVirtualBackgroundWithoutSAB
          ) {
            var ssrc = jsMediaEngineVariables.SPECIAL_ID;
            var encHandler = null;
            if (
              jsMediaEngineVariables.localVideoEncMGR &&
              jsMediaEngineVariables.enableVirtualBackgroundWithoutSAB
            ) {
              encHandler =
                jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
            }
            if (jsMediaEngineVariables.localVideoDecMGR) {
              var handle =
                jsMediaEngineVariables.localVideoDecMGR.map.get(ssrc);
              if (handle) {
                var data = {
                  command: 'update_canvas_size',
                  rendercanvasID: rendercanvasID,
                  width: value.width,
                  height: value.height,
                };
                handle.postMessage(data);
                if (encHandler) encHandler.postMessage(data);
              }
            }
          } else {
            if (typeof value.canvas === 'string') {
              value.canvas = document.getElementById(value.canvas);
            }
            if (value.canvas) {
              value.canvas.width = value.width;
              value.canvas.height = value.height;
            }
          }
          this.UpdateVideoPlayStatus(true);
        }
        break;
      case jsEvent.ZOOM_RENDER:
        {
          Zoom_Monitor.add_monitor(`ZOOMRENDER:${value.userId}`);
          if (typeof value.canvas !== 'string') {
            value.canvas = value.canvas.id;
          }
          if (
            this.isMultiView ||
            this.isSupportVideoFrameOrBitmapCapture ||
            jsMediaEngineVariables.enableVirtualBackgroundWithoutSAB
          ) {
            const renderSelfVideoInEncodeWorker =
              apiSupportUtility.getIsRenderSelfVideoInEncodeWorker(
                jsMediaEngineVariables.localSsrc &&
                  Get_Logical_SSrc(value.userId) ===
                    Get_Logical_SSrc(jsMediaEngineVariables.localSsrc)
              );
            let ssrc = jsMediaEngineVariables.SPECIAL_ID;
            var handle = null;

            if (
              renderSelfVideoInEncodeWorker &&
              jsMediaEngineVariables.localVideoEncMGR
            ) {
              handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
            } else if (jsMediaEngineVariables.localVideoDecMGR) {
              handle = jsMediaEngineVariables.localVideoDecMGR.map.get(ssrc);
            }
            if (handle) {
              var data = {
                command: 'zoomrender',
                ssrc: value.userId,
                x: value.x,
                y: value.y,
                width: value.width,
                height: value.height,
                canvas: value.canvas,
                zone: value.zone,
                RGBA: value.RGBA,
              };
              handle.postMessage(data);
            }
          }
          if (this.videoRenderArray.length) {
            for (i = 0; i < this.videoRenderArray.length; i++) {
              if (
                this.videoRenderArray[i].ssrc == value.userId &&
                this.videoRenderArray[i].zone == value.zone
              ) {
                this.videoRenderArray[i].x = value.x;
                this.videoRenderArray[i].y = value.y;
                this.videoRenderArray[i].width = value.width;
                this.videoRenderArray[i].height = value.height;
              }
            }
          }
        }
        break;
      case jsEvent.STOP_RENDER_VIDEO:
        {
          Zoom_Monitor.add_monitor(`RMRENDER:${value.userId}`);
          if (typeof value.canvas !== 'string') {
            value.canvas = value.canvas?.id;
          }

          this.videoWaterMarkName = '';
          this.isCreateVideoWaterMark = false;
          value.ssrc = value.userId;
          if (
            this.isMultiView ||
            this.isSupportVideoFrameOrBitmapCapture ||
            jsMediaEngineVariables.enableVirtualBackgroundWithoutSAB
          ) {
            const isRenderSelfVideoInEncodeWorker =
              apiSupportUtility.getIsRenderSelfVideoInEncodeWorker(
                jsMediaEngineVariables.localSsrc &&
                  Get_Logical_SSrc(value.ssrc) ===
                    Get_Logical_SSrc(jsMediaEngineVariables.localSsrc)
              );

            (isRenderSelfVideoInEncodeWorker
              ? jsMediaEngine.Notify_Video_Encode_Thread
              : jsMediaEngine.Notify_Video_Decode_Thread)({
              command: 'stopVideoRender',
              ssrc: value.ssrc,
              canvas: value.canvas,
              RGBA: value.RGBA,
              doNotClean: value.doNotClean,
              x: value.x,
              y: value.y,
              zone: value.zone,
            });
          }

          if (this.videoRenderArray.length) {
            for (i = 0; i < this.videoRenderArray.length; i++) {
              if (
                this.videoRenderArray[i].ssrc == value.ssrc &&
                this.videoRenderArray[i].zone == value.zone
              ) {
                let obseleteData = this.videoRenderArray.splice(i, 1);
                if (!value.doNotClean) {
                  this.VideoRenderObj.clearUseridRender(
                    obseleteData,
                    value.RGBA
                  );
                }
                if (jsMediaEngineVariables.enableMultiDecodeVideoWithoutSAB) {
                  this.VideoRenderObj.GiveBack_Display(obseleteData);
                }
                this.VideoRenderObj.Set_Render_Array(
                  this.videoRenderArray,
                  jsMediaEngineVariables.enableMultiDecodeVideoWithoutSAB
                );
                if (this.videoRenderArray.length == 0) {
                  jsMediaEngine.Notify_Video_Decode_Thread({
                    command: 'stopVideoRender',
                    MFlag: true,
                  });
                  this.Stop_Video_Play();
                }
              }
            }
          }
        }
        break;

      case jsEvent.SWITCH_CANVAS_FOR_VIDEO_CAPTURE:
        {
          var videodom = null;
          if (util.isSelfPreviewRenderWithVideo()) {
            let videodomID;
            if (value instanceof Array) {
              videodomID = value[0];
            } else {
              videodomID = value;
            }
            if (videodomID instanceof HTMLVideoElement) {
              videodom = videodomID;
            } else {
              videodom = util.getDocumentHandle(videodomID);
            }
          }

          if (
            util.isSelfPreviewRenderWithVideo() &&
            videodom &&
            videodom instanceof HTMLVideoElement
          ) {
            if (
              this.videoCaptureValue.videoCtrl &&
              this.videoloadedmetadatahandle
            ) {
              this.videoCaptureValue.videoCtrl.removeEventListener(
                'loadedmetadata',
                this.videoloadedmetadatahandle
              );
            }
            if (
              this.videoCaptureValue.videoCtrl &&
              !this.videoCaptureValue.videoCtrl.paused
            ) {
              this.videoCaptureValue.videoCtrl.pause();
            }
            if (
              this.videoCaptureValue.videoCtrl &&
              this.videoCaptureValue.videoCtrl.srcObject
            ) {
              this.videoCaptureValue.videoCtrl.srcObject = null;
            }
            if (
              this.videoCaptureValue.videoCtrl &&
              this.videoCaptureValue.videoCtrl.src
            ) {
              this.videoCaptureValue.videoCtrl.src = null;
            }

            this.videoCaptureValue.videoCtrl = videodom;
            this.captureVideoOutPutVideoDom = videodom;

            if (!this.isVideoCaptureLoadedmetadata) {
              globalTracingLogger.warn(
                'isVideoCaptureLoadedmetadata is false ,need to reloaded'
              );
              this.videoloadedmetadatahandle =
                this._videoloadedmetadata.bind(this);
              this.videoCaptureValue.videoCtrl.addEventListener(
                'loadedmetadata',
                this.videoloadedmetadatahandle
              );
            }

            mediaStreamController.playVideoStream(
              this.videoCaptureValue.videoCtrl
            );
          } else {
            var canvasIDlist = [];
            if (value instanceof Array) {
              canvasIDlist = value;
            } else {
              canvasIDlist.push(value);
            }

            var canvasCtrlList = this.videoCaptureValue.canvasCtrlList;

            var canvasDomList = canvasCtrlList
              .filter(function (item) {
                return canvasIDlist.indexOf(item.k) !== -1;
              })
              .map(function (item) {
                return item.dom;
              });

            this.switchCanvasForVideoCapture(canvasDomList);
          }
        }
        break;

      case jsEvent.START_CAPTURE_VIDEO:
        {
          jsMediaEngine.Update_Video_Encrpt(jsMediaEngineVariables.e2eencrypt);
          //###UI cannot change mode.
          // if (value.mode) {
          //     this.isSupportImageCapture = util.isSupportImageCapture();
          // } else {
          //     this.isSupportImageCapture = false;
          // }

          //// encode  thread send to decode thread videoFrame by MessageChannel
          this.addChannelForVideo();

          if (
            !this.videoCaptureHiddenCanvas &&
            !this.isSupportVideoFrameOrBitmapCapture
          ) {
            this.videoCaptureHiddenCanvas = util.isSupport2dOffscreenCanvas()
              ? new OffscreenCanvas(640, 360)
              : document.createElement('canvas');
            this.videoCaptureHiddenCanvasCtx =
              this.videoCaptureHiddenCanvas.getContext('2d');
          }

          if (!jsMediaEngine.isVideoEncodeHandleReady()) {
            log.warn(`not isVideoEncodeHandleReady so return`);
            Zoom_Monitor.add_monitor('not isVideoEncodeHandleReady so return');
            return;
          }
          Zoom_Monitor.add_monitor(`STARTVIDEO:${this.isStartVideoCapture}`);
          if (!this.isStartVideoCapture) {
            this.VALUE_CACHE_FOR_START_CAPTURE_VIDEO = Object.assign({}, value);
            this.isStartVideoCapture = true;
            if (this.bVideoEncodeMainThreadConsumerIntervalEnable) {
              this.videoEncodeRingbufferConsumer &&
                this.videoEncodeRingbufferConsumer.consume(20);
            }

            // 2018.06.28 : A value.canvas can be either a collection (array) of canvas ids or a separate canvas id string
            if (
              !this.isSupportVideoTrackReader &&
              !this.isSupportMediaStreamTrackProcessor
            ) {
              value.canvasCtrlList = [];
              value.canvasCtrl = this.videoCaptureHiddenCanvas;
              // By default, the first canvas dom is used as the output source of the video stream
              // this.captureVideoOutputCanvasDomList = [].concat(value.canvasCtrl);

              /**
               * from 2019.10.08 iPad os 13&13+ do not support "send video"
               * if the video dom is "display:none;" or if the video dom is not in the document
               *
               * from 2021.04.15 IOS 14 sending video will be frozen if video tag set visibility:hidden
               */
              if (
                util.isSelfPreviewRenderWithVideo() &&
                (value.video || this.selfPreviewVideotag)
              ) {
                if (value.video) {
                  value.videoCtrl = document.getElementById(value.video);
                } else if (this.selfPreviewVideotag) {
                  value.videoCtrl = this.selfPreviewVideotag;
                }
                this.isSelfViewWithVideo = true;
              } else if (util.browser.isSafari) {
                if (this.MaskSettingVideo) {
                  value.videoCtrl = this.MaskSettingVideo;
                } else {
                  let customVideoDom = document.createElement('video');
                  customVideoDom.setAttribute(
                    'style',
                    'position:fixed;top:-10000px;left:-10000px;'
                  );
                  document.body.appendChild(customVideoDom);
                  value.videoCtrl = customVideoDom;
                }
              } else {
                if (this.MaskSettingVideo) {
                  value.videoCtrl = this.MaskSettingVideo;
                } else {
                  value.videoCtrl = CreateAutoPlayVideoElement();
                }
              }
            } else {
              let videodom;
              if (value.canvas instanceof Array) {
                videodom = value.canvas[0];
              } else {
                videodom = value.canvas;
              }

              if (
                util.isSelfPreviewRenderWithVideo() &&
                util.isSupportVideoFrameOrBitmapCapture()
              ) {
                value.videoCtrl = document.getElementById(value.video);
              } else {
                if (this.MaskSettingVideo) {
                  value.videoCtrl = this.MaskSettingVideo;
                } else {
                  value.videoCtrl = CreateAutoPlayVideoElement();
                }
              }
            }
            this.ReadyCapureWidth = value.width ? value.width : 640;
            this.ReadyCapureHeight = value.width ? value.height : 360;

            this.captureVideoOutPutVideoDom = value.videoCtrl;
            this.videoCaptureValue = value;
            if (this.videoCaptureValue.ssid) {
              jsMediaEngineVariables.localSsrc = this.videoCaptureValue.ssid;
            }
            if (
              !jsMediaEngineVariables.rwgAgent &&
              (!value.fps || value.fps == 24) &&
              navigator.hardwareConcurrency &&
              navigator.hardwareConcurrency > 8 &&
              this.isSupportVideoShare
            ) {
              this.videoCaptureValue.fps = 30;
            }
            this.Start_Video_Capture();
          } else {
            globalTracingLogger.warn(
              'video capture is already started, do not call again.'
            );
          }
        }
        break;

      case jsEvent.STOP_CAPTURE_VIDEO:
        {
          var that = this;
          return new Promise((resolve, reject) => {
            try {
              if (mediaStreamController.isCaptureVideoInProgress) {
                jsMediaEngineVariables.Notify_APPUI(
                  jsEvent.STOP_VIDEO_CAPTURE_FAILED,
                  null
                );
                return reject(
                  new Error(
                    'Too many calls : the device is opening camera now, cannot stop video capture'
                  )
                );
              }

              if (that.isStartVideoCapture) {
                that.Stop_Video_Capture();
              }
              jsMediaEngineVariables.Notify_APPUI(
                jsEvent.STOP_VIDEO_CAPTURE_SUCCESS,
                null
              );
              resolve(true);
            } catch (ex) {
              jsMediaEngineVariables.Notify_APPUI(
                jsEvent.STOP_VIDEO_CAPTURE_FAILED,
                null
              );
              reject(ex);
            }
          });
        }
        break;
      case jsEvent.ADD_RENDER_AUDIO:
        {
          if (this.audioRenderArray.length == 0) {
            this.audioRenderArray.push(value);
            this.Start_Audio_Play();
          }
        }
        break;
      case jsEvent.STOP_RENDER_AUDIO:
        {
          if (this.audioRenderArray.length > 0) {
            this.Stop_Audio_Play();
            for (i = 0; i < this.audioRenderArray.length; i++) {
              if (this.audioRenderArray[i].ssrc == value.ssrc) {
                this.audioRenderArray.splice(i, 1);
              }
            }
          }
        }
        break;
      case jsEvent.PAUSE_OR_RESUME_AUDIO_DECODE: {
        /**
         * unit test:
         * window.zsdk.Notify_MeidaSDK(45, {bPause: true})
         *
         *  {
         *      bPause: boolean
         *  }
         */
        await jsMediaEngine.pushMessageToWorker(
          WORKER_TYPE.AUDIO_DECODE,
          {
            bPause: value.bPause,
          },
          'PAUSE_OR_RESUME_AUDIO_DECODE',
          false,
          true
        );
        break;
      }
      case jsEvent.UNMUTE_AUDIO:
        {
          this.captureAudioMuted = false;
          if (this.audioInputLevel) {
            this.audioInputLevel.start();
          }
          if (this.useAudioBridge && this.audioBridge) {
            this.audioBridge.unmute();
          }
          if (value.issiphone) {
            if (this.WebSipClient) {
              this.WebSipClient.unmute();
              return;
            }
          } else {
            this.UnMuteAudio();
          }
        }
        break;
      case jsEvent.MUTE_AUDIO:
        {
          this.captureAudioMuted = true;
          if (this.audioInputLevel) {
            this.audioInputLevel.stop();
          }
          if (this.useAudioBridge && this.audioBridge) {
            this.audioBridge.mute();
          }
          if (value.issiphone) {
            if (this.WebSipClient) {
              this.WebSipClient.mute();
              return;
            }
          } else {
            this.MuteAudio();
          }
        }
        break;
      case jsEvent.START_SHARING:
        {
          if (!this.sharingCanvasList.includes(value.canvas.id)) {
            this.sharingCanvasList.push(value.canvas.id);
          }
          Object.assign(value, this.sharingWaterMarkParams);
          // Zoom_Monitor.add_monitor("SARS");
          if (this.isSupportVideoShare) {
            jsMediaEngine.Notify_Sharing_Video_Decode_Thread({
              command: 'startSharingRender',
            });
          } else {
            jsMediaEngine.Notify_Sharing_Decode_Thread({
              command: 'startSharingRender',
            });
          }
          this.currentshareactive = value.ssrc;
          if (this.currentshareactive == undefined) {
            Zoom_Monitor.add_monitor2('SSRC');
          }

          this.isCreateSharingWaterMark = value.enableWaterMark;
          this.sharingWaterMarkName = this.isCreateSharingWaterMark
            ? value.waterMarkText
            : '';
          if (this.isSupportOffscreenCanvas) {
            if (this.RenderInMain === consts.RENDER_UNSET) {
              this.RenderInMain = consts.RENDER_IN_WORKER;
            }
            try {
              var ssrc = jsMediaEngineVariables.SPECIAL_ID;
              this.replaceCanvasMap.sharingMainCanvasId = value.canvas.id;
              var handle = null;

              if (this.isSupportVideoShare) {
                if (jsMediaEngineVariables.localVideoDecMGR) {
                  handle =
                    jsMediaEngineVariables.localVideoDecMGR.map.get(ssrc);
                }
              } else {
                if (jsMediaEngineVariables.localSharingDecMGR) {
                  handle =
                    jsMediaEngineVariables.localSharingDecMGR.map.get(ssrc);
                }
              }

              if (handle) {
                this.sharingRenderCanvas =
                  value.canvas.transferControlToOffscreen();
                CheckCanvasSize(value.canvas.width, value.canvas.height);
                var data = {
                  command: 'sharingRenderCanvas',
                  canvas: this.sharingRenderCanvas,
                  rendercanvasID: this.replaceCanvasMap.sharingMainCanvasId,
                  ssrc: this.currentshareactive,
                  isCreateSharingWaterMark: this.isCreateSharingWaterMark,
                  sharingWaterMarkName: this.sharingWaterMarkName,
                  watermarkOpacity: value.watermarkOpacity,
                  watermarkRepeated: value.watermarkRepeated,
                  isFromMainSession: value.isFromMainSession,
                  watermarkPosition: value.watermarkPosition,
                };
                handle.postMessage(data, [data.canvas]);
              }
            } catch (e) {
              var ssrc = jsMediaEngineVariables.SPECIAL_ID;
              var handle = null;
              if (this.isSupportVideoShare) {
                if (jsMediaEngineVariables.localVideoDecMGR) {
                  handle =
                    jsMediaEngineVariables.localVideoDecMGR.map.get(ssrc);
                }
              } else {
                if (jsMediaEngineVariables.localSharingDecMGR) {
                  handle =
                    jsMediaEngineVariables.localSharingDecMGR.map.get(ssrc);
                }
              }
              if (handle) {
                var data = {
                  command: 'sharingRenderCanvas',
                  rendercanvasID: this.replaceCanvasMap.sharingMainCanvasId,
                  ssrc: this.currentshareactive,
                  isCreateSharingWaterMark: this.isCreateSharingWaterMark,
                  sharingWaterMarkName: this.sharingWaterMarkName,
                  watermarkOpacity: value.watermarkOpacity,
                  watermarkRepeated: value.watermarkRepeated,
                  isFromMainSession: value.isFromMainSession,
                  watermarkPosition: value.watermarkPosition,
                };
                handle.postMessage(data);
              }
            }
          } else {
            if (this.RenderInMain === consts.RENDER_UNSET) {
              this.RenderInMain = consts.RENDER_IN_MAIN;
            }
            if (!this.SharingRenderObj) {
              this.SharingRenderObj = new SharingRender(
                Object.assign(
                  {},
                  {
                    Notify_APPUI: jsMediaEngineVariables.Notify_APPUI.bind(
                      jsMediaEngineVariables
                    ),
                    PubSub: PubSub,
                    jsMediaEngine: jsMediaEngine,
                    globalTracingLogger,
                    renderManager: this.renderManager,
                  }
                )
              );
            }

            if (
              this.renderManager.getRendererProvider().isWebGLRendererType()
            ) {
              this.sharingDisplay = new H264bsdCanvas(
                value.canvas,
                value.canvas.id,
                0,
                undefined,
                { preserveDrawingBuffer: false }
              );
            } else if (
              this.renderManager.getRendererProvider().isWebGL2RendererType()
            ) {
              this.sharingDisplay = new WebGL2Canvas(
                value.canvas,
                value.canvas.id,
                0,
                undefined,
                { preserveDrawingBuffer: false }
              );
            }

            this.SharingRenderObj.Set_Render_Display(this.sharingDisplay);
            this.SharingRenderObj.Change_Current_SSRC(
              this.currentshareactive,
              value.isFromMainSession
            );
            if (this.isCreateSharingWaterMark && !this.waterMarkCanvas) {
              this.waterMarkCanvas = document.createElement('canvas');
            }
            this.SharingRenderObj.Set_WaterMark_Info({
              waterMarkCanvas: this.waterMarkCanvas,
              isCreateSharingWaterMark: this.isCreateSharingWaterMark,
              sharingWaterMarkName: this.sharingWaterMarkName,
              watermarkOpacity: value.watermarkOpacity,
              watermarkRepeated: value.watermarkRepeated,
              watermarkPosition: value.watermarkPosition,
            });
            if (!this.sharingInterval) {
              this.sharingInterval = this.JsMediaSDK_SharingRenderInterval(
                this.sharingIntervalTime,
                value.isVideoShare
              );
            }
            jsMediaEngine.UpdateSharingPlayStatus(true);
          }
        }
        break;
      case jsEvent.STOP_SHARING:
        {
          if (value.canvas) {
            const index = this.sharingCanvasList.indexOf(value.canvas.id);
            if (index !== -1) {
              this.sharingCanvasList.splice(index, 1);
            }
          } else {
            this.sharingCanvasList = [];
          }

          if (this.sharingCanvasList.length !== 0) {
            return;
          }
          jsMediaEngine.UpdateSharingPlayStatus(false);
          if (this.isSupportVideoShare) {
            jsMediaEngine.Notify_Sharing_Video_Decode_Thread({
              command: 'stopSharingRender',
            });
          } else {
            jsMediaEngine.Notify_Sharing_Decode_Thread({
              command: 'stopSharingRender',
            });
          }

          this.isCreateSharingWaterMark = false;
          this.sharingWaterMarkName = '';
          if (this.sharingInterval) {
            clearInterval(this.sharingInterval);
            this.sharingInterval = 0;
          }
          if (this.sharingDisplay) {
            this.sharingDisplay.clear();
            this.sharingDisplay.cleanup(null, this.supportLoseContext);
            this.sharingDisplay = null;
          }
          if (this.SharingRenderObj) {
            this.SharingRenderObj.ClearQueue();
            this.SharingRenderObj.Set_WaterMark_Info({
              waterMarkCanvas: this.waterMarkCanvas,
              isCreateSharingWaterMark: this.isCreateSharingWaterMark,
              sharingWaterMarkName: this.sharingWaterMarkName,
            });
          }
        }
        break;
      case jsEvent.UPDATE_SHARING_DECODE_PARAM: {
        /**
                 console uint test code
                 setInterval(() => {
                        let cv = document.querySelector('.sharee-container__canvas');
                        let {width, height} = cv.getBoundingClientRect()
                        window.zsdk.Notify_MeidaSDK(44, {
                            width,
                            height
                        })
                     }, 1000)
                 */
        /**
         * const UPDATE_SHARING_DECODE_PARAM = 44;
         *
         * For the latest Chrome(version:86), offscreencanvas require setting the actual size of the canvas DOM
         * For the most part, the actual size is  `{width, height} = canvas.getBoundingClientRect()`
         * Otherwise there will be a slight flicker in the sharing screen
         * width
         * height
         */
        let { width, height, canvas } = value;
        if (
          canvas &&
          canvas.id &&
          !this.sharingCanvasList.includes(canvas.id)
        ) {
          return;
        }
        log('UPDATE_SHARING_DECODE_PARAM', value);
        if (this.SharingRenderObj) {
          this.SharingRenderObj.Update_Sharing_Canvas_Size({
            width,
            height,
          });
        } else {
          if (this.isSupportVideoShare) {
            jsMediaEngine.Notify_Sharing_Video_Decode_Thread({
              command: 'UPDATE_SHARING_CANVAS_SIZE',
              width,
              height,
            });
          } else {
            jsMediaEngine.Notify_Sharing_Decode_Thread({
              command: 'UPDATE_SHARING_CANVAS_SIZE',
              width,
              height,
            });
          }
        }
        if (jsMediaEngine.isSharingNotClearChromeVersion()) {
          CheckCanvasSize(width, height);
          return log(
            'isSharingNotClearChromeVersion',
            jsMediaEngine.isSharingNotClearChromeVersion()
          );
        }
        ///
        /// offscreen canvas update size render will flash
        /// is only used for videosdk ,jsMediaEngineVariables.rwgAgent will set in videosdk
        if (!jsMediaEngineVariables.rwgAgent) {
          break;
        }

        if (this.isSupportVideoShare) {
          jsMediaEngine.Notify_Sharing_Video_Decode_Thread({
            command: 'SET_OFFSCREENCANVAS_WIDTH_HEIGHT',
            data: { width, height },
          });
        } else {
          await jsMediaEngine.pushMessageToWorker(
            WORKER_TYPE.SHARING_DECODE,
            {
              width,
              height,
            },
            'SET_OFFSCREENCANVAS_WIDTH_HEIGHT',
            false,
            true
          );
        }

        break;
      }

      case jsEvent.CHANGE_FRAME_RATE: //Change_FrameRate
        {
        }
        break;
      case jsEvent.CHANGE_VIDEO_RESOLUTION: //Change_Resolution
        break;
      case jsEvent.CHANGE_AUDIO_SPEAKER: // Change_Speaker
        {
          if (!value.AudioSelectValue) {
            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.AUDIO_SPEAKER_SET_ERROR,
              'value.AudioSelectValue is null'
            );
            return;
          }
          let startSelectSpreakerTime = Date.now();
          if (this.useAudioBridge && this.audioBridge) {
            this.audioBridge.changeSpeaker(value.AudioSelectValue);
            return;
          }

          this.selectComputerAudioSpeaker(
            value.AudioSelectValue,
            startSelectSpreakerTime
          );
        }
        break;
      case jsEvent.AUDIO_DENOISE_SWITCH:
        {
          deviceManager.changeDenoiseSwitch(value.switch);
        }
        break;
      case jsEvent.CHANGE_AUDIO_PROFILE:
        {
          let lastAudioProfile = this.audioCapture?.audioProfile;
          if (!lastAudioProfile) break;
          this.audioCapture.audioProfile = value;
          if (value.currentSelect === 'backgroundNoiseSuppression') {
            if (
              lastAudioProfile.currentSelect !== 'backgroundNoiseSuppression'
            ) {
              let data = {
                command: 'original_sound_switch',
                enable: false,
                highfidelity: false,
                stereo: false,
              };
              if (this.audioBridge) {
                await this.audioBridge.setAudioProfile(value);
              } else {
                jsMediaEngine.Notify_Audio_Encode_Thread(data);
              }
              // getUsermedia again, open NS,AGC
              mediaStreamController.destoryAudioMediaStream();
              this.Start_Audio_Capture();
            }
            if (lastAudioProfile.highBitrate !== value.highBitrate) {
              if (this.audioBridge) {
                await this.audioBridge.setAudioProfile(value);
              } else {
                jsMediaEngine.Notify_Audio_Encode_Thread({
                  command: 'audioMode',
                  mode: -1,
                  highBitrate: value.highBitrate ? 1 : 0,
                });
              }
            }
            let denoiseSwitch = value.backgroundNoiseSuppression === 'Zoom';
            deviceManager.changeDenoiseSwitch(denoiseSwitch);
          } else {
            let data = {
              command: 'original_sound_switch',
              enable: true,
              highfidelity: value.originalSound.highfidelity,
              stereo: value.originalSound.stereo,
            };
            if (this.audioBridge) {
              await this.audioBridge.setAudioProfile(value);
            } else {
              jsMediaEngine.Notify_Audio_Encode_Thread(data);
            }
            if (
              lastAudioProfile.currentSelect !== 'originalSound' ||
              lastAudioProfile.originalSound?.stereo !==
                value.originalSound.stereo
            ) {
              // getUsermedia again, close NS,AGC
              mediaStreamController.destoryAudioMediaStream();
              this.Start_Audio_Capture();
            }
          }
        }
        break;
      case jsEvent.CHANGE_VIDEO_CAPTURE_DEVICE:
        {
          //Change_Capture_Devic:
          let config = Object.assign(
            {},
            this.VALUE_CACHE_FOR_START_CAPTURE_VIDEO,
            value
          );
          if (
            !this.isSupportVideoTrackReader &&
            !this.isSupportMediaStreamTrackProcessor
          ) {
            config.canvasCtrlList = [];
            if (config.canvas instanceof Array) {
              for (var i = 0; i < config.canvas.length; i++) {
                config.canvasCtrlList.push({
                  k: config.canvas[i],
                  dom: util.getDocumentHandle(config.canvas[i]),
                });
              }
            } else {
              config.canvasCtrlList.push({
                k: config.canvas,
                dom: util.getDocumentHandle(config.canvas),
              });
            }
            config.canvasCtrl = config.canvasCtrlList[0].dom;
          }
          config.videoCtrl = this.captureVideoOutPutVideoDom;

          if (
            this.videoCaptureValue?.videoCtrl &&
            this.videoloadedmetadatahandle
          ) {
            this.videoCaptureValue.videoCtrl.removeEventListener(
              'loadedmetadata',
              this.videoloadedmetadatahandle
            );
          }
          this.isVideoCaptureLoadedmetadata = false;

          mediaStreamController.destoryVideoMediaStream();
          this.videoCaptureValue = config;
          if (this.videoCaptureValue.videoCtrl?.srcObject) {
            this.videoCaptureValue.videoCtrl.srcObject = null;
          }
          if (this.videoCaptureValue.videoCtrl?.src) {
            this.videoCaptureValue.videoCtrl.src = null;
          }

          this.StartVideoMediaCapture();
        }
        break;
      case jsEvent.CHANGE_SHARING_2ND_VIDEO_CAPTUREVIDEO_DEVICE:
        {
          if (value.canvas) {
            value.rendercanvasID = value.canvas;
          }
          let config = Object.assign({}, this.desktopSharingValue, value);
          this.EndSharingMediaStream();
          this.desktopSharingValue = config;
          this.StartDesktopMediaCapture();
        }
        break;
      case jsEvent.SHARE_2ND_AUDIO_CAPTURE_DEVICE:
        {
          this.EndSharingMediaStream();
          this.desktopSharingValue = value;
          this.StartDesktopMediaCapture();
        }
        break;
      case jsEvent.CHANGE_CURRENT_ACTIVE_SSRC:
        {
          if (this.currentactive != value.ssrc) {
            this.currentactive = value.ssrc;
            jsMediaEngineVariables.CurrentSSRC = this.currentactive;
            this.changeVideoRenderActiveSsrc(this.currentactive);
          }
          jsMediaEngine.Notify_Audio_Thread_CurrentSSRC(
            jsMediaEngineVariables.CurrentSSRC
          );
        }
        break;

      case jsEvent.CHANGE_CURRENT_SHARING_ACTIVE_SSRC:
        {
          if (this.currentshareactive != value.ssrc) {
            this.currentshareactive = value.ssrc;
          }
          if (this.isSupportOffscreenCanvas) {
            if (this.isSupportVideoShare) {
              jsMediaEngine.Notify_Sharing_Video_Decode_Thread({
                command: 'CHANGE_CURRENT_SHARING_ACTIVE_SSRC',
                ssrc: value.ssrc,
                isFromMainSession: value.isFromMainSession,
              });
            } else {
              var ssrc = jsMediaEngineVariables.SPECIAL_ID;
              if (jsMediaEngineVariables.localSharingDecMGR) {
                var handle =
                  jsMediaEngineVariables.localSharingDecMGR.map.get(ssrc);
                if (handle) {
                  var data = {
                    command: 'CHANGE_CURRENT_SHARING_ACTIVE_SSRC',
                    ssrc: value.ssrc,
                    isFromMainSession: value.isFromMainSession,
                  };
                  handle.postMessage(data);
                }
              }
            }
          } else {
            if (!this.SharingRenderObj) {
              this.SharingRenderObj = new SharingRender(
                Object.assign(
                  {},
                  {
                    Notify_APPUI: jsMediaEngineVariables.Notify_APPUI.bind(
                      jsMediaEngineVariables
                    ),
                    PubSub: PubSub,
                    jsMediaEngine: jsMediaEngine,
                    globalTracingLogger,
                    renderManager: this.renderManager,
                  }
                )
              );
            }
            this.SharingRenderObj.Change_Current_SSRC(
              this.currentshareactive,
              value.isFromMainSession
            );
          }
        }
        break;

      case jsEvent.LEAVE_MEETING: //Leave_Meeting :
        {
          this.Stop_Audio_Play();
        }
        break;
      case jsEvent.MEETING_FAIL_OVER: //failover :
        {
          this.Meeting_Fail_Over(
            value.audio_websocket_address,
            value.video_websocket_address
          );
        }
        break;
      case jsEvent.END_MEDIA: //End_Media
        {
          log('jsEvent.END_MEDIA');
          PubSub.trigger(PUBSUB_EVT.END_MEDIA);
          this.destroy().catch((ex) => log.error(ex));
        }
        break;
      case jsEvent.CHANGE_AUDIO_MIC:
        {
          if (this.audioCapture && value && this.audioCtx) {
            if (this.enableHID) {
              if (this.hidAvalible) {
                await HIDControl.destroy();
                this.hidAvalible = false;
              }
              if (value.microphoneLabel) {
                this.hidAvalible = await HIDControl.init(
                  value.microphoneLabel,
                  value.defaultMuted
                );
              }
            }

            mediaStreamController.destoryAudioMediaStream();
            try {
              this.isCaputureNodeConnect = false;
              value.audioProfile = this.audioCapture.audioProfile;
              this.audioCapture = value;
              if (this.audioCtx.sampleRate != this.sampleRate) {
                this.sampleRate = this.audioCtx.sampleRate;
                jsMediaEngine.Modify_Audio_SampleRate(this.sampleRate);
              }
              this.Start_Audio_Capture();
              jsMediaEngine.Reset_Aec();
            } catch (e) {
              jsMediaEngineVariables.Notify_APPUI(
                jsEvent.AUDIO_MIC_SET_ERROR,
                e
              );
              this.JsMediaSDK_Log(e);
            }
          } else {
            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.AUDIO_MIC_SET_ERROR,
              'value is null or not join computer audio before change audio mic'
            );
          }
          return;
        }
        break;
      case jsEvent.WEBRTC_RESTART:
        {
          if (value) {
            jsMediaEngine.Notify_Audio_Thread_Status(
              jsMediaEngineVariables.SPECIAL_ID,
              jsEvent.AUDIO_START
            );
            mediaStreamController.destoryAudioMediaStream();
            this.Remove_Audio_Capture();
            this.audioCapture = value;
            this.Start_Audio_Capture();
            jsMediaEngine.Reset_Aec();
          }
        }
        break;
      case jsEvent.LEAVE_COMPUTER_AUDIO:
        {
          mediaStreamController.destoryAudioMediaStream();
          this.audioCapture = null;
          this.captureAudioMuted = false;
          if (this.hidAvalible) {
            await HIDControl.destroy();
            this.hidAvalible = false;
          }
          jsMediaEngineVariables.ComputerAudioStatus =
            AudioStatus.ComputerAudio_Null;
          if (this.audioInputLevel) {
            this.audioInputLevel.destroy();
            this.audioInputLevel = null;
            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.LEAVE_COMPUTER_AUDIO_COMPLETE,
              null
            );
            return;
          }
          if (
            this.useAudioBridge &&
            this.audioBridge &&
            !this.audioBridge.isDestroyed
          ) {
            this.audioBridge.enableBroadCastToBO(false);
            this.audioBridge.leaveAudioWithoutDisconnect(
              consts.WEBRTC_COMMPUTER_AUDIO_MODE
            );

            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.LEAVE_COMPUTER_AUDIO_COMPLETE,
              null
            );
            return;
          }
          if (
            value &&
            value.issiphone &&
            this.webrtcaudiodom &&
            this.WebSipClient
          ) {
            this.webrtcaudiodom.muted = true;
            this.WebSipClient.mute();
            return;
          }
          if (
            jsMediaEngineVariables.ComputerAudioStatus ===
            AudioStatus.ComputerAudio_Connecting
          ) {
            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.LEAVE_COMPUTER_AUDIO_COMPLETE,
              null
            );
            return;
          }
          this.audioEncodeRingBufferConsumer &&
            this.audioEncodeRingBufferConsumer.cancelConsume();

          this.Remove_Audio_Capture();
          this.Remove_Audio_Play();
          this.CloseBoringPeerConnection();
          if (this.checkWorkletInterval) {
            clearInterval(this.checkWorkletInterval);
            this.checkWorkletInterval = null;
          }
          try {
            if (jsMediaEngineVariables.AudioNode) {
              jsMediaEngineVariables.AudioNode.disconnect();
              jsMediaEngineVariables.AudioNode.postCMD('stopWorklet', true);
              jsMediaEngineVariables.AudioNode.port = null;
            }
          } catch (ex) {
            log('AudioNode.port', ex);
          }

          jsMediaEngineVariables.AudioNode = null;
          jsMediaEngineVariables.workletWasmInitSuccess = false;
          if (this.audioCtx) {
            this.audioCtx.onstatechange = null;
            this.audioCtx.close();
            this.isCaputureNodeConnect = false;
          }
          this.audioCtx = null;
          this.audioPlay = false;
          this.audioDomNode = null;
          jsMediaEngineVariables.Notify_APPUI(
            jsEvent.LEAVE_COMPUTER_AUDIO_COMPLETE,
            null
          );
        }
        break;
      case jsEvent.JOIN_COMPUTER_AUDIO:
        {
          this.enableHID = value.CaptureAudioInfo.enableHID;
          if (this.enableHID) {
            if (value.CaptureAudioInfo.microphoneLabel) {
              try {
                this.hidAvalible = await HIDControl.init(
                  value.CaptureAudioInfo.microphoneLabel,
                  value.CaptureAudioInfo.defaultMuted
                );
              } catch (e) {
                globalTracingLogger.log('auto connect hid fail');
                jsMediaEngineVariables.Notify_APPUI(
                  jsEvent.AUDIO_CONNECT_HID_JOIN_FAILED,
                  e
                );
              }
            }
          }

          jsMediaEngineVariables.ComputerAudioStatus =
            AudioStatus.ComputerAudio_Connecting;
          try {
            if (value.checkAutoplay) {
              await util.checkAudioAutoPlay();
            }
          } catch (e) {
            log.error('audio auto play', e);
            jsMediaEngineVariables.ComputerAudioStatus =
              AudioStatus.ComputerAudio_Null;
            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.JOIN_COMPUTER_AUDIO_COMPLETE,
              null
            );
            return jsMediaEngineVariables.Notify_APPUI(
              jsEvent.AUDIO_AUTO_PLAY_FAILED,
              e
            );
          }

          if (this.audioInputLevel) {
            this.audioInputLevel.destroy();
            this.audioInputLevel = null;
          }
          if (value.isPreviewMode) {
            if (value.CaptureAudio) {
              this.audioCapture = value.CaptureAudioInfo;
              this.audioInputLevel = new AudioInputLevel({
                analyserCallback: () => {
                  jsMediaEngineVariables.Notify_APPUI_SAFE(
                    jsEvent.AUDIO_PREVIEW_ASN
                  );
                },
              });
              /** this is for ui, ui need this to hanlde logic */
              if (!mediaStreamController.shouldCaptureAudio()) {
                jsMediaEngineVariables.Notify_APPUI(
                  jsEvent.USER_GRANT_CAPTURE_AUDIO,
                  null
                );
              }
              this.Start_Audio_Capture();
            } else {
              jsMediaEngineVariables.ComputerAudioStatus =
                AudioStatus.ComputerAudio_Connected;
              jsMediaEngineVariables.Notify_APPUI(
                jsEvent.JOIN_COMPUTER_AUDIO_COMPLETE,
                null
              );
            }
            return;
          }

          if (!this.isSupportVideoShare && this.isSupportOffscreenCanvas) {
            var videosharesyncchannel = new MessageChannel();
            jsMediaEngine.Notify_Video_Sharing_Msg_Channel(
              videosharesyncchannel,
              videosharesyncchannel
            );
          }

          // use webrtc audio bridge transport audio
          this.useAudioBridge = value.useAudioBridge && value.audioBridge;
          if (this.useAudioBridge) {
            deviceManager.setUserId(value.CaptureAudioInfo.ssrc);
            const {
              nginxHost,
              rwgHost,
              cid,
              abToken,
              supportLocalAB,
              useWebRTCOnDesktop,
            } = value.audioBridge;
            await this.previewInit({
              audioBridge: {
                nginxHost,
                rwgHost,
                cid,
                abToken,
                isCapturingAudio: value.CaptureAudio,
                audioMode: consts.WEBRTC_COMMPUTER_AUDIO_MODE,
                supportLocalAB,
                useWebRTCOnDesktop,
              },
            });
            if (!this.audioBridge) {
              //mediasdk is destoryed before await resolve
              globalTracingLogger.error(
                'audioBridge instance is null after initialization'
              );
              break;
            }
            deviceManager.setAudioBridge(this.audioBridge);
            if (value.CaptureAudio) {
              this.audioCapture = value.CaptureAudioInfo;
              if (value.CaptureAudioInfo?.audioProfile) {
                this.audioBridge.setAudioProfile(
                  value.CaptureAudioInfo.audioProfile
                );
              }
              this.Start_Audio_Capture();
            } else {
              jsMediaEngineVariables.ComputerAudioStatus =
                AudioStatus.ComputerAudio_Connected;
              jsMediaEngineVariables.Notify_APPUI(
                jsEvent.JOIN_COMPUTER_AUDIO_COMPLETE,
                null
              );
            }

            deviceManager.updateSelectedSpeakerDevices('default', 0, true);
            return;
          }

          if (value.issiphone) {
            if (this.WebSipClient) {
              try {
                await this.WebSipClient.clear();
                this.WebSipClient = null;
              } catch (e) {
                /*
                 * Just use catch
                 * */
              }
            }
            let server = value.sip.sipServer;
            let meetingnumber = value.sip.meetingnumber;
            let participantId = value.sip.participantId;
            let sipsbc;
            try {
              sipsbc = value.sip.sipNumberList[0].number.replace(' ', '');
            } catch (e) {
              //free accout
              sipsbc = '+10000';
            }
            let username = 'sip:' + 'zoom.wcl.sip' + '@zoom.us';
            let audiodom = value.sip.audio;
            this.webrtcaudiodom = audiodom;
            this.WebSipClient = new WebSipClient(
              server,
              meetingnumber,
              participantId,
              sipsbc,
              username,
              audiodom
            );
            try {
              await this.WebSipClient.connect();
              await this.WebSipClient.call();
            } catch (error) {
              globalTracingLogger.error('Error connecting to SIP audio', error);
              if (error.name && error.name.indexOf('NotAllowedError') != -1) {
                jsMediaEngineVariables.Notify_APPUI(
                  jsEvent.USER_FORBIDDED_CAPTURE_AUDIO,
                  null
                );
              }
            }
            return;
          }

          try {
            this.Remove_Audio_Play();
            this.Remove_Audio_Capture();
            this.CloseBoringPeerConnection();

            jsMediaEngine.Notify_Audio_Thread_Status(
              jsMediaEngineVariables.SPECIAL_ID,
              jsEvent.AUDIO_START
            );
            if (this.audioCtx) {
              this.audioCtx.close();
              this.isCaputureNodeConnect = false;
            }
            let audioContextConfigure = util.getAudioContextConfigure();

            this.audioCtx = createAudioContext('Main', audioContextConfigure);

            if (this.audioCtx.sampleRate != this.sampleRate) {
              this.sampleRate = this.audioCtx.sampleRate;
              jsMediaEngine.Modify_Audio_SampleRate(this.sampleRate);
            }

            // tesla wants to use their own aec.
            this.disableAudioAGC = value.disableAudioAGC;
            this.disableNoiseSuppression = value.disableNoiseSuppression;
            this.disableBrowserAec = value.disableBrowserAec;
            if (this.bAudioDecodeUsingSAB) {
              Zoom_Monitor.add_monitor('ASSAB');
            }

            /**
             * fix mac safari join as attendee, audio can't auto play bug
             */
            if (this.audioCtx.state === 'suspended') {
              this.audioCtx.resume().catch((e) => {
                globalTracingLogger.error('audioContext resume fail', e);
              });
            }
            var that = this;
            /**
             * audioWorklet.addModule cannot support fetch data => URL.createObjectUrl => constructor audioWorlet.addModule
             */

            let workletPath = null;
            let wasmPath = null;
            if (!jsMediaEngineVariables.decoderinworklet) {
              workletPath = this.audioWorkletJsPath.audioWorkletPath;
              wasmPath = this.audioWorkletJsPath.audioWasm;
            } else {
              let isSupportSIMD = await util.isSDKSupportSIMD();
              if (isSupportSIMD) {
                workletPath = this.audioWorkletJsPath.audioWorkletSIMDPath;
                wasmPath = this.audioWorkletJsPath.audioSIMDWasm;
              } else {
                workletPath = this.audioWorkletJsPath.audioWorkletProcessPath;
                wasmPath = this.audioWorkletJsPath.audioWasm;
              }
            }

            if (this.isDestroy) {
              return;
            }
            const module = await util.downloadAndCompileWebAssembly(
              wasmPath,
              'AudioWorklet',
              Zoom_Monitor,
              !util.browser.isSafari &&
                jsMediaEngineVariables.enableStreamingInstantiate // Safari has a bug when instantiating the result of compileStreaming inside an AudioWorklet
            );
            await this.audioCtx.audioWorklet.addModule(workletPath);
            jsMediaEngineVariables.AudioNode = new zoomAudioWorkletNode(
              that.audioCtx,
              'zoomAudioWorklet',
              {
                processorOptions: {
                  sharedBuffer:
                    util.browser.isSafari && jsMediaEngineVariables.sharedBuffer
                      ? jsMediaEngineVariables.sharedBuffer
                      : null,
                  userAgent: navigator.userAgent,
                  audioDecodeChannelsNum: util.isSupportPlayStereo() ? 2 : 1,
                  audioEncodeChannelsNum: util.isBrowserSupportStereo() ? 2 : 1,
                  wasmModule: module,
                },
                numberOfOutputs: 1,
                outputChannelCount: [2],
              }
            );
            jsMediaEngineVariables.AudioNode.onprocessorerror = (event) => {
              globalTracingLogger.error(
                'Exception thrown in AudioWorkletProcessor',
                event
              );
            };

            if (util.browser.isSafari && !jsMediaEngineVariables.sharedBuffer) {
              jsMediaEngineVariables.AudioNode.postCMD(
                'diableSharedArrayBuffer'
              );
            }
            if (!jsMediaEngineVariables.decoderinworklet) {
              jsMediaEngineVariables.AudioNode.postCMD(
                'disableDecoderinworklet'
              );
            }

            if (jsMediaEngineVariables.decoderinworklet) {
              if (this.codecDoAVSync) {
                jsMediaEngineVariables.AudioNode.postCMD('codecDoAVSync', true);
              } else if (jsMediaEngineVariables.CurrentSSRC) {
                jsMediaEngineVariables.AudioNode.postCMD(
                  'currentSSRC',
                  jsMediaEngineVariables.CurrentSSRC
                );
              }
              jsMediaEngineVariables.AudioNode.postCMD('initData', {
                userid: value.CaptureAudioInfo.ssrc,
                meetingnumb: jsMediaEngineVariables.localAudioPara.meetingnumb,
                meetingid: jsMediaEngineVariables.localAudioPara.meetingid,
                qoson: jsMediaEngineVariables.localAudioPara.qoson,
              });

              jsMediaEngineVariables.AudioNode.postCMD('audiowasm');
            }

            if (that.checkWorkletInterval) {
              clearInterval(that.checkWorkletInterval);
              that.checkWorkletInterval = null;
            }
            that.checkWorkletInterval = setInterval(() => {
              if (jsMediaEngineVariables.AudioNode) {
                jsMediaEngineVariables.AudioNode.postCMD('checkProcess', null);
              }
            }, 10000);

            // jsMediaEngineVariables.AudioNode.connect(that.audioCtx.destination);
            // share system audio need use peerConnection
            let isSupportChromeWideAEC =
              util.isSupportChromeWideAEC() &&
              !jsMediaEngineVariables.shareSystemAudio;
            if (
              util.browser.isChrome &&
              !(util.isAndroidBrowser() || util.isQuestBrowser())
            ) {
              if (this.disableBrowserAec) {
                this.isSupportBrowserAec = false;
              } else {
                this.isSupportBrowserAec =
                  isSupportChromeWideAEC ||
                  (await this.isLocalP2PSupportedPromise);
              }

              if (jsMediaEngineVariables.sharedBuffer) {
                jsMediaEngineVariables.AudioNode.postCMD(
                  'sharedBuffer',
                  jsMediaEngineVariables.sharedBuffer
                );
              }
              let realStream;
              if (!this.disableBrowserAec) {
                if (this.isSupportBrowserAec) {
                  if (!isSupportChromeWideAEC) {
                    let dest = that.audioCtx.createMediaStreamDestination();
                    jsMediaEngineVariables.AudioNode.connect(dest);
                    realStream = dest.stream;
                    realStream = await that.chromeAecWorkAround(dest.stream);
                  }
                } else {
                  jsMediaEngine.Notify_Audio_Thread_AEC_Status(
                    jsMediaEngineVariables.SPECIAL_ID,
                    false
                  );
                }
              }
              let playDevice = null;
              if (!value.speakerInfo || !value.speakerInfo.defaultDeviceId) {
                playDevice = '';
              } else {
                playDevice = value.speakerInfo.defaultDeviceId;
              }
              let startSelectSpeakerTime = Date.now();
              if (isSupportChromeWideAEC) {
                jsMediaEngineVariables.AudioNode.connect(
                  that.audioCtx.destination
                );
              } else {
                if (!that.audioDomNode) {
                  that.audioDomNode = new Audio();
                }
                that.audioDomNode.srcObject = realStream;
                that.audioDomNode.play().catch((error) => {
                  globalTracingLogger.error('Audio play failed', error);
                });
              }
              that.selectComputerAudioSpeaker(
                playDevice,
                startSelectSpeakerTime
              );
            } else {
              if (jsMediaEngineVariables.sharedBuffer) {
                jsMediaEngineVariables.AudioNode.postCMD(
                  'sharedBuffer',
                  util.browser.isSafari
                    ? null
                    : jsMediaEngineVariables.sharedBuffer
                );
              }

              /// for android deveice,use zoom aec.
              /// because android device we only use default output device. can't do p2p
              if (util.isAndroidBrowser() || util.isQuestBrowser()) {
                let dest = that.audioCtx.createMediaStreamDestination();
                jsMediaEngineVariables.AudioNode.connect(dest);
                if (this.disableBrowserAec) {
                  this.isSupportBrowserAec = false;
                } else {
                  this.isSupportBrowserAec = await this
                    .isLocalP2PSupportedPromise;
                }
                let realStream = dest.stream;
                if (!this.disableBrowserAec) {
                  if (this.isSupportBrowserAec) {
                    realStream = await that.chromeAecWorkAround(dest.stream);
                  } else {
                    jsMediaEngine.Notify_Audio_Thread_AEC_Status(
                      jsMediaEngineVariables.SPECIAL_ID,
                      false
                    );
                  }
                }
                if (!that.audioDomNode) {
                  that.audioDomNode = new Audio();
                }
                that.audioDomNode.srcObject = realStream;
                that.audioDomNode.play().catch((error) => {
                  globalTracingLogger.error('Audio play failed', error);
                });
              } else {
                //firefox 116 support get output devices list,
                // we can select audio speaker now
                if (
                  util.browser.isFirefox &&
                  util.isFirefoxVersionHigherThan(116)
                ) {
                  let dest = that.audioCtx.createMediaStreamDestination();
                  jsMediaEngineVariables.AudioNode.connect(dest);
                  let realStream = dest.stream;
                  if (!that.audioDomNode) {
                    that.audioDomNode = new Audio();
                  }
                  that.audioDomNode.srcObject = realStream;
                  let playDevice = null;
                  let startSelectSpeakerTime = Date.now();
                  if (
                    !value.speakerInfo ||
                    !value.speakerInfo.defaultDeviceId
                  ) {
                    playDevice = '';
                  } else {
                    playDevice = value.speakerInfo.defaultDeviceId;
                  }
                  this.selectComputerAudioSpeaker(
                    playDevice,
                    startSelectSpeakerTime
                  );

                  that.audioDomNode.play().catch((error) => {
                    globalTracingLogger.error('Audio play failed', error);
                  });
                } else {
                  jsMediaEngineVariables.AudioNode.connect(
                    that.audioCtx.destination
                  );
                  deviceManager.updateSelectedSpeakerDevices(
                    'default',
                    0,
                    true
                  );
                }
              }
            }

            jsMediaEngine.Notify_Audio_Decode_Thread({
              command: 'stop_audio_incoming',
              stopPlayAudio: false,
            });

            jsMediaEngine.Notify_Audio_Encode_Thread({
              command: 'startAudioEncode',
              ssid: value.CaptureAudioInfo.ssrc,
              audioMode: jsMediaEngineVariables.audioMode,
            });
            jsMediaEngine.Update_Audio_Encrpt(
              jsMediaEngineVariables.e2eencrypt
            );

            var audio_decode_channel = new MessageChannel();
            var audio_encode_channel = new MessageChannel();
            var audio_worker_channel = new MessageChannel();
            jsMediaEngineVariables.AudioNode.port.postMessage(
              { status: 'encodeAudioPort' },
              [audio_encode_channel.port2]
            );
            jsMediaEngineVariables.AudioNode.port.postMessage(
              { status: 'decodeAudioPort' },
              [audio_decode_channel.port1]
            );
            jsMediaEngineVariables.AudioNode.port.postMessage({
              status: 'sampleRate',
              data: that.audioCtx.sampleRate,
            });
            jsMediaEngine.Notify_Audio_Thread_Msg_Channel(
              audio_decode_channel,
              audio_encode_channel,
              'decodeAudioPort',
              'encodeAudioPort'
            );
            if (
              jsMediaEngineVariables.enableEchoDetection &&
              !jsMediaEngineVariables.sharedBuffer
            ) {
              jsMediaEngine.Notify_Audio_Thread_Msg_Channel(
                audio_worker_channel,
                audio_worker_channel,
                'audioWorkerPort',
                'audioWorkerPort'
              );
            }
            that.Start_Audio_Play();
            //msg channel create here!
            if (this.isSupportOffscreenCanvasForVideoDecode) {
              var audio_video_decode_channel = new MessageChannel();
              jsMediaEngine.Notify_Audio_Video_Thread_Msg_Channel(
                audio_video_decode_channel
              );
              if (jsMediaEngineVariables.decoderinworklet) {
                jsMediaEngineVariables.AudioNode.port.postMessage(
                  { status: 'decodeVideoPort' },
                  [audio_video_decode_channel.port2]
                );
              } else {
                jsMediaEngine.Notify_Audio_Thread_Msg_Channel2(
                  audio_video_decode_channel
                );
              }
            }

            if (value.CaptureAudio) {
              that.audioCapture = value.CaptureAudioInfo;
              let audioProfile = value.CaptureAudioInfo.audioProfile;
              if (audioProfile) {
                if (
                  audioProfile.currentSelect === 'backgroundNoiseSuppression'
                ) {
                  let denoiseSwitch =
                    audioProfile.backgroundNoiseSuppression === 'Zoom';
                  deviceManager.changeDenoiseSwitch(denoiseSwitch);
                  jsMediaEngine.Notify_Audio_Encode_Thread({
                    command: 'audioMode',
                    mode: -1,
                    highBitrate: audioProfile.highBitrate ? 1 : 0,
                  });
                } else {
                  let message = {
                    command: 'audio_profile',
                    highfidelity: audioProfile.originalSound.highfidlity,
                    enable: true,
                  };
                  jsMediaEngine.Notify_Audio_Encode_Thread(message);
                }
              }
              that.Start_Audio_Capture();
              if (this.firstSetDelay) {
                this.firstSetDelay = false;
                jsMediaEngine.Reset_Aec();
                jsMediaEngine.Set_Aec_Delay();
              }
            } else {
              jsMediaEngineVariables.ComputerAudioStatus =
                AudioStatus.ComputerAudio_Connected;
              jsMediaEngineVariables.Notify_APPUI(
                jsEvent.JOIN_COMPUTER_AUDIO_COMPLETE,
                null
              );
            }
          } catch (e) {
            jsMediaEngineVariables.ComputerAudioStatus =
              AudioStatus.ComputerAudio_Null;
            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.JOIN_COMPUTER_AUDIO_COMPLETE,
              null
            );
            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.JOIN_COMPUTER_AUDIO_FAILURE,
              null
            );
            log.error(e);
            this.JsMediaSDK_Log(e);
          }
        }
        break;
      case jsEvent.JOIN_DESKTOP_AUDIO:
        {
          if (
            jsMediaEngineVariables.DesktopAudioStatus !==
            AudioStatus.DesktopAudio_Null
          ) {
            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.JOIN_DESKTOP_AUDIO_COMPLETE,
              null
            );
            return;
          }

          jsMediaEngineVariables.DesktopAudioStatus =
            AudioStatus.DesktopAudio_Connecting;

          this.useAudioBridge = value.useAudioBridge && value.audioBridge;
          if (this.useAudioBridge) {
            deviceManager.setUserId(value.CaptureAudioInfo.ssrc);
            const {
              nginxHost,
              rwgHost,
              cid,
              abToken,
              supportLocalAB,
              useWebRTCOnDesktop,
            } = value.audioBridge;
            await this.previewInit({
              audioBridge: {
                nginxHost,
                rwgHost,
                cid,
                abToken,
                isCapturingAudio: value.CaptureAudio,
                audioMode: consts.WEBRTC_SHARE_AUDIO_MODE,
                supportLocalAB,
                useWebRTCOnDesktop,
              },
            });
            deviceManager.setAudioBridge(this.audioBridge);
            this.Start_Desktop_Audio_Capture();
            jsMediaEngineVariables.DesktopAudioStatus =
              AudioStatus.DesktopAudio_Connected;
            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.JOIN_DESKTOP_AUDIO_COMPLETE,
              null
            );
            return;
          }

          if (
            util.isSupportChromeWideAEC() &&
            jsMediaEngineVariables.ComputerAudioStatus !=
              AudioStatus.ComputerAudio_Null &&
            !this.audioDomNode &&
            jsMediaEngineVariables.shareSystemAudio &&
            (await this.isLocalP2PSupportedPromise)
          ) {
            //use peerConnection to replace chromeWideAEC
            jsMediaEngineVariables.AudioNode.disconnect();
            let dest = this.audioCtx.createMediaStreamDestination();
            jsMediaEngineVariables.AudioNode.connect(dest);
            let realStream = dest.stream;
            realStream = await this.chromeAecWorkAround(dest.stream);
            if (!this.audioDomNode) {
              this.audioDomNode = new Audio();
            }

            this.audioDomNode.srcObject = realStream;
            let playDevice = deviceManager.speakerId;
            let startTime = Date.now();
            this.selectComputerAudioSpeaker(playDevice, startTime);
            this.audioDomNode.play().catch((error) => {
              globalTracingLogger.error('Audio play failed', error);
            });
          }

          try {
            jsMediaEngine.Notify_Audio_Encode_Thread({
              command: 'mute',
              bOn: jsEvent.AUDIO_START,
              sharing: true,
            });
            if (this.sharingAudioCtx) {
              this.sharingAudioCtx.close();
              this.isSharingCaptureNodeConnect = false;
            }
            let audioContextConfigure = util.getAudioContextConfigure();
            this.sharingAudioCtx = createAudioContext(
              'MainSharing',
              audioContextConfigure
            );
            var that = this;
            /**
             * audioWorklet.addModule cannot support fetch data => URL.createObjectUrl => constructor audioWorlet.addModule
             */
            let workletPath = this.audioWorkletJsPath.sharingAudioWorkletPath;
            await this.sharingAudioCtx.audioWorklet.addModule(workletPath);
            jsMediaEngineVariables.SharingAudioNode = new zoomAudioWorkletNode(
              that.sharingAudioCtx,
              'zoomSharingAudioWorklet',
              {
                processorOptions: {
                  sharingEncodeChannelsNum: util.isSupportSharingStereo()
                    ? 2
                    : 1,
                },
              }
            );

            if (jsMediaEngineVariables.sharedBuffer) {
              jsMediaEngineVariables.SharingAudioNode.postCMD(
                'sharedBuffer',
                jsMediaEngineVariables.sharedBuffer
              );
            }

            let shareaudio_encode_channel = new MessageChannel();
            jsMediaEngineVariables.SharingAudioNode.port.postMessage(
              { status: 'encodeAudioPort' },
              [shareaudio_encode_channel.port1]
            );
            jsMediaEngine.Notify_Audio_Thread_Msg_Channel3(
              shareaudio_encode_channel
            );

            jsMediaEngine.Notify_Audio_Encode_Thread({
              command: 'startAudioEncode',
              ssid: value.CaptureAudioInfo.ssrc,
              samplerate: this.sharingAudioCtx.sampleRate,
              isSharing: true,
              audioMode: jsMediaEngineVariables.audioMode,
              sharingEncodeChannelsNum: util.isSupportSharingStereo() ? 2 : 1,
            });

            jsMediaEngine.Update_Audio_Encrpt(
              jsMediaEngineVariables.e2eencrypt
            );
            that.Start_Desktop_Audio_Capture();
          } catch (e) {
            log.error(e);
            this.JsMediaSDK_Log(e);
          }

          jsMediaEngineVariables.DesktopAudioStatus =
            AudioStatus.DesktopAudio_Connected;
          jsMediaEngineVariables.Notify_APPUI(
            jsEvent.JOIN_DESKTOP_AUDIO_COMPLETE,
            null
          );
        }
        break;
      case jsEvent.LEAVE_DESKTOP_AUDIO:
        {
          if (
            jsMediaEngineVariables.DesktopAudioStatus !==
            AudioStatus.DesktopAudio_Connected
          ) {
            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.LEAVE_DESKTOP_AUDIO_COMPLETE,
              null
            );
            return;
          }
          let isPauseAudioShare = false;
          if (value && value.isPause) {
            isPauseAudioShare = true;
          }
          if (!isPauseAudioShare) this.EndDesktopAudioMediaStream();
          //Maybe we should not close audio track
          this.Remove_Sharing_Audio_Capture();
          if (this.audioBridge) {
            this.audioBridge.leaveAudioWithoutDisconnect(
              consts.WEBRTC_SHARE_AUDIO_MODE
            );
          } else {
            //support chrome wide AEC but use peerConnection
            //means this share is share system audio
            //change to use chrome wide aec
            if (util.isSupportChromeWideAEC() && this.audioDomNode) {
              jsMediaEngineVariables.AudioNode.disconnect();
              this.CloseBoringPeerConnection();
              this.audioDomNode = null;
              let playDevice =
                deviceManager.speakerId == 'default'
                  ? ''
                  : deviceManager.speakerId;
              jsMediaEngineVariables.AudioNode.connect(
                this.audioCtx.destination
              );
              let starTime = Date.now();
              this.selectComputerAudioSpeaker(playDevice, starTime);
            }

            this.audioEncodeRingBufferConsumer &&
              this.audioEncodeRingBufferConsumer.cancelConsume();

            try {
              if (jsMediaEngineVariables.SharingAudioNode) {
                jsMediaEngineVariables.SharingAudioNode.postCMD(
                  'stopWorklet',
                  true
                );
                jsMediaEngineVariables.SharingAudioNode.port = null;
                jsMediaEngineVariables.SharingAudioNode.disconnect();
                jsMediaEngineVariables.SharingAudioNode = null;
              }
            } catch (ex) {
              log('SharingAudioNode.port', ex);
            }

            if (this.sharingAudioCtx) {
              this.sharingAudioCtx.close();
              this.isSharingCaptureNodeConnect = false;
            }
            this.sharingAudioCtx = null;

            if (this.screenShareAudioPreviewElement) {
              this.screenShareAudioPreviewElement.pause();
            }
          }
          jsMediaEngineVariables.DesktopAudioStatus =
            AudioStatus.DesktopAudio_Null;
          jsMediaEngineVariables.Notify_APPUI(
            jsEvent.LEAVE_DESKTOP_AUDIO_COMPLETE,
            null
          );
        }
        break;
      case jsEvent.START_REMOTE_CONTROL:
        {
          /**
                 socketURL
                 meetingID
                 condID
                 scaleWidth : The exact width of the sharing video area in the web page, in pixels
                 scaleHeight
                 srcOffsetX : default 0  The top left vertex of video relative to the top left vertex of the webpage horizontal offset
                 srcOffsetY : default 0
                 os: {Number}
                 dom: dom Object
                 */
          log('sdk start remote control 1');
          let sharingInfo = this.sharingWidthAndHeightInfo;
          let remoteControl = new RemoteControl(
            Object.assign(
              {
                dom: document,
              },
              value
            )
          );
          this.remoteControl = remoteControl;

          log('sharingInfo', sharingInfo);
          remoteControl.setDstWidthAndHeight(
            sharingInfo.logicWidth,
            sharingInfo.logicHeight
          );
          remoteControl.setSrcWidthAndHeight(
            sharingInfo.logicWidth,
            sharingInfo.logicHeight
          );
          remoteControl.setSrcScaleWidthAndHeight(
            value.scaleWidth,
            value.scaleHeight
          );
          remoteControl.setSrcOffsetXY(value.srcOffsetX, value.srcOffsetY);
          remoteControl.setRemoteOS(value.os);

          return remoteControl
            .start()
            .then((isSuccess) => {
              jsMediaEngineVariables.Notify_APPUI(
                isSuccess
                  ? jsEvent.START_REMOTE_CONTROL_SUCCESS
                  : jsEvent.START_REMOTE_CONTROL_FAILED
              );
              if (isSuccess) {
                remoteControl.onPasteTextLengthOverflow(function () {
                  jsMediaEngineVariables.Notify_APPUI(
                    jsEvent.REMOTE_CONTROL_PASTE_TEXT_LENGTH_OVERFLOW
                  );
                });

                remoteControl.onReturnCopiedText((data) => {
                  log(data);
                  jsMediaEngineVariables.Notify_APPUI(
                    jsEvent.REMOTE_CONTROL_COPIED_TEXT_NOTIFY,
                    data
                  );
                });
              }
              return isSuccess;
            })
            .catch((ex) => {
              log(ex);
              jsMediaEngineVariables.Notify_APPUI(
                jsEvent.START_REMOTE_CONTROL_FAILED
              );
              globalTracingLogger.error(
                'An error occurred when trying to start remote control',
                ex
              );
              Zoom_Monitor.add_monitor('RMCTF');
              return Promise.reject(ex);
            });
        }
        break;
      case jsEvent.CANCEL_REMOTE_CONTROL:
        {
          return this.remoteControl.destroy().then((isSuccess) => {
            jsMediaEngineVariables.Notify_APPUI(
              isSuccess
                ? jsEvent.CANCEL_REMOTE_CONTROL_SUCCESS
                : jsEvent.CANCEL_REMOTE_CONTROL_FAILED
            );
            return isSuccess;
          });
        }
        break;
      case jsEvent.UPDATE_REMOTE_CONTROL_PROPERTIES:
        {
          /**
           * {Number} #{value.scaleWidth}
           * {Number} #{value.scaleHeight}
           * {Boolean} #{value.isControllerNow}
           * {String} #{value.os}
           */
          if (this.remoteControl) {
            if (value.scaleWidth && value.scaleHeight) {
              this.remoteControl.setSrcScaleWidthAndHeight(
                value.scaleWidth,
                value.scaleHeight
              );
            }
            // value.srcOffsetX could be 0
            if (isNumber(value.srcOffsetX) || isNumber(value.srcOffsetY)) {
              this.remoteControl.setSrcOffsetXYAndSendPDU(
                value.srcOffsetX,
                value.srcOffsetY
              );
            }
            if (isBoolean(value.isControllerNow)) {
              this.remoteControl.setIsControlerNow(value.isControllerNow);
            }
            if (isNumber(value.os)) {
              this.remoteControl.setRemoteOS(value.os);
            }
          }
        }
        break;
      /**
       * sometimes, for multiple sharing, webclient need to resend "position pdu" information to server
       */
      case jsEvent.RESEND_REMOTE_CONTROL_POSITION_PDU:
        {
          if (this.remoteControl) {
            this.remoteControl.sendPostionPDU();
          }
        }
        break;

      case jsEvent.START_DESKTOP_SHARING:
        {
          this.flipSend = true;
          this.canISendNextSharingFrame = true;
          if (value.mode) {
            this.isSharingSupportImageCapture = util.isSupportImageCapture();
          } else {
            this.isSharingSupportImageCapture = false;
          }
          if (!this.isStartDesktopSharing) {
            jsMediaEngine.Update_Sharing_Encrpt(
              jsMediaEngineVariables.e2eencrypt
            );
            this.isStartDesktopSharing = true;
            this.desktopSharingSend = true;
            if (value.canvas) {
              value.rendercanvasID = value.canvas;
            }
            if (
              this.isSupportSharingTrackReader ||
              this.isSupportMediaStreamTrackProcessor
            ) {
              value.video = util.getDocumentHandle(value.canvas);
            } else {
              value.video = util.getDocumentHandle(value.video);
              value.canvas = util.getDocumentHandle(value.canvas);
            }
            this.desktopSharingValue = value;
            this.Start_Desktop_Sharing();
          } else {
            return;
          }
        }
        break;
      case jsEvent.STOP_DESKTOP_SHARING:
        {
          this.flipSend = true;
          this.canISendNextSharingFrame = true;
          jsMediaEngineVariables.shareSystemAudio = false;
          if (this.sharingTrackReader) {
            try {
              this.sharingTrackReader.stop();
            } catch (e) {}
          }
          if (this.isStartDesktopSharing) {
            this.desktopSharingSend = false;
            this.StopSharingCapture();
            if (this.isSupportVideoShare) {
              jsMediaEngine.Update_Sharing_Video_Encode_Status({
                command: 'stopsharing',
              });
            } else {
              jsMediaEngine.Update_Sharing_Encode_Status({
                command: 'stopsharing',
              });
            }
          } else {
            return;
          }
        }
        break;
      case jsEvent.PAUSE_DESKTOP_SHARING:
        {
          this.flipSend = true;
          this.canISendNextSharingFrame = false;
          if (this.isStartDesktopSharing) {
            this.desktopSharingSend = false;
            if (this.isSupportVideoShare) {
              jsMediaEngine.Update_Sharing_Video_Encode_Status({
                command: 'pause',
              });
            } else {
              jsMediaEngine.Update_Sharing_Encode_Status({
                command: 'pause',
              });
            }
          } else {
            return;
          }
        }
        break;
      case jsEvent.RESUME_DESKTOP_SHARING:
        {
          this.flipSend = true;
          this.canISendNextSharingFrame = true;
          if (this.isStartDesktopSharing) {
            this.desktopSharingSend = true;
            if (
              !this.isSupportMediaStreamTrackProcessor &&
              !this.isSupportSharingTrackReader
            ) {
              this.Process_Sharing();
            }
            if (this.isSupportVideoShare) {
              jsMediaEngine.Update_Sharing_Video_Encode_Status({
                command: 'resume',
              });
            } else {
              jsMediaEngine.Update_Sharing_Encode_Status({
                command: 'resume',
              });
            }
          } else {
            return;
          }
        }
        break;
      case jsEvent.START_STOP_REMOTE_CONTROL_CHECK: {
        if (!window.WebQrscanner) {
          console.error('qrScanner not loaded');
          return;
        }
        if (value.enable && this.desktopSharingMediaStram) {
          WebQrscanner.qrScanner.start(
            this.desktopSharingValue.video,
            (qrCodeStr) => {
              jsMediaEngineVariables.Notify_APPUI_SAFE(
                jsEvent.SEND_REMOTE_CONTROL_QR_CODE,
                qrCodeStr
              );
            }
          );
        } else {
          WebQrscanner.qrScanner.stop();
        }
        break;
      }
      case jsEvent.START_SHARING_WHITEBOARD:
        {
          this.isStartWhiteboardSharing = true;
          if (value.canvas) {
            value.rendercanvasID = value.canvas;
          }

          if (!this.isStartDesktopSharing) {
            jsMediaEngine.Update_Sharing_Encrpt(
              jsMediaEngineVariables.e2eencrypt
            );

            value.video = value.canvas = util.getDocumentHandle(value.canvas);
            this.isStartDesktopSharing = true;
            this.desktopSharingSend = true;
            this.desktopSharingValue = value;
            this.Start_Whiteboard_Sharing();
          } else {
            return;
          }
        }
        break;
      case jsEvent.STOP_SHARING_WHITEBOARD:
        this.isStartWhiteboardSharing = false;
        if (this.isStartDesktopSharing) {
          this.desktopSharingSend = false;
          this.isStartDesktopSharing = false;
          // this.Stop_Whiteboard_Sharing();

          jsMediaEngine.Update_Sharing_Encode_Status({
            command: 'stopsharing',
          });
        } else {
          return;
        }

        break;
      case jsEvent.CHECK_CHROME_SHARING_EXTENSION:
        {
          var extensionid = 'kgjfgplpablkjnlkjmjdecgdpfankdle';
          var image = document.createElement('img');
          image.src = 'chrome-extension://' + extensionid + '/images/trash.png';
          image.onload = function () {
            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.CHECK_CHROME_SHARING_EXTENSION_RESPONSE,
              true
            );
          };
          image.onerror = function () {
            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.CHECK_CHROME_SHARING_EXTENSION_RESPONSE,
              false
            );
          };
        }
        break;

      case jsEvent.COMMAND_SOCKET_MESSAGE_NOTIFY: {
        if (
          value.evt === jsEvent.ZOOM_CONNECTION_VIDEO_OFFER_RESPONSE_EVT ||
          value.evt === jsEvent.ZOOM_CONNECTION_AUDIO_OFFER_RESPONSE_EVT
        ) {
          log('rwg answer from ui', value);
          if (!value.answer) return;
          switch (value.answer.type) {
            case ZOOM_CONNECTION_TYPE.ZOOM_CONNECTION_VIDEO:
              PubSub.trigger(
                jsEvent.PUBSUB_EVT.ZOOM_CONNECTION_VIDEO_OFFER_RESPONSE_EVT,
                value
              );
              break;
            case ZOOM_CONNECTION_TYPE.ZOOM_CONNECTION_AUDIO:
              PubSub.trigger(
                jsEvent.PUBSUB_EVT.ZOOM_CONNECTION_AUDIO_OFFER_RESPONSE_EVT,
                value
              );
              break;
          }
        } else if (value.evt === jsEvent.WS_CONF_AB_TOKEN_RES) {
          if (value.body && value.body.token) {
            PubSub.trigger(
              jsEvent.PUBSUB_EVT.AUDIO_BRIDGE_WS_TOKEN,
              value.body.token
            );
          }
        }
        break;
      }

      case jsEvent.USER_NODE_LIST: {
        jsMediaEngine.setUserNodeListToWorker(value.userList);
        /**
         * @type value {USER_NODE[]}
         * @type USER_NODE {Object}
         * @type USER_NODE.userid {string}
         * @type USER_NODE.sn {string}
         * @type USER_NODE.bremove {boolean}
         */
        if (!this.setvideokk && value.encryptKey) {
          this.setvideokk = true;
          await jsMediaEngine.pushMessageToWorker(
            WORKER_TYPE.VIDEO_DECODE,
            {
              KK: value.encryptKey,
            },
            'MAIN_KK',
            false,
            true
          );
          await jsMediaEngine.pushMessageToWorker(
            WORKER_TYPE.VIDEO_ENCODE,
            {
              KK: value.encryptKey,
            },
            'MAIN_KK',
            false,
            true
          );
        }
        break;
      }

      case jsEvent.AUIOD_INTERPRETATION_MUTE: {
        jsMediaEngine.Notify_Audio_Thread_Interpretation_Mute(value.mute);
        break;
      }

      case jsEvent.AUDIO_INTERPRETATION_SELECT_LANGUAGE: {
        jsMediaEngine.Notify_Audio_Thread_Interpretation_Set_Lang(value.lang);
        break;
      }

      case jsEvent.AUDIO_INTERPRETATION_LIST_INFO: {
        jsMediaEngine.Notify_Audio_Thread_Interpretation_Set_Interpreter(
          value.interpreterList
        );
        break;
      }

      case jsEvent.AUDIO_INTERPRETATION_ENABLE: {
        jsMediaEngine.Notify_Audio_Thread_Interpretation_Enable(value.enable);
        break;
      }
      case jsEvent.VIDEO_ENABLE_DECODE_HW:
        {
          if (value.enable && this.isEnableVideoDecodeHardWareThread) {
            if (this.videodecodehardwareflag !== null) {
              value.enable = this.videodecodehardwareflag;
            } else {
              this.videodecodehardwareflag =
                await util.IsSupportVideoDecodeHardwareAcceleration();
              value.enable = this.videodecodehardwareflag;
            }
          }
          jsMediaEngine.Notify_Video_Decode_Thread({
            command: 'hwstatus',
            enable: value.enable,
          });
        }
        break;

      case jsEvent.VIDEO_ENABLE_ENCODE_HW:
        {
          if (consts.WEBCODEC_ENCODE_OPEN_FLAG) {
            if (
              value.enable &&
              this.videoencodehardwareflag &&
              !this.isAppleGraphic
            ) {
              value.enable = true;
            } else {
              value.enable = false;
            }
            jsMediaEngine.Notify_Video_Encode_Thread({
              command: 'hwstatus',
              enable: value.enable,
            });
          }
        }
        break;

      case 'update_videohd_value':
        {
          Zoom_Monitor.add_monitor('NoVideoHD');
          if (!value.videohd && this.videoencodehardwareflag) {
            util.set720pcapacity(false);
            Zoom_Monitor.add_monitor('CAPTURE720:' + false);
            jsMediaEngine.Notify_Video_Encode_Thread({
              command: 'videohd',
              videohd: value.videohd,
            });
          }
        }
        break;
      case 'update_videofullhd_value':
        {
          Zoom_Monitor.add_monitor('UVFHD' + value.videofullhd);
          util.set1080pcapacity(value.videofullhd);
          jsMediaEngine.Notify_Video_Encode_Thread({
            command: 'videofullhd',
            videofullhd: value.videofullhd,
          });
        }
        break;
      case jsEvent.SET_DESKTOP_VOLUME:
        {
          if (this.audioBridge) {
            this.audioBridge.setShareVolumeLevel(
              value.userid,
              value.shareVolume,
              value.isFromMainSession
            );
          } else {
            //merge startAudioShare command to startAudioEncode
            jsMediaEngine.Notify_Audio_Decode_Thread({
              command: 'setShareVolumeLevel',
              userid: value.userid,
              shareVolume: value.shareVolume,
              isFromMainSession: value.isFromMainSession,
            });
          }
        }
        break;

      case jsEvent.USER_NODE_LIST_IN_MAIN_SESSION:
        {
          if (
            value.mediaActionType == jsEvent.sdkIvTypeKeyEnum.SHARING_DECODE
          ) {
            if (this.isSupportVideoShare) {
              jsMediaEngine.pushMessageToWorker(
                WORKER_TYPE.VIDEO_DECODE,
                value,
                'USER_NODE_LIST_IN_MAIN_SESSION',
                false,
                true
              );
            } else {
              jsMediaEngine.pushMessageToWorker(
                WORKER_TYPE.SHARING_DECODE,
                value,
                'USER_NODE_LIST_IN_MAIN_SESSION',
                false,
                true
              );
            }
          }
          if (
            value.mediaActionType == jsEvent.sdkIvTypeKeyEnum.SHARING_ENCODE
          ) {
            if (this.isSupportVideoShare) {
              jsMediaEngine.pushMessageToWorker(
                WORKER_TYPE.VIDEO_ENCODE,
                value,
                'USER_NODE_LIST_IN_MAIN_SESSION',
                false,
                true
              );
            } else {
              jsMediaEngine.pushMessageToWorker(
                WORKER_TYPE.SHARING_ENCODE,
                value,
                'USER_NODE_LIST_IN_MAIN_SESSION',
                false,
                true
              );
            }
          }
          if (value.mediaActionType == jsEvent.sdkIvTypeKeyEnum.AUDIO_DECODE) {
            jsMediaEngine.pushMessageToWorker(
              WORKER_TYPE.AUDIO_DECODE,
              value,
              'USER_NODE_LIST_IN_MAIN_SESSION',
              false,
              true
            );
          }
        }
        break;
      case jsEvent.UPDATE_MEDIA_PARAMS:
        {
          if (
            value.mediaActionType == jsEvent.sdkIvTypeKeyEnum.SHARING_DECODE
          ) {
            if (this.isSupportVideoShare) {
              jsMediaEngine.pushMessageToWorker(
                WORKER_TYPE.VIDEO_DECODE,
                value,
                'UPDATE_MEDIA_PARAMS',
                false,
                true
              );
            } else {
              jsMediaEngine.pushMessageToWorker(
                WORKER_TYPE.SHARING_DECODE,
                value,
                'UPDATE_MEDIA_PARAMS',
                false,
                true
              );
            }
          }
          if (
            value.mediaActionType == jsEvent.sdkIvTypeKeyEnum.SHARING_ENCODE
          ) {
            if (this.isSupportVideoShare) {
              jsMediaEngine.pushMessageToWorker(
                WORKER_TYPE.VIDEO_ENCODE,
                value,
                'UPDATE_MEDIA_PARAMS',
                false,
                true
              );
            } else {
              jsMediaEngine.pushMessageToWorker(
                WORKER_TYPE.SHARING_ENCODE,
                value,
                'UPDATE_MEDIA_PARAMS',
                false,
                true
              );
            }
          }
          if (value.mediaActionType == jsEvent.sdkIvTypeKeyEnum.AUDIO_DECODE) {
            jsMediaEngine.pushMessageToWorker(
              WORKER_TYPE.AUDIO_DECODE,
              value,
              'UPDATE_MEDIA_PARAMS',
              false,
              true
            );
          }
        }
        break;
      case jsEvent.SHARING_ADD_REV_CHANNEL_TYPE:
        {
          if (this.isSupportVideoShare) {
            jsMediaEngine.pushMessageToWorker(
              WORKER_TYPE.VIDEO_DECODE,
              value,
              'SHARING_ADD_REV_CHANNEL_TYPE',
              false,
              true
            );
          } else {
            jsMediaEngine.pushMessageToWorker(
              WORKER_TYPE.SHARING_DECODE,
              value,
              'SHARING_ADD_REV_CHANNEL_TYPE',
              false,
              true
            );
          }
        }
        break;
      case jsEvent.SHARING_REMOVE_REV_CHANNEL_TYPE:
        {
          if (this.isSupportVideoShare) {
            jsMediaEngine.Notify_Sharing_Video_Decode_Thread({
              command: 'SHARING_REMOVE_REV_CHANNEL_TYPE',
              data: value,
            });
          } else {
            jsMediaEngine.Notify_Sharing_Decode_Thread({
              command: 'SHARING_REMOVE_REV_CHANNEL_TYPE',
              data: value,
            });
          }
        }
        break;
      case jsEvent.BUILD_MS_CHANNEL_IN_BO:
        {
          if (this.isSupportVideoShare) {
            jsMediaEngine.Notify_Sharing_Video_Decode_Thread({
              command: 'BUILD_MS_CHANNEL_IN_BO',
              data: value,
            });
          } else {
            jsMediaEngine.Notify_Sharing_Decode_Thread({
              command: 'BUILD_MS_CHANNEL_IN_BO',
              data: value,
            });
          }
        }
        break;
      case jsEvent.SWITCH_WATER_MARK_FLAG:
        {
          const { type } = value;
          if (!type) {
            this.SWITCH_VIDEO_WATER_MARK(value);
            this.SWITCH_SHARING_WATER_MARK(value);
          } else if (type === ConnectionType.VIDEO) {
            this.SWITCH_VIDEO_WATER_MARK(value);
          } else if (type === ConnectionType.SHARING) {
            this.SWITCH_SHARING_WATER_MARK(value);
          }
        }
        break;
      case jsEvent.AUDIO_CC_SELECT_LANGUAGE: {
        if (this.audioBridge) {
          this.audioBridge.set_CC_lang(value.lang);
        } else {
          jsMediaEngine.Notify_Audio_Thread_CC_Set_Lang(value.lang);
        }
        break;
      }
      case jsEvent.BUILD_MA_CHANNEL_IN_BO: {
        jsMediaEngine.Notify_Audio_Decode_Thread({
          command: 'BUILD_MA_CHANNEL_IN_BO',
          data: value,
        });
        break;
      }
      case jsEvent.ENABLE_SHARE_TO_BO: {
        if (this.audioBridge) {
          this.audioBridge.enableShareToBO(value.enable);
        } else {
          jsMediaEngine.Notify_Audio_Encode_Thread({
            command: 'ENABLE_SHARE_TO_BO',
            data: value.enable,
          });
        }
        break;
      }
      case jsEvent.ENABLE_BROADCAST_TO_BO: {
        if (this.audioBridge) {
          this.audioBridge.enableBroadCastToBO(value.enable);
        } else {
          jsMediaEngine.Notify_Audio_Encode_Thread({
            command: 'ENABLE_BROADCAST_TO_BO',
            data: value.enable,
          });
        }
        break;
      }
      case jsEvent.WEBGL_LOST_REPLACE_CANVAS: {
        let canvas = document.getElementById(value.canvasId);
        if (value.canvas) {
          canvas = value.canvas;
        }
        if (!canvas) return;
        let handleMGR = null;
        const isSupportOffscreenDecodeCanvas =
          this.replaceCanvasMap.videoDecodeCanvasIds &&
          this.replaceCanvasMap.videoDecodeCanvasIds.indexOf(value.canvasId) !==
            -1;
        /** single thread vb has offscreen encodeCanvas */
        const isSupportOffscreenEncodeCanvas =
          this.replaceCanvasMap.videoEncodeCanvasIds &&
          this.replaceCanvasMap.videoEncodeCanvasIds.indexOf(value.canvasId) !==
            -1;
        if (value.canvasId === this.replaceCanvasMap.videoMaskSettingCanvasId) {
          handleMGR = jsMediaEngineVariables.localVideoEncMGR;
        } else if (
          value.canvasId === this.replaceCanvasMap.sharingMainCanvasId
        ) {
          if (this.isSupportVideoShare) {
            handleMGR = jsMediaEngineVariables.localVideoDecMGR;
          } else {
            handleMGR = jsMediaEngineVariables.localSharingDecMGR;
          }
        } else if (
          value.canvasId === this.replaceCanvasMap.sharingPreviewCanvasId
        ) {
          handleMGR = jsMediaEngineVariables.localSharingEncMGR;
        } else if (
          isSupportOffscreenEncodeCanvas ||
          isSupportOffscreenDecodeCanvas
        ) {
          if (isSupportOffscreenEncodeCanvas) {
            // no sab virtual background has offscreen canvas
            handleMGR = jsMediaEngineVariables.localVideoEncMGR;
          } else {
            handleMGR = jsMediaEngineVariables.localVideoDecMGR;
          }
          // offscreen canvas in video/sharing encode thread should also replaced(Only decode thread has webgl)
          if (this.lazyReplaceEncodeCanvasTimer) {
            clearTimeout(this.lazyReplaceEncodeCanvasTimer);
            this.lazyReplaceEncodeCanvasTimer = null;
          }
          // when multi canvas lost same time, no need to duplicate replace
          this.lazyReplaceEncodeCanvasTimer = setTimeout(() => {
            // notify video encode replace canvas
            let videoEncodeHandleMGR = jsMediaEngineVariables.localVideoEncMGR;
            if (videoEncodeHandleMGR) {
              let videoEncodeHandle = videoEncodeHandleMGR.map.get(
                jsMediaEngineVariables.SPECIAL_ID
              );
              if (videoEncodeHandle) {
                videoEncodeHandle.postMessage({
                  command: 'WEBGL_LOST_REPLACE_CANVAS',
                });
              }
            }
            // notify sharing encode replace canvas
            let sharingEncodeHandleMGR =
              jsMediaEngineVariables.localSharingEncMGR;
            if (sharingEncodeHandleMGR) {
              let sharingEncodeHandle = sharingEncodeHandleMGR.map.get(
                jsMediaEngineVariables.SPECIAL_ID
              );
              if (sharingEncodeHandle) {
                sharingEncodeHandle.postMessage({
                  command: 'WEBGL_LOST_REPLACE_CANVAS',
                });
              }
            }
            if (this.videoCaptureHiddenCanvas) {
              const { width, height } = this.videoCaptureHiddenCanvas;
              if (util.isSupport2dOffscreenCanvas()) {
                this.videoCaptureHiddenCanvas = new OffscreenCanvas(
                  width,
                  height
                );
              } else {
                this.videoCaptureHiddenCanvas =
                  document.createElement('canvas');
                this.videoCaptureHiddenCanvas.width = width;
                this.videoCaptureHiddenCanvas.height = height;
              }
              this.videoCaptureHiddenCanvasCtx =
                this.videoCaptureHiddenCanvas.getContext('2d');
            }
            if (this.bgCanvas) {
              const { width, height } = this.bgCanvas;
              this.bgCanvas = document.createElement('canvas');
              this.bgCanvas.width = width;
              this.bgCanvas.height = height;
              this.bgCanvasctx = this.bgCanvas.getContext('2d');
              if (
                !this.VideoMaskSettingCanvas &&
                this.PrevVideoMaskSettingCanvas
              ) {
                this.VideoMaskSettingCanvas = this.PrevVideoMaskSettingCanvas;
                this.Update_Mask_Texture(
                  this.maskCoordinate,
                  this.videoCaptureHiddenCanvas.width,
                  this.videoCaptureHiddenCanvas.height
                );
                this.VideoMaskSettingCanvas = null;
              } else {
                this.Update_Mask_Texture(
                  this.maskCoordinate,
                  this.videoCaptureHiddenCanvas.width,
                  this.videoCaptureHiddenCanvas.height
                );
              }
            }
          }, 500);
        }
        if (handleMGR) {
          try {
            var replaceCanvas = canvas.transferControlToOffscreen();
            var workerHandle = handleMGR.map.get(
              jsMediaEngineVariables.SPECIAL_ID
            );
            if (workerHandle) {
              workerHandle.postMessage(
                {
                  command: 'WEBGL_LOST_REPLACE_CANVAS',
                  data: {
                    canvasId: value.canvasId,
                    canvas: replaceCanvas,
                  },
                },
                [replaceCanvas]
              );
            }
          } catch (e) {}
        }
        break;
      }
      case jsEvent.CHANGE_HID_ENABLE: {
        this.enableHID = value.enable;
        if (value.enable) {
          if (this.hidAvalible) {
            await HIDControl.destroy();
            this.hidAvalible = false;
          }
          if (value.microphoneLabel) {
            this.hidAvalible = await HIDControl.init(
              value.microphoneLabel,
              value.defaultMuted
            );
          }
        } else {
          if (this.hidAvalible) {
            await HIDControl.destroy();
            this.hidAvalible = false;
          }
        }
        break;
      }
      case jsEvent.ENABLE_VIDEO_OBSERVER: {
        jsMediaEngine.Notify_Video_Decode_Thread({
          command: 'ENABLE_VIDEO_OBSERVER',
          data: value.enable,
          enablefps: !value.fpsdisbale,
        });
        break;
      }
      case jsEvent.SWITCH_SHARING_TYPE: {
        jsMediaEngine.Notify_Video_Encode_Thread({
          command: 'SWITCH_SHARING_TYPE',
          data: value.mode,
        });
        break;
      }
      case jsEvent.SET_OTHER_AUDIO_VOLUME_LEVEL: {
        if (this.useAudioBridge && this.audioBridge) {
          this.audioBridge.setSpeechVolumeLevel(value.userId, value.volume);
        }
        if (value.issiphone) {
        } else {
          if (jsMediaEngineVariables.decoderinworklet) {
            if (jsMediaEngineVariables.AudioNode) {
              jsMediaEngineVariables.AudioNode.postCMD('setSpeechVolumeLevel', {
                userid: value.userId >> 10,
                volume: value.volume,
              });
            }
          } else {
            jsMediaEngine.Notify_Audio_Decode_Thread({
              command: 'setSpeechVolumeLevel',
              userid: value.userId >> 10,
              volume: value.volume,
            });
          }
        }
        break;
      }
      case jsEvent.USER_NODE_AUDIO_STATUS_LIST: {
        if (this.audioBridge) {
          this.audioBridge.updateUserMuteUnmuteStatus(value);
        }
        break;
      }
      case jsEvent.MOVE_PTZ_CAMERA: {
        mediaStreamController._updateVideoConstraints({ advanced: [value] });
        break;
      }
      case jsEvent.NOTIFY_SDK_JOIN_RWG_SUCCESS: {
        jsMediaEngineVariables.clearMessageToRwg();
        break;
      }
      case jsEvent.ENABLE_REUSE_STREAM: {
        mediaStreamController.enableReuseStream(value.enable);
        break;
      }
      case jsEvent.PRESET_MEDIA_CONSTRAINTS: {
        mediaStreamController.presetConstraints(value || {});
        break;
      }
      case jsEvent.DESTORY_REUSE_STREAM: {
        mediaStreamController.destoryReuseStream({
          audio: value && value.audio,
          video: value && value.video,
        });
        break;
      }
      case jsEvent.WHITEBOARD_JOIN_MESSAGE:
        {
          let message = {
            command: 'WHITEBOARD_JOIN_MESSAGE',
            data: value.message,
            nodeId: value.nodeId,
            encryptKey: value.encryptKey,
            sn: value.sn,
            dcsId: value.dcsId,
            EncodedSn: value.EncodedSn,
          };

          if (this.isSupportVideoShare) {
            jsMediaEngine.Notify_Sharing_Video_Encode_Thread(message);
          } else {
            jsMediaEngine.Notify_Sharing_Encode_Thread(message);
          }
        }
        break;
      case jsEvent.STOP_AUDIO_INCOMING:
        {
          if (this.audioBridge) {
            this.audioBridge.stopIncomingAudio(value);
          } else {
            //if decode in worklet, we can notify audio deocode worker dont push data into rtpSAB to reduce data copy
            //if decdoe in worker, we must push data into worklet, or isNeedMoreData will return true forever
            //so, we need notify worklet dont set data into ouputs
            if (jsMediaEngineVariables.decoderinworklet) {
              jsMediaEngine.Notify_Audio_Decode_Thread({
                command: 'stop_audio_incoming',
                stopPlayAudio: value,
              });
            } else {
              if (jsMediaEngineVariables.AudioNode) {
                jsMediaEngineVariables.AudioNode.postCMD(
                  'stop_audio_incoming',
                  value
                );
              }
            }
          }
        }
        break;
      case jsEvent.SET_CODEC_MODE:
        {
          Zoom_Monitor.add_monitor('AUDIO_CODEC_MODE' + value.mode);
          jsMediaEngineVariables.audioMode = value.mode;
          jsMediaEngine.Notify_Audio_Encode_Thread({
            command: 'audioMode',
            mode: value.mode,
            highBitrate: -1,
          });
        }
        break;
      case jsEvent.MOBILE_ROTATE:
        {
          this.isLandScape = value.isLandScape;
          if (!mediaStreamController.videoStreamTrack) {
            return;
          }
          if (this.isLandScape && this.captureSize) {
            this.Change_Video_Capture_Resolution(
              this.captureSize.width,
              this.captureSize.height
            );
          }
        }
        break;
      case jsEvent.SAVE_LOCAL_LOG:
        {
          this.localLog && this.localLog.saveAllLogFiles();
        }
        break;
      case jsEvent.AUDIO_JOIN_SUCCESS: {
        if (mediaStreamController && mediaStreamController.audioStream) {
          let audioTrack =
            mediaStreamController.audioStream.getAudioTracks()[0];
          if (audioTrack) {
            if (audioTrack.muted || audioTrack.readyState !== 'live') {
              jsMediaEngineVariables.Notify_APPUI_SAFE(
                jsEvent.AUDIO_STREAM_FAILED
              );
            }
          }
        }
        break;
      }
      case jsEvent.REMOVE_EXPIRED_CANVAS:
        {
          const videoMGR = jsMediaEngineVariables.localVideoDecMGR;
          const specialSsrc = jsMediaEngineVariables.SPECIAL_ID;
          if (videoMGR) {
            const handle = videoMGR.map.get(specialSsrc);
            if (handle) {
              const data = {
                command: 'removeExpiredCanvas',
                rendercanvasID: value.canvasId,
              };
              handle.postMessage(data);
            }
          }
        }
        break;
      default: {
        log('CAN NOT HANDLE THE EVENT!');
      }
    }
  },
  SWITCH_VIDEO_WATER_MARK: function (params) {
    this.videoWaterMarkParams = params;
    const {
      enableWaterMark,
      waterMarkText = '',
      watermarkOpacity,
      watermarkRepeated,
      watermarkPosition,
    } = params;
    this.isCreateVideoWaterMark = !!enableWaterMark;
    this.videoWaterMarkName = this.isCreateVideoWaterMark ? waterMarkText : '';
    if (this.VideoRenderObj) {
      if (enableWaterMark) {
        if (this.isCreateVideoWaterMark && !this.waterMarkCanvas) {
          this.waterMarkCanvas = document.createElement('canvas');
        }
      } else {
        this.VideoRenderObj.Set_WaterMark_Flag(enableWaterMark);
      }
      this.VideoRenderObj.Set_WaterMark_Info({
        waterMarkCanvas: this.waterMarkCanvas,
        isCreateVideoWaterMark: this.isCreateVideoWaterMark,
        videoWaterMarkName: this.videoWaterMarkName,
        watermarkOpacity,
        watermarkRepeated,
        watermarkPosition,
      });
    } else {
      jsMediaEngine.Notify_Video_Decode_Thread({
        command: 'SWITCH_WATER_MARK_FLAG',
        isCreateVideoWaterMark: this.isCreateVideoWaterMark,
        videoWaterMarkName: this.videoWaterMarkName,
        watermarkOpacity,
        watermarkRepeated,
        watermarkPosition,
      });
    }
    util.watermark.updateWaterMarkInfo(params);
  },
  SWITCH_SHARING_WATER_MARK: function (params) {
    this.sharingWaterMarkParams = params;
    const {
      enableWaterMark,
      waterMarkText = '',
      watermarkOpacity,
      watermarkRepeated,
      watermarkPosition,
    } = params;
    this.isCreateSharingWaterMark = !!enableWaterMark;
    this.sharingWaterMarkName = this.isCreateSharingWaterMark
      ? waterMarkText
      : '';
    if (this.SharingRenderObj) {
      if (enableWaterMark) {
        if (this.isCreateSharingWaterMark && !this.waterMarkCanvas) {
          this.waterMarkCanvas = document.createElement('canvas');
        }
      } else {
        this.SharingRenderObj.Set_WaterMark_Flag(enableWaterMark);
      }
      this.SharingRenderObj.Set_WaterMark_Info({
        waterMarkCanvas: this.waterMarkCanvas,
        isCreateSharingWaterMark: this.isCreateSharingWaterMark,
        sharingWaterMarkName: this.sharingWaterMarkName,
        watermarkOpacity,
        watermarkRepeated,
        watermarkPosition,
      });
    } else {
      if (this.isSupportVideoShare) {
        jsMediaEngine.Notify_Sharing_Video_Decode_Thread({
          command: 'SWITCH_SHARING_WATER_MARK_FLAG',
          isCreateSharingWaterMark: this.isCreateSharingWaterMark,
          sharingWaterMarkName: this.sharingWaterMarkName,
          watermarkOpacity,
          watermarkRepeated,
          watermarkPosition,
        });
      } else {
        jsMediaEngine.Notify_Sharing_Decode_Thread({
          command: 'SWITCH_WATER_MARK_FLAG',
          isCreateSharingWaterMark: this.isCreateSharingWaterMark,
          sharingWaterMarkName: this.sharingWaterMarkName,
          watermarkOpacity,
          watermarkRepeated,
          watermarkPosition,
        });
      }
    }
  },
  JsMediaSDK_VideoRenderInterval: function (time) {
    //time is interval time;
    let draw = this.VideoRenderObj.Start_Draw.bind(this.VideoRenderObj);
    return draw(jsEvent.SET_INTERVAL_MODE, time);
  },
  JsMediaSDK_SharingRenderInterval: function (time, isVideoShare = true) {
    var sharingRenderInterval = this.SharingRenderObj.No_Bindthis_Interval.bind(
      this.SharingRenderObj
    );
    var interval = setInterval(sharingRenderInterval, isVideoShare ? 20 : time);
    return interval;
  },

  /** expose func to get sharing stream info */
  getShareStreamInfo: function () {
    if (!this.desktopSharingMediaStram) return null;
    const videoTracks = this.desktopSharingMediaStram.getVideoTracks();
    if (!videoTracks.length) return null;
    if (videoTracks[0].getSettings) {
      return videoTracks[0].getSettings() || null;
    }
    return null;
  },

  onUnload: function (event) {
    const isWebGPU = this.rendererType == RenderConst.RENDERER_TYPE.WEBGPU;
    globalTracingLogger.log(`window.onunload:${this._id}, webGPU:${isWebGPU}`);
    Zoom_Monitor.add_monitor(`window.onunload:${this._id}, webGPU:${isWebGPU}`);
    if (isWebGPU) {
      this.destroy().catch((ex) => {});
    }
  },
  handleUnloadEvent() {
    this.unloadHandler = this.onUnload.bind(this);
    window.addEventListener('unload', this.unloadHandler);
  },

  /**
   * destroy all workers
   */
  async destroy() {
    log('destroy');

    window.removeEventListener('unload', this.unloadHandler);
    /**
     * Before 2019-12-2, we ignore that "destroy" method could be called during audio/video/sharing initiation
     * "during" means that initXXXDecode/initXXXEncode is called but not finished,
     * then some intervals or async process could not be terminated after "destroy",
     * so we need "isDestroy", check this value before these intervals or async process.
     *
     * (This issue is found in "breakout room")
     * @type {boolean}
     */

    if (this.destoryPromise) {
      await this.destoryPromise;
      return;
    }
    this.isDestroy = true;
    this.destoryPromise = new Promise(async (resolve, reject) => {
      try {
        globalTracingLogger.log(`DelInstance:${this._id}`);
        jsMediaEngineVariables._callbackList = [];
        jsMediaEngineVariables._Notify_APPUI = null;

        Zoom_Monitor.add_monitor(`DelInstance:${this._id}`);
        let that = this;
        jsMediaEngine.Stop_Monitor(this);
        PubSub.clearAllSubscriptions();
        jsMediaEngine.preserveMessageController.clear();
        jsMediaEngineVariables.destroyQueueMessageToRwg();

        if (this.audioInputLevel) {
          this.audioInputLevel.destroy();
          this.audioInputLevel = null;
        }

        if (this.bVideoEncodeMainThreadConsumerIntervalEnable) {
          this.videoEncodeRingbufferConsumer &&
            this.videoEncodeRingbufferConsumer.cancelConsume();
        }
        this.EndMedia();

        if (this.audioBridge) {
          this.audioBridge.destroy(false);
          this.audioBridge = null;
        }

        if (this.computePressureManager) {
          this.computePressureManager.destroy();
        }

        let videohandlerList = [
          jsMediaEngineVariables.localVideoDecMGR,
          jsMediaEngineVariables.localVideoEncMGR,
          jsMediaEngineVariables.localSharingDecMGR,
          jsMediaEngineVariables.localSharingEncMGR,
        ];

        let audiohandlerList = [
          jsMediaEngineVariables.localAudioDecMGR,
          jsMediaEngineVariables.localAudioEncMGR,
        ];

        let instances = [
          jsMediaEngineVariables.sharingDecInitInstance,
          jsMediaEngineVariables.sharingEncInitInstance,
          jsMediaEngineVariables.videoDecInitInstance,
          jsMediaEngineVariables.videoInitInstance,
          jsMediaEngineVariables.audioDecInitInstance,
          jsMediaEngineVariables.audioEncodeInitInstance,
        ];
        jsMediaEngine.closeAllPromiseObject(instances);

        jsMediaEngine.clearWorkerListener(videohandlerList, []);
        try {
          await new Promise(function (resolve, reject) {
            let timeout = setTimeout(() => resolve(), 1000);
            Promise.all(that.allpromises).finally(() => {
              clearTimeout(timeout);
              resolve();
            });
          });
        } catch (e) {
          console.error(e);
        }
        /**
         * remove webtranport connection
         * when user enter waiting room from preivew, the instance not be removed that in preview connecions
         * so the conection that in waiting room will connect error
         */
        jsMediaEngine.CloseMediaNetSessionToRwg(
          ZOOM_CONNECTION_TYPE.ZOOM_CONNECTION_AUDIO,
          NET_SESSION_TYPE.NET_WEBTRANSPORT
        );
        jsMediaEngine.CloseMediaNetSessionToRwg(
          ZOOM_CONNECTION_TYPE.ZOOM_CONNECTION_VIDEO,
          NET_SESSION_TYPE.NET_WEBTRANSPORT
        );
        await jsMediaEngine.closeAllMedia(
          this,
          videohandlerList,
          audiohandlerList
        );

        if (this.WebSipClient) {
          try {
            await this.WebSipClient.clear();
            this.WebSipClient = null;
          } catch (e) {
            /*
             * Just use catch
             * */
          }
        }

        if (this.hidAvalible) {
          await HIDControl.destroy();
        }

        await jsMediaEngine.destroyAllWorkers(
          videohandlerList,
          audiohandlerList
        );

        this.videoEncodeRingbufferConsumer = null;
        this.audioEncodeRingBufferConsumer = null;
        this.audioDecodeRingBuffer = null;
        this.videoDecodeRingBuffer = null;
        this.sharingDecodeRingBuffer = null;
      } catch (e) {
        globalTracingLogger.error(`destroy instance ${this._id} error`, e);
      }
      resolve();
    });

    await this.destoryPromise;
  },
  async isWebGPURendererType() {
    // FixMe: if there are fallback to webgl in renderManager, this rendererType should be synced.

    // TODO: fix for called too early, needs reverting at next release.
    let rendererType = await util.evaluateRendererType(
      this.webgpucapcity,
      this.isWebGL2Enabled
    );

    return rendererType == RenderConst.RENDERER_TYPE.WEBGPU;
  },
};

export default JsMediaSDK_Instance;
